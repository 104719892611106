const MENTION_REG_EX = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;

export const formatUser = user => ({
  _id: user.currentUser._id || '',
  avatar: user.currentUser.avatar || '',
  birthday: user.currentUser.birthday || '',
  displayName: user.currentUser.displayName || '',
  email: user.currentUser.email || '',
  fullName: user.currentUser.fullName || ''
});

export const mapEventGuestUsers = (messages, members) => {
  return messages.map(message => {
    if (message.content.event) {
      return {
        ...message,
        content: {
          ...message.content,
          event: {
            ...message.content.event,
            guests: !message.content.event.guests ? [] : message.content.event.guests.map(
              (guest) => ({
                rsvpStatus: guest.rsvpStatus,
                user: members?.find(member => member._id === guest._id)
              })
            )
          }
        }
      }
    }
    return message;
  })
}

export const removeFromEventMappedGuestUsers = (event) => {
  return {
    ...event,
    guests: !event.guests ? [] : event.guests.map(
      (guest) => ({
        rsvpStatus: guest.rsvpStatus,
        _id: guest.user._id
      })
    )
  }
}

export const mapMentionInputSuggestions = (members) => members?.map(member => ({
  id: member._id,
  name: member.displayName,
  display: member.displayName,
  fullName: member.fullName,
  avatar: member.avatar,
})) || []

export const replaceMentionValues = (
  value,
  replacer,
) => value.replace(MENTION_REG_EX, (fullMatch, original, trigger, name, id) => replacer({
  original,
  trigger,
  name,
  id,
}))
