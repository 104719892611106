import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useLogin from '../core/hooks/useLogin';
import VisiblePasswordField from './common/VisiblePasswordField';
import { useTranslation } from 'react-i18next';

function SignInForm({ setRedirectTo }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const location = useLocation();
    const login = useLogin('SignIn Login');
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        const referrer = location?.state?.referrer ?? '/chat';
        setRedirectTo(referrer);

        if (email && password) {
            // userLogin(loginCredentials);
            login(email, password, remember);
        }
    };

    return (
        <div className="sign-in-form-container">
            <Helmet>
                <title>{t("Sign In")}</title>
            </Helmet>
            <div className="spacer"></div>
            <div className="form-group">
                <div className="back-btn">
                    <Link to="/">
                        <span className="bk-btn-text">&#10094; {t("Back")}</span>
                    </Link>
                </div>
                <div className="form-container">
                    <h1 className="form-title">{t("Welcome back")}</h1>
                    <form
                        className="sign-in-form"
                        action=""
                        onSubmit={handleSubmit}
                    >
                        <div className="email-input-container">
                            <label className="email-title" htmlFor="email">
                            {t("EMAIL")}
                            </label>
                            <input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                className="email-input"
                                type="email"
                                placeholder={t("Enter your email")}
                                name="email"
                                required="required"
                                data-test-id="input-email"
                            />
                        </div>
                        <div className="password-input-container">
                            <label
                                className="password-title"
                                htmlFor="password"
                                value="FakePSW"
                            >
                                {t("PASSWORD")}
                            </label>
                            <VisiblePasswordField
                                className="password-input"
                                placeholder={t("Enter your password")}
                                required
                                data-test-id="input-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>
                                <input
                                    data-test-id="chk-remember-me"
                                    type="checkbox"
                                    value={remember}
                                    onChange={(e) => {
                                        setRemember(e.target.checked);
                                    }}
                                />{' '}
                                {t("Keep me logged in")}
                            </label>
                        </div>
                        <div className="sign-in-form-btn-container">
                            <Link to="/home">
                                <button
                                    onClick={handleSubmit}
                                    className="sign-in-form-btn"
                                >
                                    {t("SIGN IN")}
                                </button>
                            </Link>
                        </div>
                        <div className="tw-text-xs tw-mt-4 tw-text-center tw-tracking-widest">
                            <Link to="/forgot-password">{t("FORGOT PASSWORD")}</Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="spacer"></div>
            <div className="spacer"></div>
        </div>
    );
}

export default observer(SignInForm);
