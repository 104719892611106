import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'validate.js';

import { resetConfirmToken } from '../../../services/databaseService';
import useQuery from '../../../core/hooks/useQuery';
import Errors from '../../common/Errors';
import LoadingSpinnerWithModal from '../../common/LoadingSpinnerWithModal';
import { resetPassword } from '../../../services/databaseService';

const validationConstraints = {
    email: {
        email: true,
    },
    passcode: {
        presence: {
            allowEmpty: false,
        },
    },
};

/**
 * Verify passcode page component
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function VerifyPasscode() {
    const query = useQuery();
    const [email] = useState(() => {
        const eml = query.get('email');
        if (!eml) {
            return '';
        }
        return eml.replace(' ', '+');
    });
    const [passcode, setPasscode] = useState('');
    const [processing, setProcessing] = useState(false);
    const history = useHistory();
    const [errors, setErrors] = useState({});

    const validateInputs = () => {
        const errs = validate(
            {
                email,
                passcode,
            },
            validationConstraints
        );
        if (errs) {
            setErrors(errs);
            return false;
        }
        return true;
    };

    const validateEmail = () => {
        const errs = validate(
            {
                email,
            },
            {
                email: {
                    email: true,
                },
            }
        );
        if (errs) {
            setErrors(errs);
            return false;
        }
        return true;
    };

    const resendPasscode = (e) => {
        e.preventDefault();
        if (processing) {
            return false;
        }

        // validate email
        if (!validateEmail()) {
            return false;
        }
        setProcessing(true);
        // fire api request
        resetPassword({ email: email }).then((res) => {
            if (res && res.success) {
                window.alert(
                    `An email has been sent to ${email} with your temporary password.`
                );
            }
            setProcessing(false);
        });

        return false;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (processing) {
            return false;
        }

        // verify parameters
        if (!validateInputs()) {
            if (errors.email && errors.email.length > 0) {
                // show email error message
                alert(errors.email.join("\n"));
            }
            return false;
        }

        setProcessing(true);
        // fire api
        resetConfirmToken({
            email,
            token: passcode,
        }).then((res) => {
            setProcessing(false);
            if (res && res.user) {
                const { _id } = res.user;
                window.alert('Now you need a new password.');
                history.push(
                    `/forgot-password/reset?id=${_id}&token=${passcode}`
                );
                return;
            } else {
                window.alert('Sorry that passcode is incorrect!');
            }
        });

        return false;
    };

    return (
        <form
            className="tw-pt-2 tw-text-center"
            action=""
            onSubmit={submitHandler}
        >
            <h1 className="tw-text-xl">Enter Password from Email</h1>
            <p
                data-test-id="body"
                className="tw-text-center tw-text-sm tw-mt-4 tw-mx-3 tw-tracking-wider"
            >
                Enter the temporary passcode that was sent to {email}
            </p>
            <label className="tw-mt-8 tw-w-full tw-text-left tw-tracking-wider">
                PASSCODE
                <input
                    className="tw-w-full tw-bg-transparent tw-border-b tw-border-gray-500 tw-py-2 tw-mt-4"
                    type="text"
                    placeholder="Passcode"
                    data-test-id="input-passcode"
                    value={passcode}
                    onChange={(e) => {
                        setPasscode(e.target.value);
                        setErrors({
                            ...errors,
                            passcode: [],
                        });
                    }}
                />
                {errors.passcode !== undefined && (
                    <Errors attr="passcode" errors={errors.passcode} />
                )}
            </label>
            <button
                type="submit"
                data-test-id="btn-submit"
                className="tw-bg-orange-chat tw-py-4 tw-text-center tw-bottom-4 tw-left-0 tw-right-0 tw-rounded tw-w-full tw-mt-20"
                disabled={processing}
                onClick={submitHandler}
            >
                NEXT
            </button>
            <button
                data-test-id="btn-resend"
                className="tw-mt-4"
                type="button"
                onClick={resendPasscode}
            >
                RESEND PASSCODE
            </button>
            {processing && <LoadingSpinnerWithModal />}
        </form>
    );
}
