import React from 'react';
import { Helmet } from 'react-helmet';

import MembersList from '../../../common/MembersList';

export default function MemberDirectory() {
    return (
        <div>
            <Helmet>
                <title>Bailiwik Members</title>
            </Helmet>
            <MembersList />
        </div>
    );
}
