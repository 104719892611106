import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../core/contexts/FirebaseContext';
import useSocialLogin from '../core/hooks/useSocialLogin';
import useLogin from '../core/hooks/useLogin';
import { useLogToAmplitude } from '../core/utility/amplitude';
import Loader from './Loader';
import metadata from '../metadata.json';

const SignIn = ({ setBailiwikId, setRedirectTo }) => {
    const { firebase } = useFirebase();
    const socialLogin = useSocialLogin('Social Login');
    const login = useLogin('Social Login');
    const { t, i18n } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const [cookies, setCookie] = useCookies(['bw-language']);
    const [language, setLanguage] = useState('en');
    const logToAmplitude = useLogToAmplitude();
    const handleSubmit = (language) => {
        i18n.changeLanguage(language);
        let expires = new Date();
        // Expire in 10 minutes
        expires.setTime(expires.getTime() + 10 * 60 * 1000);
        setCookie('bw-language', language, {
            path: '/',
        });
        setLanguage(language);
    };

    const isGuestJoining = useMemo(() => {
        const guestJoinBWId = window.sessionStorage.getItem('guest-join-bw');
        return !!guestJoinBWId;
    }, []);

    const onSignInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account',
        });

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((response) => {
                console.log('response', response);
                console.log('response operation', response.operation);
                console.log(
                    `email: ${response.user.email}, display name: ${response.user.displayName}`
                );
                setShowLoader(true);
                socialLogin(
                    response.additionalUserInfo.profile.email,
                    response.user.uid,
                    response.user.displayName,
                    null,
                    // See #702
                    // response.additionalUserInfo.profile.picture,
                    (bailiwikId) => {
                        logActivity(response.user.uid, 'google');
                        setBailiwikId(bailiwikId);
                        /*
                        socialLogin was already doing this, so removed this code:
                        setRedirectTo(
                            bailiwikId
                                ? `/chat/${bailiwikId}`
                                : '/find-bailiwik'
                        );
                        */
                    }
                );
            });
    };

    const onSignInWithApple = () => {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((response) => {
                setShowLoader(true);
                socialLogin(
                    response.user.email,
                    response.user.uid,
                    '',
                    '',
                    (bailiwikId) => {
                        logActivity(response.user.uid, 'apple');
                        setBailiwikId(bailiwikId);
                        /*
                        socialLogin was already doing this, so removed this code:
                        setRedirectTo(
                            bailiwikId
                                ? `/chat/${bailiwikId}`
                                : '/find-bailiwik'
                        );*/
                    }
                );
            });
    };

    const onGuestSignIn = () => {
        // Same event recorded in client/components/SocialLogin/index.jsx
        logToAmplitude(null, null, null, 'Guest Sign In Clicked 1');
        setShowLoader(true);
        login(process.env.GUEST_EMAIL, process.env.GUEST_PASSWORD, true,
            () => {
                // when "logging in" as Guest, no bw recorded.
                logToAmplitude(null, null, null, 'Guest Sign In Clicked 2');
            });
    };

    /**
     * 
     * @param {*} source should be apple or google
     */
    const logActivity = (userId, source) => {
        try {
            const properties = {
                screen: 'SignUpScreen'
            };
            logToAmplitude(
                userId,
                null,
                null,
                'Sign Up Screen' + (source ? ' ' + source : ''),
                properties
            );
        } catch (e) {
            console.error("ERROR logging to amplitude screens/start/SignUpScreen.js");
            console.error(e);
        }
    }

    useEffect(() => {
        document.body.style.overflowY = 'auto';
    }, []);

    return (
        <div className="sign-in-container">
            <div className="spacer"></div>
            <div className="logo-container">
                <div className="logo"></div>
            </div>
            <div className="welcome-container">
                <h1 className="welcome">{t('Welcome to Bailiwik')}</h1>
                <h3 className="tagline">{t('A new kind of neighborhood')}</h3>
            </div>
            <div className="language-container">
                <button
                    onClick={(e) => handleSubmit('en')}
                    className={`tw-bg-orange-chat tw-text-center tw-block tw-py-4 tw-w-72 language-btn${
                        language == 'en' ? '-active' : ''
                    }`}
                >
                    ENGLISH
                </button>
                <button
                    onClick={(e) => handleSubmit('es')}
                    className={`tw-bg-orange-chat tw-text-center tw-block tw-py-4 tw-w-72 language-btn${
                        language == 'es' ? '-active' : ''
                    }`}
                >
                    ESPAÑOL
                </button>
            </div>
            <div className="sign-in-btn-container">
                {!isGuestJoining && (
                    <button
                        className="browse-as-a-guest"
                        onClick={onGuestSignIn}
                    >
                        <span>{t('Not sure yet?')}</span>
                        <br />
                        {t('BROWSE AS A GUEST')}
                    </button>
                )}
                <Link
                    to="/sign-up"
                    data-test-id="lnk-sign-up"
                    className="tw-bg-orange-chat tw-text-center tw-block tw-py-4 tw-w-72 welcome-sign-up-btn"
                >
                    {t('SIGN UP')}
                </Link>
                <div className="social-login-buttons-wrapper">
                    <button
                        onClick={onSignInWithGoogle}
                        className="sign-in-form-btn google"
                    >
                        <img src="/images/icons/google.png" alt="google" />
                        Sign-up with Google
                    </button>
                    <button
                        onClick={onSignInWithApple}
                        className="sign-in-form-btn apple"
                    >
                        <img src="/images/icons/apple.png" alt="apple" />
                        Sign-up with Apple
                    </button>
                </div>
            </div>
            <div className="no-account-blurb">
                <p className="tw-mt-4 tw-tracking-wider already-have-an-account">
                    {t('ALREADY HAVE AN ACCOUNT')}{' '}
                    <Link to="/sign-in" data-test-id="lnk-sign-in">
                        {t('SIGN IN')}
                    </Link>
                </p>
                <p className="tw-mt-5 tw-mx-8 text-center">
                    {t('Looking for the mobile app? Download on')}{' '}
                    <a href="https://apps.apple.com/us/app/bailiwik/id146587210">
                        {t('iPhone')}
                    </a>{' '}
                    {t('or')}{' '}
                    <a href="https://play.google.com/store/apps/details?id=com.bailiwik.com.android&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        {t('Android')}
                    </a>
                </p>
                <div className="tw-text-sm tw-mx-8 tw-text-center tw-mt-5">
                    {t('By using Bailiwik you agree to the')}{' '}
                    <Link to="/terms-and-conditions" className="tcs">
                        {t('Terms & Conditions')}
                    </Link>
                </div>
            </div>
            <div className="spacer" />
            <div className="app-version">
                <p className='tw-text-xs'>
                    BAILIWIK APP VERSION: {`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
                </p>
            </div>

            {showLoader && <Loader />}
        </div>
    );
};

export default SignIn;
