import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    AsyncStorgae,
} from 'react';
import { useStores } from '../../lib/store';
import firebase from 'firebase/app';
import { observer } from 'mobx-react';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const PRODUCTION_SERVER = process.env.NODE_ENV === 'production';
console.log('PRODUCTION_SERVER? :', PRODUCTION_SERVER);
const firebaseAuthUrlProduction =
    'https://us-central1-bailiwik-mobile-app.cloudfunctions.net/TokenGenerate';
const firebaseImageFetchUrlProduction = 'https://us-central1-bailiwik-mobile-app.cloudfunctions.net/ImageFetch';

// firebase auth dev url
const firebaseAuthUrlDev =
  'https://us-central1-bailiwik-mobile-app-dev.cloudfunctions.net/TokenGenerate';
    const firebaseImageFetchUrlDev = 'https://us-central1-bailiwik-mobile-app-dev.cloudfunctions.net/ImageFetch';

// See https://console.firebase.google.com/project/bailiwik-mobile-app/settings/general/web:MDBkOTk4NGItOTcxYy00ZjQ3LWE5YzctNTA4MWI5MTlkMzdk
// PROD firebase
const firebaseConfigProduction = {
    apiKey: 'AIzaSyDKop6k7dlG9eWib3rwcBfDoi9pXVfGL7E',
    authDomain: 'bailiwik-mobile-app.firebaseapp.com',
    databaseURL: 'https://bailiwik-mobile-app-default-rtdb.firebaseio.com',
    projectId: 'bailiwik-mobile-app',
    storageBucket: 'bailiwik-mobile-app.appspot.com',
    messagingSenderId: '82216927771',
    appId: '1:82216927771:web:f667ae4130cc47a3416ebe',
};


// dev instance
const firebaseConfigDev = {
    apiKey: 'AIzaSyAN5T2Tme0hsAjB9Z_vJifaCsN399vc95c',
    authDomain: 'bailiwik-mobile-app-dev.firebaseapp.com',
    databaseURL: 'https://bailiwik-mobile-app-dev-default-rtdb.firebaseio.com',
    projectId: 'bailiwik-mobile-app-dev',
    storageBucket: 'bailiwik-mobile-app-dev.appspot.com',
    messagingSenderId: '601348532866',
    appId: '1:601348532866:web:d1eb57c5e27e2d944f629b',
};


const firebaseConfig = PRODUCTION_SERVER
    ? firebaseConfigProduction
    : firebaseConfigDev;
const firebaseAuthUrl = PRODUCTION_SERVER
    ? firebaseAuthUrlProduction
  : firebaseAuthUrlDev;
const firebaseImageFetchUrl = PRODUCTION_SERVER
  ? firebaseImageFetchUrlProduction
  : firebaseImageFetchUrlDev;

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}

console.log('FirebaseContext FirebaseConfig: ', firebaseConfig);

const FirebaseContext = createContext();

const useFirebase = () => useContext(FirebaseContext);

const FirebaseProviderCore = ({ children }) => {
    const { userStore } = useStores();
    let { currentUser } = userStore;

    const [firebaseApp, setFirebaseApp] = useState({
        auth: false,
        firebase,
    });

  useEffect(() => {
    console.log('FirebaseToken FirebaseProviderCore currentUser: ', currentUser);
    if ( !currentUser?.token ) {
      console.log('FirebaseToken No token found');
      return;
    } 
    try {
      
        const authFB = (token) => {
          console.log('FirebaseToken FirebaseProviderCore token: ', token);
          console.log('FirebaseToken FirebaseProviderCore firebaseAuthUrl: ', firebaseAuthUrl);
            fetch(
                `${firebaseAuthUrl}?token=${token ? token : currentUser.token}`
            )
                .then((res) => res.json())
              .then((res) => {
                console.log('FirebaseToken FirebaseProviderCore res: ', res);
                firebase.auth().signInWithCustomToken(res.token)
              })
              .then(() => {
                setFirebaseApp({ auth: true, firebase });
              })
                .catch((err) => console.log(err, '---res'));
        };

      authFB(currentUser?.token);
      
    } catch (error) {
      console.error('FirebaseToken FirebaseProviderCore ERROR: ', error);
    }

    }, [currentUser?.token]);

    return (
        <FirebaseContext.Provider value={firebaseApp}>
            {children}
        </FirebaseContext.Provider>
    );
};

const FirebaseProvider = observer(FirebaseProviderCore);

export { useFirebase, FirebaseProvider, firebaseImageFetchUrl };
