import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import { getImageUrl, getTimeAgo } from '../../../lib/utils';
import iconPerson from '../../../assets/person.png';

/**
 * Get message creator name
 *
 * @param {object} creator
 *
 * @returns {string} Creator name
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const getCreatorName = (creator) => {
    const { displayName, fullName } = creator;
    if (displayName && displayName.trim() !== '') {
        return displayName.trim();
    }
    return fullName;
};

/**
 * Message Creator UI
 *
 * @param {object} props
 *
 * @returns {React.Element}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Creator({ message, author, sender = false }) {
    const { bailwikId } = useParams();
    const classes = ['tw-flex'];
    if (sender) {
        classes.push('tw-flex-row-reverse');
        classes.push('tw-items-end');
    } else {
        classes.push('tw-flex-row');
    }
    const img = (typeof author.avatar == 'string' && author.avatar) || author.image?.uri || author.image;
    const avatarToDisplay = getImageUrl(img);
    return (
        <div className={classes.join(' ')}>
            <div className="tw-rounded-full tw-w-8 tw-h-8 tw-overflow-hidden">
                <Link to={`/members/${bailwikId}/${author._id}`}>
                    <img
                        className="tw-object-cover tw-h-full tw-w-full"
                        src={
                            avatarToDisplay || '/images/icons/person.png'
                        }
                    />
                </Link>
            </div>
            <div className="tw-m-2 tw-opacity-40 tw-text-white tw-text-sm">
                {sender ? 'You' : getCreatorName(author)}
                {' - '}
                {getTimeAgo(message.createdAt)}
            </div>
        </div>
    );
}

Creator.propTypes = {
    message: PropTypes.object,
    sender: PropTypes.bool,
    author: PropTypes.object,
};

Creator.defaultProps = {
    sender: false,
};
