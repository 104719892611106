import { makeAutoObservable, runInAction, toJS } from 'mobx';

import {
    getCurrentBailiwik,
    setCurrentBailiwik,
} from '../services/deviceService';
import { getBailiwik, getMembers } from '../services/databaseService';

/**
 * Bailiwik mobx store
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export default class BailiwikStore {
    rootStore;
    current;
    members;
    chat;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        this.reset();
    }

    reset(source) {
        this.current = {};
        this.members = [];
        this.membersDetails = [];
        this.chat = null;
    }

    get getBailiwikId() {
        if (!this.current || !this.current._id) {
            return null;
        }
        return this.current._id;
    }

    async load(bailiwikId, source) {
        let id = bailiwikId;
        if (!bailiwikId) {
            const bailiwik = getCurrentBailiwik();
            if (!bailiwik) {
                return null;
            }
            id = bailiwik._id;
        }
        const data = await getBailiwik(id).catch((err) => console.error(err));
        if (!data || !data.bailiwik) {
            return null;
        }
        const membersDetails = await this.getMembersDetails(data.bailiwik._id);
        setCurrentBailiwik(data.bailiwik);
        runInAction(() => {
            this.current = data.bailiwik;
            this.members = data.bailiwik?.members;
            this.chat = data.bailiwik.chat;
            this.membersDetails = membersDetails;
        });

        return this.bailiwik;
    }

    async loadAllFromStorage(source) {
        const current = await getCurrentBailiwik();
        const membersDetails = await this.getMembersDetails(current._id);
        if (current) {
            runInAction(() => {
                this.current = current;
                this.membersDetails = membersDetails;
            });
        }
    }

    get isBailiff() {
        const { userStore } = this.rootStore;
        const uid = userStore.getUserId;
        if ((this.current && this.current.bailiff) || userStore.isSuperAdmin) {
            if (userStore.isSuperAdmin) {
                return true;
            }
            return this.current.bailiff._id === uid;
        }
        return false;
    }

    isHost(userId) {
        const { userStore } = this.rootStore;
        // this is a method and not an @computed.
        // I need to be able to pass in a userId to check
        if (!userId) {
            userId = userStore.getUserId;
        }
        if (
            this.current.hosts &&
            this.current.hosts.length &&
            this.current.hosts.indexOf(userId) > -1
        ) {
            return true;
        }
        return false;
    }

    get isMember() {
        const uid = this.rootStore.userStore.getUserId;
        if (this.members && this.members.length) {
            return this.members.includes(uid);
        }
        return false;
    }

    async switch(bailiwikData, source) {
        console.log('BailiwikStore.switch STARTING, from:', source);

        // we can optionally just use the target bailiwik ID, we don't need the whole object, we get that from UserStore.bailiwiks
        let targetBailiwikId = bailiwikData?._id || bailiwikData;
        if (targetBailiwikId) {
            const membersDetails = await this.getMembersDetails(
                targetBailiwikId
            );
            // const uid = this.rootStore.userStore.getUserId || getStoredUserId();
            // const prevBailiwik = this.current?._id ? this.current._id : null;
            runInAction(() => {
                this.current = bailiwikData;
                this.membersDetails = membersDetails;
            });
            setCurrentBailiwik(bailiwikData);

            // @TODO: Complete following behaviour
            //
            // await this.switchBailiwikDataSet(
            //     targetBailiwikId,
            //     'BailiwikStore.switch'
            // );
            //
            // if (this.isMember) {
            //     NotificationService.switch({
            //         prevBailiwik,
            //         newBailiwik,
            //         uid,
            //     });

            //     //UserStore.load('BailiwikStore switch');
            //     EventStore.load(newBailiwik, 'BailiwikStore switch');
            //     DealStore.load(newBailiwik, 'BailiwikStore switch');
            //     MemberStore.load(newBailiwik, true, 'BailiwikStore .switch');
            //     //await MemberStore .load(newBailiwik,'BailiwikStore');
            // }
        }
    }

    async getMembersDetails(bailiwikID) {
        let response = await getMembers(bailiwikID).catch((err) =>
            console.log(err)
        );

        return response.members;
    }
}
