import React from "react";
import PropTypes from "prop-types";

/**
 * Component to display No messages Yet screen
 * 
 * @param {object} props
 * 
 * @returns React.ReactElement
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function NoMessagesYet({ message }) {
  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-justify-center tw-items-center">
      <div className="tw-flex-grow-0 tw-h-40">&nbsp;</div>
      <div className="tw-flex-grow tw-h-full">
        <div className="tw-flex tw-h-full tw-flex-col tw-justify-center tw-items-center">
          <div className="">
            <svg
              width="91"
              height="91"
              viewBox="0 0 91 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.0219 65L69 45.5L23.0219 26L23 41.1667L55.8571 45.5L23 49.8333L23.0219 65Z"
                fill="white"
              />
              <circle
                cx="45.5"
                cy="45.5"
                r="43"
                stroke="white"
                strokeWidth="5"
              />
            </svg>
          </div>
          <div className="tw-mt-2 tw-text-center tw-text-sm tw-tracking-wide">
            {message}
          </div>
        </div>
      </div>
      <div className="tw-flex-grow-0 tw-h-56">
        <div className="tw-flex tw-justify-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 10.1796L16.59 8.72656L12 13.4465L7.41 8.72656L6 10.1796L12 16.3629L18 10.1796Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="tw-mt-2 tw-text-sm tw-text-center">
          <b>Tap on the field</b> below to write <br />a message
        </div>
      </div>
    </div>
  );
}

NoMessagesYet.defaultProps = {
  message: "NO MESSAGES YET",
};

NoMessagesYet.propTypes = {
  message: PropTypes.string,
};
