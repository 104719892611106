import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Modal popup
 *
 * @param object props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Modal({ children, closeHandler }) {
    const node = useRef();

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return; // clicked inside
        }
        closeHandler(); // hide on outside click
    };

    // attach/deattach event for detecting click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="tw-relative">
            <div className="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto">
                <div className="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
                    <div
                        className="tw-fixed tw-inset-0 tw-transition-opacity"
                        aria-hidden="true"
                    >
                        <div className="tw-absolute tw-inset-0 tw-bg-black-chat tw-bg-opacity-20"></div>
                    </div>

                    <span
                        className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        ref={node}
                        className="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg tw-w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
                            <div className="sm:tw-flex sm:tw-items-start">
                                <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left tw-relative tw-w-full">
                                    <div
                                        data-test-id="btn-close-modal"
                                        className="tw-absolute tw-right-0 tw--top-3"
                                        onClick={closeHandler}
                                    >
                                        <svg
                                            width="13"
                                            height="14"
                                            viewBox="0 0 13 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.0218 0.709727C11.6716 0.319727 11.1058 0.319727 10.7556 0.709727L6.36405 5.58973L1.97255 0.699727C1.62231 0.309727 1.05653 0.309727 0.706286 0.699727C0.356043 1.08973 0.356043 1.71973 0.706286 2.10973L5.09779 6.99973L0.706286 11.8897C0.356043 12.2797 0.356043 12.9097 0.706286 13.2997C1.05653 13.6897 1.62231 13.6897 1.97255 13.2997L6.36405 8.40973L10.7556 13.2997C11.1058 13.6897 11.6716 13.6897 12.0218 13.2997C12.3721 12.9097 12.3721 12.2797 12.0218 11.8897L7.63031 6.99973L12.0218 2.10973C12.3631 1.72973 12.3631 1.08973 12.0218 0.709727Z"
                                                fill="#364C80"
                                            />
                                        </svg>
                                    </div>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    children: PropTypes.node,
};
