import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';

import App from './app';
import { StoresProvider } from '../lib/store';
import LocalStorageProvider from '../core/providers/localStorageProvider';
import { FirebaseProvider } from '../core/contexts/FirebaseContext'

import * as Sentry from '@sentry/react';
console.log('Sentry set up ErrorBoundary');
function FallbackComponent() {
  return (
    <div>An error has occured</div>
  )
}

export default function AppContainer() {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <StoresProvider>
            <LocalStorageProvider>
                <BrowserRouter>
                    <FirebaseProvider>
                        <Suspense fallback="...is loading">
                            <App />
                        </Suspense>
                    </FirebaseProvider>
                </BrowserRouter>
            </LocalStorageProvider>
      </StoresProvider>
    </Sentry.ErrorBoundary>
    );
}
