export default {
  clearIndicator: (p) => ({
    ...p,
    backgroundColor: 'red'
  }),
  container: (p) => ({
    ...p,
    backgroundColor: '#344A7C'
  }),
  control: (p) => ({
    ...p,
    border: 'none',
    backgroundColor: '#344A7C',
    boxShadow: 'nonoe'
  }),
  dropdownIndicator: (p) => ({
    ...p,
    opacity: 0
  }),  
  indicatorSeparator: (p) => ({
    ...p,
    opacity: 0
  }),
  input: (p) => ({
    ...p,
    color: 'white'
  }),
  placeholder: (p) => ({
    ...p,
    color: '#FFFFFF',
    opacity: .4
  }),
  menu: (p) => ({
    ...p,
    width: 'calc(100% - 42px)',
    backgroundColor: '#344A7C'
  }),
  singleValue: (p) => ({
    ...p,
    color: 'white',

  }),
  option: (p) => ({
    ...p,
    backgroundColor: '#344A7C',
  }),
  
  }
