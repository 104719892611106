import React from 'react'
import './style.css'

const DeleteMediaModal = (props) => {

    return (
        <>
            <div className="mm-question">
                <p>
                    Are you sure you want to delete this media?
                </p>
            </div>
            <div className="mm-button-confirm">
                <button onClick={props.onConfirm}>
                    DELETE
                </button>
            </div>
            <div className="mm-button mm-button-close">
                <button onClick={props.onClose}>
                    CANCEL
                </button>
            </div>
        </>
    )
}

export default DeleteMediaModal