import React from 'react';
import PropTypes from 'prop-types';

import TextInput from './TextInput';

/**
 * ChatTextInput Component
 *
 * This component use to capture user text input
 *
 * @param {object} props
 *
 * @returns React.ReactElement
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function ChatTextInput({ text, onChange, send }) {
    const submitHandler = (e) => {
        e.preventDefault();
        // do basic validations
        if (text.trim() === '') {
            return;
        }
        send(text.trim());
    };

    return (
        <div className="tw-mt-1 tw-flex-grow tw-w-full">
            <form action="" method="POST" onSubmit={submitHandler}>
                <div className="tw-flex tw-flex-row tw-rounded-3xl tw-bg-white">
                    <div className="tw-flex-grow tw-flex-shrink">
                        <div className="tw-ml-7">
                            <TextInput
                                text={text}
                                onChange={onChange}
                                onEnter={submitHandler}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="tw-flex-grow-0 tw-w-10 tw-p-0 tw-m-0"
                        data-test-id="btn-submit"
                        onClick={submitHandler}
                    >
                        <svg
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z"
                                fill="#F27F45"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.0096 27L30.0769 18.5L10.0096 10L10 16.6111L24.3407 18.5L10 20.3889L10.0096 27Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    );
}

ChatTextInput.defaultProps = {
    text: '',
};

ChatTextInput.propTypes = {
    send: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
};
