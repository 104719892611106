import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useAsync } from 'react-use';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useStores } from '../../lib/store.js';
import NoMessagesYet from './components/NoMessagesYet';
import ChatToolbar from '../common/ChatToolbar';
import Messages from '../common/Messages';
import { getUserAccount } from '../../services/databaseService';
import Modal from '../chat/StandardModal';
import ReportPost from '../chat/StandardModal/ReportPostModal';
import Toast from '../toast';

import ChatService from '../../services/ChatService';
import { sanitize, getImageUrl } from '../../lib/utils.js';
import ImageUploaderModal from '../chat/ImageUploader';
import EditTextMessage from '../common/EditTextMessage';
import HeaderFooterLayout from '../common/HeaderFooterLayout.jsx';
import { handleSignOut } from '../../services/authService.js';
import { useTranslation } from 'react-i18next';

import useMessages from '../../core/hooks/useMessages.js';
import useSendMessage from '../../core/hooks/useSendMessage';
import {
    editMediaMessage,
    editMessage,
    reportMessage,
} from '../../core/utility/firebase-message-actions';

import { useResetBadgesCount } from '../../core/hooks/useBadgeCount'
import { useLogToAmplitude } from '../../core/utility/amplitude';

/**
 * DirectMessage Component direct message route
 * 
 * This is a screen that displays all direct messages in a
 * conversation. User sees this when clicking a chat item
 * in the list in Messages tab.
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const Chat = ({ bailiwik }) => {
    const { conversationId, memberId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const rootStore = useStores();
    const { userStore, chatStore } = rootStore;
    const { currentUser } = userStore;
    const [isLocked, setIsLocked] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [mediaModalData, setMediaModalData] = useState(); 
    const [messageToReport, setMessageToReport] = useState();
    const user = { currentUser: userStore.currentUser };

    const [profileModalIndex, setProfileModalIndex] = useState({});
    const [text, setText] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editingMessage, setEditingMessage] = useState(null);
    const isMounted = useRef(false);
    const { t } = useTranslation();
    // #635 requires we send memberId in order to update unseenCount for dm message
    const sendMessage = useSendMessage(`dm/${conversationId}/messages`, {currentUser : userStore.currentUser}, bailiwik, { _id: memberId });

    const [page, setPage] = useState(0);
    const messages = useMessages(`dm/${conversationId}/messages`, page);
    const scrollDivRef = useRef();
    const toastRef = useRef();
    const resetBadges = useResetBadgesCount(userStore?.currentUser?._id);
    const logToAmplitude = useLogToAmplitude();

    const loadMember = async (memberId) => {
        const member = await getUserAccount(memberId, 'direct message');
        const populatedMember = {
            _id: member._id,
            title: member.fullName ? member.fullName : member._id,
            name: member.fullName ? member.fullName : member._id,
            detail: member.occupation
                ? `${member.displayName}\n${member.occupation}`
                : member.displayName,
            displayName: member.displayName ?? member.fullName,
            occupation: member.occupation ? member.occupation : null,
            bio: member.bio ? member.bio : null,
            birthday: member.birthday ? member.birthday : null,
            website: member.website ? member.website : null,
            interests: member.interests ? member.interests : null,
            button: true,
            size: 'med',
            bans: member.bailiwiksBanned,
            blocks: member.blocks,
            blocked: userStore.isBlocked(member._id),
            banned: false,
            arrow: false,
            padding: true,
            swipeable: member._id !== userStore.getUserId,
            swipeableType: 'user',
            avatar: true,
            pushToken: member.pushToken ? member.pushToken : null,
            pushSettings: member.pushSettings ? member.pushSettings : null,
            image: getImageUrl(member.avatar) || null,
        };
        setProfileModalIndex(populatedMember);
    };

    const sendNewText = async (text) => {
        if (isLocked) {
            return;
        }
        setIsLocked(true);

        // Create a pre message to post to chat instantly
        const sanitized = sanitize(text);
        if (sanitized === '') {
            return;
        }
        sendMessage('TEXT', { text: sanitized }, {});
        logToAmplitude(
            currentUser?._id,
            bailiwik?._id,
            bailiwik?.name,
            'Post',
            { type: 'direct' }
          );
    };

    const onUpload = (media) => {
        if (mediaModalData.content) {
            editMediaMessage(mediaModalData, media);
            //   toastRef.current.notify('MEDIA UPDATED!')
        } else {
            sendMessage('MEDIA', { media: media });
            //   toastRef.current.notify('MEDIA UPLOADED')
        }
        setMediaModalData(false);
        logToAmplitude(currentUser?._id, bailiwik._id, bailiwik.name, 'Post',
            { type: 'media', screen: 'messages' });
    };

    const sendEditedText = async (text) => {
        if (isLocked) {
            return;
        }
        setIsLocked(true);
        const sanitizedText = sanitize(text);
        if (sanitizedText === '') {
            return;
        }

        editMessage(editingMessage, text);
        setText('');
        setEditMode(false);
        setEditingMessage(null);
    };

    const sendText = (text) => {
        if (editMode) {
            sendEditedText(text);
        } else {
            sendNewText(text);
        }
        setIsLocked(false);
        setText('');
    };

    const { loading, error } = useAsync(async () => {
        try {
            await loadMember(memberId);
        } catch (err) {
            if (err.message === 'Unauthorized') {
                handleSignOut('Chat force logout', rootStore);
                history.push('/sign-in', {
                    state: { referrer: location },
                });
            }
            throw err;
        }
    });

    useEffect(() => {
        if (memberId === userStore.getUserId) {
            window.alert(t("You cant chat with yourself"));
            // go to previous page
            history.goBack();
            return;
        }

        if (!isMounted.current) {
            isMounted.current = true;
        }
        return () => {
        };
    }, []);

    useEffect(() => {
        // Data structure is strange.
        // an unseenCount is stored *both* on the conversationId,
        // such as data/users/:userId/:conversationId/unseenCount
        // such as data/users/:userId/:messageId/unseenCount
        // Individual messages are stored here:
        // data/dm/:conversationId/messages/:messageId
        // Anyways, you have to resetBadges *both* on the conversationId
        // and on every messageId in the conversation.
        // Fortunately, unseenCount is only stored at nodes
        // like data/users/:userId/:something/unseenCount.
        if (conversationId) {
            resetBadges(conversationId);
        }
        return () => {
        };
    }, [conversationId]);

    if (loading) {
        return null;
    }

    if (error) {
        return null;
    }

    const editHandler = (message) => {
        setEditMode(true);
        setEditingMessage(message);
    };

    const onReportPost = async () => {
        let areReported = null;
        const item = messageToReport;
        const res = await reportMessage(item, user, areReported);

        if (!areReported && res.committed) {
            setMessageToReport(false);
            toastRef.current.notify('POST HAS BEEN REPORTED');
        } else {
            setMessageToReport(false);
            toastRef.current.notify('POST HAS BEEN UNREPORTED');
        }
    };

    return (
        <HeaderFooterLayout
            title={`${t("Chat with")} ${profileModalIndex.title}`}
            backButton
            Footer={
                <ChatToolbar
                    onMedia={() => setMediaModalData(true)}
                    text={text}
                    onTextChange={setText}
                    sendText={sendText}
                />
            }
        >
            <Helmet>
                <title>Chat with {profileModalIndex.title}</title>
            </Helmet>
            <Toast ref={toastRef} />

            {(messageToReport) && (
                <Modal
                    onClose={() =>
                        setMessageToReport(false)
                    }
                    title={
                        'Confirm Report'
                    }
                >
                    {messageToReport && (
                        <ReportPost
                            onClose={() => setMessageToReport(false)}
                            onConfirm={onReportPost}
                        />
                    )}
                </Modal>
            )}


            {mediaModalData && (
                <ImageUploaderModal
                    source="DirectMessage Chat"
                    message={
                        mediaModalData.images
                            ? { content: { media: mediaModalData } }
                            : mediaModalData
                    }
                    onClose={() => setMediaModalData(false)}
                    onUpload={onUpload}
                    user={userStore.currentUser}
                    bailiwik={bailiwik}
                    memberId={memberId}
                />
            )}
            {editMode && (
                <EditTextMessage
                    text={editingMessage.content.text}
                    title={t("Edit Direct Message")}
                    submitHandler={(text) => {
                        sendText(text);
                    }}
                    closeHandler={() => {
                        setEditMode(false);
                    }}
                />
            )}
            {!editMode && messages.length === 0 && <NoMessagesYet />}

            <div
                id="scrollableDiv"
                // className="box-container"
                ref={scrollDivRef}
                style={{
                    height: '100%',
                    width: '103%',
                    maxWidth: '1120px',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                <InfiniteScroll
                    ref={(ref) => {
                        scrollDivRef.current = ref;
                        if (ref && ref.isElementAtTop) {
                            const func = ref.__proto__.isElementAtTop;
                            ref.__proto__.isElementAtTop = (target, n) =>
                                target.scrollTop === 0
                                    ? false
                                    : func(target, n);
                        }
                    }}
                    className="container"
                    scrollThreshold={0.8}
                    inverse={true}
                    dataLength={messages.length}
                    hasMore={true}
                    next={() => setPage(page + 1)}
                    scrollableTarget="scrollableDiv"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                >
                    {messages.length > 0 && (
                        <Messages
                            onEditMedia={(post) => setMediaModalData(post)}
                            messages={messages}
                            myId={userStore.getUserId}
                            chatId={chatId}
                            memberId={memberId}
                            memberProfile={profileModalIndex}
                            myProfile={userStore.currentUser}
                            editHandler={editHandler}
                            report={setMessageToReport}
                        />
                    )}
                </InfiniteScroll>
            </div>
        </HeaderFooterLayout>
    );
};

export default observer(Chat);
