import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import BailiwikAvatar from './bailiwik-avatar';
import { getImageUrl } from '../lib/utils';
import { handleSignOut } from '../services/authService';
import { useStores } from '../lib/store';
import FindBailiwik from './FindBailiwik';
import BailiwikItem from './FindBailiwik/BailiwikItem';
import Toast from './toast';

/**
 * Avatar Popup for user options
 *
 * @param {object} props
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const AvatarPopup = ({ logout, closeHandler }) => {
    const node = useRef();

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return; // clicked inside
        }
        closeHandler(); // hide on outside click
    };

    // attach/deattach event for detecting click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={node}
            className="popup-shadow tw-absolute tw-left-0 tw--bottom-24 tw-bg-white tw-text-black-chat tw-p-4 tw-w-44 tw-z-10"
            data-test-id="user-avatar-popup"
        >
            <div>
                <Link to="/profile" className="tw-w-full tw-block">
                    Edit Profile
                </Link>
            </div>
            <div>
                <button
                    className="tw-font-bold tw-w-full tw-text-left"
                    onClick={logout}
                    data-test-id="user-logout-btn"
                >
                    Logout
                </button>
            </div>
        </div>
    );
};

/**
 * User Avatar
 */
const Avatar = observer(({ user, logout }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    return (
        <div className="tw-relative">
            <img
                src={getImageUrl(user.avatar) || '/images/icons/person.png'}
                alt="user-avatar"
                className="avatar tw-block"
                onClick={() => {
                    setIsPopupVisible(!isPopupVisible);
                }}
                data-test-id="user-avatar"
            />
            {isPopupVisible && (
                <AvatarPopup
                    logout={logout}
                    closeHandler={() => {
                        setIsPopupVisible(false);
                    }}
                />
            )}
        </div>
    );
});

/**
 *
 * @param {object} props
 *
 * @returns {ReactElement}
 */
function ShareMenuPopup({ closeHandler }) {
    const [copied, setCopied] = useState(false);
    const node = useRef();

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return; // clicked inside
        }
        closeHandler(); // hide on outside click
    };

    const onCopy = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 4000);
    };

    // attach/deattach event for detecting click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <ul
                ref={node}
                className="share-menu-top tw-bg-white tw-absolute tw-left-0 tw-text-black-chat tw-p-4 tw-w-auto tw-whitespace-nowrap"
            >
                <li
                    className="mb-2"
                    style={{ cursor: 'pointer' }}
                    onClick={onCopy}
                >
                    Share Bailiwik URL
                </li>
                <li>
                    <Link to="/flyer">Create a Flyer</Link>
                </li>
            </ul>
            {copied && (
                <Toast position="top" closeNotificationOnAnimationEnd={false} notification="Now you can share your Bailiwik with your friends by pasting in the URL" />
            )}
        </>
    );
}

const Header = (props) => {
    const rootStore = useStores();
    const [modalPosition, toggleModalPosition] = useState(false);
    const [findModal, toggleFindModal] = useState(false);
    const modalContentRef = useRef();
    const modalHeadlineRef = useRef();
    const history = useHistory();
    const [shareMenuVisibility, setShareMenuVisibility] = useState(false);

    const logout = () => {
        const isLoggingOut = window.confirm('Are you sure you want to logout?');
        if (!isLoggingOut) {
            // ok that was accident :)
            return false;
        }
        handleSignOut('Logout', rootStore);

        // ok we are done, move out :O
        history.push('/');
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            e.preventDefault();
            if (
                modalHeadlineRef.current &&
                !e.target.contains(modalContentRef.current) &&
                !e.target.contains(modalHeadlineRef.current)
            )
                toggleModalPosition(false);
        };

        if (!modalPosition)
            return document.removeEventListener('click', handleClickOutside);

        document.body.addEventListener('click', handleClickOutside);

        return () =>
            document.body.removeEventListener('click', handleClickOutside);
    }, [modalPosition]);
    return (
        <div className="header-wrapper">
            {findModal && (
                <FindBailiwik
                    onClose={() => toggleFindModal(false)}
                    currentUser={props.currentUser}
                    bailiwiks={props.bailiwiks}
                    switchBailiwik={props.switchBailiwik}
                />
            )}
            <header
                className="header row"
                style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5)), url(/images/themes/bg-theme${props?.bailiwik?.theme}.png)`,
                }}
            >
                <div className="col-4 d-flex justify-content-stat">
                    {props.isMember === true && (
                        <Avatar logout={logout} user={props.currentUser} />
                    )}
                    {props.isMember === false && (
                        <div
                            className="tw-absolute tw-top-3 tw-left-4"
                            onClick={() => {
                                if (props.isGuest) {
                                    logout();
                                } else {
                                    history.push('/find-bailiwik');
                                }
                            }}
                        >
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.83 7L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7H3.83Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    )}
                </div>
                <div className="col-4 justify-content-center d-flex">
                    <BailiwikAvatar bailiwik={props.bailiwik} isHeader={true} />
                </div>
                <div className="col-4 justify-content-end d-flex header-right-icon">
                    <div className="tw-relative">
                        <button
                            className=""
                            type="button"
                            onClick={() => {
                                setShareMenuVisibility((state) => !state);
                            }}
                        >
                            <svg
                                width="30"
                                height="28"
                                viewBox="-2 -4 35 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.41202 18.8233C0.667536 18.8307 -0.0144497 19.509 0.000232828 20.2516V30.592C0.000232828 31.3292 0.673042 32.0001 1.41202 32.0001H33.4125C34.1514 32.0001 34.8242 31.3292 34.8242 30.592V20.2516C34.8353 19.5072 34.1588 18.8233 33.4125 18.8233C32.6661 18.8233 31.9897 19.5071 32.0007 20.2516V29.1835H2.8238V20.2516C2.84035 19.4998 2.16572 18.816 1.41202 18.8233Z"
                                    fill="white"
                                />
                                <path
                                    d="M17.6448 0C17.3084 0.0128679 16.9187 0.152576 16.6742 0.382358L8.67408 7.91187C8.21635 8.42289 8.02701 9.40088 8.55643 9.97072C9.07851 10.5332 10.0914 10.5148 10.6153 9.95602L16.2332 4.66183V24.0004C16.2332 24.7798 16.8656 25.4122 17.645 25.4122C18.4244 25.4122 19.0568 24.7798 19.0568 24.0004V4.66183L24.6748 9.95602C25.1582 10.4376 26.206 10.5277 26.7336 9.97072C27.2612 9.41556 27.0921 8.40087 26.616 7.91187L18.6159 0.382358C18.342 0.124999 18.0203 0 17.6453 0H17.6448Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                        {shareMenuVisibility && (
                            <ShareMenuPopup
                                closeHandler={() =>
                                    setShareMenuVisibility(false)
                                }
                            />
                        )}
                    </div>
                    {!props.isGuest && (
                        <img
                            onClick={() => toggleFindModal(!modalPosition)}
                            src="/images/icons/header-search.png"
                        />
                    )}
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <div
                        //onClick={() => toggleModalPosition(!modalPosition)}
                        onClick={() => {
                            if (props.isGuest) {
                                toggleFindModal(true);
                            } else {
                                toggleModalPosition(!modalPosition); // show modal if props.isGuest is false
                            }
                        }}
                        className="bailiwik-title"
                    >
                        {props?.bailiwik?.name}
                        <img src="/images/icons/switcher-drop.png" />
                    </div>
                </div>
            </header>
            <div
                style={{ display: !modalPosition ? 'none' : 'block' }}
                className="bailiwik-switcher tw-container tw-w-full tw-max-w-2xl"
            >
                <div className="bailiwik-switcher-inner">
                    <div className="sticky-top">
                        <div
                            ref={modalHeadlineRef}
                            className="row justify-content-center align-items-center bio pt-4"
                        >
                            <img
                                onClick={() => toggleModalPosition(false)}
                                className="bailiwik-close"
                                src="/images/icons/close-white.png"
                            />
                            <div className="mt-4 bailiwik-data pb-2 px-2">
                                <p className="bailiwik-data-name">
                                    {props?.bailiwik?.name}
                                </p>
                                <div className="bailiwik-data-container mt-2 tw-space-x-6 tw-flex tw-flex-col sm:tw-flex-row">
                                    <p className="bailiwik-data-members">
                                        {props?.bailiwik?.memberCount} Members
                                    </p>
                                    <p className="bailiwik-data-hostname">
                                        {props?.bailiwik?.bailiff?.fullName} •
                                        👑
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center mb-6 members-containe pt-3 members-modal">
                            <div className="col-11 p-0 bailiwik-list d-flex justify-content-between">
                                <span>YOUR BAILIWIKS</span>
                                <span></span>
                            </div>
                            <div
                                ref={modalContentRef}
                                className="col-11 p-0 bailiwik-list"
                            >
                                <div
                                    //onMouseDown={() => toggleFindModal(true)}
                                    className="col-12 d-flex my-2 p-2 align-items-center find-bailiwik-font switch-hover"
                                    //onClick={() => {}}
                                    onClick={() => toggleFindModal(true)}
                                >
                                    <div className={'bailiwik-icon-container '}>
                                        <img
                                            src="/images/icons/find-bailiwik.png"
                                            alt="fin-bailiwik"
                                            className="bailiwik-icon"
                                        />
                                    </div>
                                    <div className="ml-3">Find a Bailiwik</div>
                                </div>
                                {props.bailiwiks.map((bailiwik) => (
                                    <div
                                        key={bailiwik._id}
                                        className="bailiwik-item-wrapper"
                                    >
                                        <BailiwikItem
                                            switchBailiwik={
                                                props.switchBailiwik
                                            }
                                            showDescription={true}
                                            bailiwik={bailiwik}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
