import { makeAutoObservable, toJS, runInAction } from 'mobx';
import {
    storeCachedChat,
    cachedChatUnsent,
    getCachedDMs,
    getCachedChat,
    getCachedChatUnsent,
    setCachedDMs,
} from '../services/deviceService';

/**
 * Chat mobx store
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export default class ChatStore {
    cachedChat;
    cachedDMs;
    cachedChatUnsent;
    newPostSendTimestamp = new Date();
    cachedChatUpdatedAt;
    loadedCachedDMs;
    loadedCachedChat;
    rootStore;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.reset();
    }

    reset(source) {
        this.cachedChat = [];
        this.cachedDMs = [];
        this.cachedChatUnsent = [];
        this.cachedChatUpdatedAt = new Date();
        this.loadedCachedDMs = false;
        this.loadedCachedChat = false;
    }

    getCachedDMs(directMessageUser, source) {
        if (!this.cachedDMs || this.cachedDMs.length === 0) {
            return [];
        }
        const filtered = this.cachedDMs.filter(
            (chat) => chat.directMessageUser === directMessageUser
        );
        if (filtered.length === 0) {
            return [];
        }
        const dmChat = toJS(filtered[0].chats);

        return Array.isArray(dmChat) ? dmChat : [dmChat];
    }

    cacheDirectMessages(chats, directMessageUser, source) {
        const isSaved =
            this.cachedDMs.filter(
                (item) => item.directMessageUser === directMessageUser
            ).length > 0;

        if (isSaved) {
            this.cachedDMs.map((data) => {
                if (data.directMessageUser === directMessageUser) {
                    return (data.chats = chats);
                }
                return data;
            });
        } else {
            this.cachedDMs.push({
                directMessageUser,
                chats,
            });
        }
        setCachedDMs(toJS(this.cachedDMs));
    }

    updateCachedChatTimestamp(source) {
        runInAction(() => {
            this.cachedChatUpdatedAt = new Date();
        });
    }
    setNewPostSendMessage(text) {
        runInAction(() => {
            this.newPostSendMessage = text;
        });
    }

    setNewPostSendTimestamp(timestamp) {
        runInAction(() => {
            this.newPostSendTimestamp = timestamp;
        });
    }
    async cacheUnsetChatMessage(messageId, bailiwikId) {
        //console.log('ChatStore doing cacheUnsetChat');

        const isSaved =
            this.cachedChat.filter((item) => item.bailiwik === bailiwikId)
                .length > 0;

        if (isSaved) {
            //console.log('ChatStore found chat')
            this.cachedChat.map((data) => {
                if (data.bailiwik === bailiwikId) {
                    return data.chats.filter((chatMessage) => {
                        if (chatMessage._id !== messageId) {
                            return chatMessage;
                        } else {
                            //console.log('removing chatMessage._id from cache');
                        }
                    });
                } else {
                    return data;
                }
            });
        } else {
            //console.log('ChatStore chat not cached');
        }
    }

    async removeCachedChatUnsent(message, source) {
        //console.log('ChatStore removeCached ChatUnsent:', message._id,'  from:', source );
        //this just stores as a flat array, this should have
        //few, if any, unsent posts. We may need to remove old unsent
        if (
            this.cachedChatUnsent.length > 0 &&
            this.cachedChatUnsent.filter((unsent) => unsent._id === message._id)
                .length > 0
        ) {
            runInAction(() => {
                this.cachedChatUnsent = this.cachedChatUnsent.filter(
                    (unsent) => {
                        if (unsent._id !== message._id) {
                            return unsent;
                        } else {
                            //console.log('unsent found in unsent list, removing');
                        }
                    }
                );
                this.cachedChatUpdatedAt = new Date();
            });
        }
        this.cacheUnsetChatMessage(message._id, message.bailiwik).then(() => {
            let cachedChatUnsentPrep = toJS(this.cachedChatUnsent);
            cachedChatUnsent(cachedChatUnsentPrep);
            storeCachedChat(toJS(this.cachedChat));
        });
    }

    async storeCachedChatUnsent(message) {
        //let's remove it before adding it back in case we
        // are attempting a resend
        await this.removeCachedChatUnsent(
            message._id,
            'ChatStore storeCachedChatUnsent'
        );

        //console.log('ChatStore check if already stored and store it');
        //this just stores as a flat array, this should have
        //few, if any, unsent posts. We may need to remove old unsent
        if (
            this.cachedChatUnsent.filter((unsent) => unsent._id === message._id)
                .length < 1
        ) {
            runInAction(() => {
                this.cachedChatUnsent.push(message);
            });
        } else {
            //console.log('ChatStore storeCachedChatUnsent attempted to remove item not in unsent list');
        }

        let cachedChatUnsentPrep = toJS(this.cachedChatUnsent);
        cachedChatUnsent(cachedChatUnsentPrep);
        this.cachedChatUpdatedAt = new Date();
    }

    async loadAllFromStorage(source) {
        console.log('loadAllFromStorage ChatStore and parse');
        //only load if not already loaded
        // this is triggered from Homescreen load and pn handling
        if (this.loadedCachedDMs === false) {
            this.cachedDMs = getCachedDMs();
            runInAction(() => {
                this.loadedCachedDMs = true;
            });
        }

        if (this.loadedCachedChat === false) {
            this.cachedChat = getCachedChat();
            this.cachedChatUnsent = getCachedChatUnsent();
            runInAction(() => {
                this.loadedCachedChat = true;
            });
        }
        this.cachedChatUpdatedAt = new Date();
    }
}
