import React from 'react'
import './style.css'

const ReportPost = (props) => {

    return (
        <>
            <div className="mm-question">
                <p>
                    Are you sure you want to report this post for violating our terms of service? Bailiwik will review within 24 hours.
                </p>
            </div>
            <div className="mm-button-confirm">
                <button onClick={props.onConfirm}>
                    REPORT
                </button>
            </div>
            <div className="mm-button mm-button-close">
                <button onClick={props.onClose}>
                    CANCEL
                </button>
            </div>
        </>
    )
}

export default ReportPost