import React from "react";
import IconSVG from '../../common/iconSVG'
import './style.css'

const ActionsToolbar = ({
    onComment,
    onLike,
    onRemove,
    onUpdate,
    onReport,
    actions = ['like', 'update', 'delete', 'comment'],
}, ref) => {
    return (
        <div ref={ref}>
            <div className="message-toolbar-container">
                {actions.indexOf('like') !== -1 && onLike && <div onClick={onLike} className="message-action-item">
                <img src="/images/icons/like-white.png"></img>
                </div>}
                {actions.indexOf('report') !== -1 && onReport && <div onClick={onReport} className="message-action-item">
                    <img src="/images/icons/report-icon.png"></img>
                </div>}
                {actions.indexOf('delete') !== -1 && onRemove && <div onClick={onRemove} className="message-action-item">
                    <img src="/images/icons/trash-circle.png"></img>
                </div>}
                {actions.indexOf('update') !== -1 && onUpdate && <div onClick={onUpdate} className="message-action-item">
                    <img src="/images/icons/pencil-circle.png"></img>
                </div>}
                {actions.indexOf('comment') !== -1 && onComment && <div onClick={onComment} className="message-action-item">
                    <img src="/images/icons/sms-circle.png"></img>
                </div>}
            </div>
        </div>
    )
}

export default React.forwardRef(ActionsToolbar)