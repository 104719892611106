import { EventEmitter } from 'events';
// import { getAuthToken, storeCachedChat, getCachedChat } from './deviceService';

// CANDIDATE FOR DELETION
/**
 * Chat services
 * @todo Get rid of class
 * @todo test
 * 
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
class ChatService extends EventEmitter {
}

export default new ChatService();
