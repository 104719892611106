import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import Messages from './Messages';
import EditTextModal from '../StandardModal/EditTextModal';
import Modal from '../StandardModal';
import DeleteModal from '../StandardModal/DeleteMediaModal';
import TextInput from '../../common/ChatToolbar/TextInput';
import { sanitize } from '../../../lib/utils.js';
import './style.css';
import IconSVG from '../../common/iconSVG';
import useSendMessage from '../../../core/hooks/useSendMessage';
import useToggleLike from '../../../core/hooks/useToggleLike';
import {
    removeMessage,
    editMessage,
} from '../../../core/utility/firebase-message-actions';
import Message from '../message';
import ChatService from '../../../services/ChatService';
import ObjectID from 'bson-objectid';
import { useLogToAmplitude } from '../../../core/utility/amplitude';
import { useStores } from '../../../lib/store';

const CommentsModal = (props) => {
    const [comment, setComment] = useState();
    const [commentToEdit, setCommentToEdit] = useState();
    const [commentToDelete, setCommentToDelete] = useState();
    const { bailiwikStore } = useStores();
    const toggleLike = useToggleLike();
    const containerRef = useRef();
    const treadsLength = useRef(props.parent.thread?.length);
    const sendMessage = useSendMessage(
        `thread/${props.parent._id}/messages`,
        { currentUser: props.currentUser },
        props.parent.bailiwik
    );

    useEffect(() => {
        setTimeout(
            () =>
                containerRef.current.scrollTo(
                    0,
                    containerRef.current.scrollHeight
                ),
            600
        );
    }, []);

    useLayoutEffect(() => {
        if (treadsLength.current === props.parent.thread?.length) {
            treadsLength.current = props.parent.thread?.length;
            return;
        }
        treadsLength.current = props.parent.thread?.length;
        containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
    }, [props]);

    const logToAmplitude = useLogToAmplitude();

    const handleKeyDown = (event) => {
        event.preventDefault();
        addComment();
    };

    /**
     * extracts the "type" from the parent post. Used by Amplitude analytics.
     *
     * @returns 'text', 'event', 'media', or 'deal'
     */
    const getThreadType = () => {
        const parent = props.parent;
        if (!parent) return null;
        const type = parent.type ? parent.type.toLowerCase() : null;
        return type;
    };

    const addComment = () => {
        const sanitized = sanitize(comment);
        if (!sanitized) return;

        if (props.isDM || props.isEvent || props.isDeal) {
            sendMessage('TEXT', { text: sanitized }, {});
            setComment('');
            const type = getThreadType();
            logToAmplitude(props.currentUser._id, bailiwikStore?.current?._id,
                bailiwikStore?.current?.name, 'Reply', { type: type });
        } else {
            /*
                Issue: Comments on Events and Deals are not working #341

                Action: added filter isDM to check whether comments are coming from DMs or Events / Deals and connecting to proper database.
            */

            const parent = props.parent;
            const child = {
                _id: ObjectID().toString(),
                thread: [],
                reports: [],
                chat: props.parent.chat,
                creator: props.currentUser,
                type: 'TEXT',
                bailiwik: props.parent.bailiwik,
                content: { text: sanitized },
                likes: { count: 0, users: [] },
                inThread: true,
                createdAt: new Date(),
                updatedAt: new Date(),
                postPreview: true,
            };

            const newChats = { ...parent };

            newChats.thread.push(child);
            props.updateData(newChats);

            ChatService.addToThread(parent, child);

            setComment('');
        }
    };

    const like = (comment) =>
        toggleLike(comment, { currentUser: props.currentUser });

    const remove = () => {
        // props.isDM checks whether chats are coming from Firebase.
        // DMs and chats on homescreen are from Firebase
        // Events and Deals are from mongoDB currently
        if (props.isDM || props.isEvent || props.isDeal) {
            removeMessage(commentToDelete);
            setCommentToDelete(false);
        } else {
            const { comment, index } = commentToDelete;
            const newChats = { ...props.parent };

            newChats.thread.splice(index, 1);
            props.updateData(newChats);

            const requestData = {
                chat_id: comment.chat,
                messageID: comment._id,
                parentId: props.parent._id,
                isThread: true,
            };

            ChatService.delete(requestData);
            setCommentToDelete(false);
        }
    };

    const handleEditComment = (commentText) => {
        editMessage(commentToEdit, commentText);

        return setCommentToEdit(false);
    };
    return (
        <div className="modal-container">
            {(commentToEdit || commentToDelete) && (
                <Modal
                    onClose={() =>
                        setCommentToDelete(false) || setCommentToEdit(false)
                    }
                    title={commentToDelete ? 'Confirm Delete' : 'Edit Comment'}
                >
                    {commentToDelete ? (
                        <DeleteModal
                            onClose={() => setCommentToDelete(false)}
                            onConfirm={remove}
                        />
                    ) : (
                        <EditTextModal
                            text={commentToEdit.content.text}
                            onPost={handleEditComment}
                        />
                    )}
                </Modal>
            )}
            <div className="modal-content">
                <div className="modal-headline">
                    <button onClick={props.close} className="modal-close">
                        <IconSVG
                            icon="backArrow"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                        />
                    </button>
                    <span>
                        {props.parent.content.event || props.parent.content.deal
                            ? ` Respond to Post ${
                                  props.parent.content.event
                                      ? props.parent.content.event.title
                                      : props.parent.content.deal.title
                              }`
                            : `Respond to Post by ${
                                  props.parent?.creator?.fullName || ''
                              }`}
                    </span>
                </div>
                <div ref={containerRef} className="modal-comments">
                    {props.children}
                    <Messages
                        isThread={true}
                        setCommentToDelete={setCommentToDelete}
                        setCommentToEdit={setCommentToEdit}
                        like={like}
                        currentUser={props.currentUser}
                        id={props.parent._id}
                    />
                    {props.parent.thread?.map((comment, index) => (
                        <Message
                            actions={[
                                'like',
                                'update',
                                comment?.creator?._id === props.currentUser._id
                                    ? 'delete'
                                    : '',
                            ]}
                            currentUser={props.currentUser}
                            message={comment}
                            like={() => like(comment, index)}
                            remove={() =>
                                setCommentToDelete({ comment, index })
                            }
                            update={() => setCommentToEdit({ comment, index })}
                            key={comment['_id']}
                        />
                    ))}
                </div>
                <div className="modal-chat-box d-flex justify-content-center align-items-center">
                    <div className=" input-container justify-content-center align-items-center mb-0 row d-flex">
                        <div className="message-row">
                            <TextInput
                                onEnter={(e) => handleKeyDown(e)}
                                // onKeyDown={handleKeyDown}
                                className="message-input modal-input"
                                type="text"
                                text={comment}
                                onChange={setComment}
                                placeholder="Say Something About This Post..."
                            />
                            <div className="em-input-button-bg">
                                <button
                                    onClick={addComment}
                                    value="Submit"
                                    className="button-bg"
                                >
                                    <img
                                        src="/images/icons/send-white.svg"
                                        alt="deals"
                                        className="input-button"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentsModal;
