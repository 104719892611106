import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'validate.js';

import { resetConfirm } from '../../../services/databaseService';
import useQuery from '../../../core/hooks/useQuery';
import Errors from '../../common/Errors';
import useLogin from '../../../core/hooks/useLogin';
import LoadingSpinnerWithModal from '../../common/LoadingSpinnerWithModal';
import VisiblePasswordField from '../../common/VisiblePasswordField';

const validationConstraints = {
    newPassword: {
        presence: {
            allowEmpty: false,
        },
    },
    newPasswordConfirm: {
        equality: 'newPassword',
    },
};

/**
 * Reset password page component
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function ResetPassword({ setBailiwikId }) {
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false);
    const query = useQuery();
    const history = useHistory();
    const login = useLogin('Reset Password');

    const validateInputs = () => {
        const errors = validate(
            {
                newPassword,
                newPasswordConfirm,
            },
            validationConstraints
        );
        if (errors) {
            setErrors(errors);
            return false;
        }
        return true;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (processing) {
            return;
        }

        // verify inputs
        if (!validateInputs()) {
            return;
        }
        setProcessing(true);
        // fire api
        resetConfirm({
            userId: query.get('id'),
            token: query.get('token'),
            password: newPassword,
        }).then((res) => {
            if (res && res.user) {
                const { email } = res.user;
                const callback = (bailiwikId) => {
                    setBailiwikId(bailiwikId);
                    setTimeout(() => {
                        history.push(
                            location?.state?.referrer ?? `/chat/${bailiwikId}`
                        );
                    });
                };
                return login(email, newPassword, false, callback);
            }
            setProcessing(false);
            window.alert('Something went wrong.');
        });

        return false;
    };

    return (
        <form
            className="tw-pt-2 tw-text-center"
            action=""
            onSubmit={submitHandler}
        >
            <h1 className="tw-text-xl">Enter Password from Email</h1>
            <p className="tw-text-center tw-text-sm tw-mt-4 tw-mx-3 tw-tracking-wider">
                Enter your new password
            </p>

            <label className="tw-mt-8 tw-w-full tw-text-left tw-tracking-wider">
                NEW PASSWORD
                <div className="tw-pt-4">
                    <VisiblePasswordField
                        name="new_password"
                        className="tw-w-full tw-bg-transparent tw-border-b tw-border-gray-500 tw-py-2"
                        placeholder="New Password*"
                        data-test-id="input-password"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                            setErrors({
                                ...errors,
                                newPassword: [],
                            });
                        }}
                    />
                </div>
                {errors.newPassword !== undefined && (
                    <Errors attr="newPassword" errors={errors.newPassword} />
                )}
            </label>
            <label className="tw-mt-8 tw-w-full tw-text-left tw-tracking-wider">
                CONFIRM PASSWORD
                <div className="tw-pt-4">
                    <VisiblePasswordField
                        name="new_confirm_password"
                        className="tw-w-full tw-bg-transparent tw-border-b tw-border-gray-500 tw-py-2"
                        placeholder="Confirm New Password*"
                        data-test-id="input-password-confirm"
                        value={newPasswordConfirm}
                        onChange={(e) => {
                            setNewPasswordConfirm(e.target.value);
                            setErrors({
                                ...errors,
                                newPasswordConfirm: [],
                            });
                        }}
                    />
                </div>
                {errors.newPasswordConfirm !== undefined && (
                    <Errors
                        attr="newPasswordConfirm"
                        errors={errors.newPasswordConfirm}
                    />
                )}
            </label>
            <button
                type="submit"
                data-test-id="btn-submit"
                className="tw-bg-orange-chat tw-py-4 tw-text-center tw-bottom-4 tw-left-0 tw-right-0 tw-rounded tw-w-full tw-mt-20"
                disabled={processing}
                onClick={submitHandler}
            >
                SET NEW PASSWORD
            </button>
            {processing && (
                <LoadingSpinnerWithModal message="Please wait a few minutes for us to reset your password and log you in." />
            )}
        </form>
    );
}
