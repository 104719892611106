import React from 'react';
import PropTypes from 'prop-types';

export default function Row({ children }) {
    return (
        <div className="tw-flex tw-border-b tw-border-gray-400 tw-border-opacity-20 tw-pt-2 tw-pb-2 tw-px-2 tw-mt-1.5 tw-items-center">
            {children}
        </div>
    );
}

Row.propTypes = {
    children: PropTypes.node.isRequired,
};
