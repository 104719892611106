import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { getImageUrl } from '../../../lib/utils';
import iconPerson from '../../../assets/person.png';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../../../core/contexts/FirebaseContext';
import { getCurrentUser } from '../../../services/deviceService';
import { getPageWithBailiwikIdFromURL } from '../../../helpers';

export default function Profile({
    data,
    closeHandler,
    isBailiff = false,
    isHost = false,
    top = 'tw-top-0',
}) {
    const { firebase } = useFirebase();
    const bailiwikId = getPageWithBailiwikIdFromURL()?.[1]
    const params = useParams();
    const location = useLocation();
    const user = getCurrentUser();
    const history = useHistory();
    const member = { ...data };
    const [name] = useState(() => {
        return data.displayName ? data.displayName : data.fullName;
    });
    const getFirstName = () => {
        return name.split(' ')[0];
    };
    const { t } = useTranslation();

  const getConversation = () => {
    console.log('MembersList Profile getConversation params', params);
    const collection = firebase.firestore().collection('DM');
    

        return collection
            .where(`users.${member._id}.isMember`, '==', true)
            .where(`users.${user._id}.isMember`, '==', true)
            .get()
            .then((snapshot) => {
              if (snapshot.docs.length) {
                  console.log('MembersList Profile getConversation snapshot.docs.length', snapshot.docs.length)
                    const docRef = snapshot.docs[0];

                    return {
                        ...docRef.data(),
                        _id: docRef.id,
                    };
                }

                const user1 = {
                    _id: user._id,
                    name: user.displayName || user.fullName,
                    avatar: user.avatar
                        ? `${process.env.ASSETS_URL}/${user.avatar}`
                        : '',
                    isMember: true,
                };

                const user2 = {
                    _id: member._id,
                    name: member.displayName || member.fullName,
                    avatar: member.avatar
                        ? `${process.env.ASSETS_URL}/${member.avatar}`
                        : '',
                    isMember: true,
                };

                return collection
                    .add({
                        users: {
                            [member._id]: user1,
                            [user._id]: user2,
                        },
                    })
                    .then((docRef) => docRef.get())
                    .then((docRef) => ({
                        ...docRef.data(),
                        _id: docRef.id,
                    }));
            });
    };

    const handleDM = async (e) => {
        const conversation = await getConversation();
        if (location.pathname.includes('chat')) {
            closeHandler();
        }
        history.push(`/dm/${bailiwikId}/${data._id}/${conversation._id}`);
    };

    return (
        <div
            data-test-id="modal-profile"
            className={`tw-absolute ${top} tw-bottom-0 tw-left-0 tw-right-0 tw-bg-blue-bg tw-z-30 tw-p-3 tw-pt-4`}
        >
            <div className="tw-relative tw-text-center">
                <button
                    data-test-id="btn-modal-close"
                    className="tw-absolute tw-left-1 tw-top-1"
                    onClick={closeHandler}
                >
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="white"
                        />
                    </svg>
                </button>
                <div className="">{getFirstName()}&apos;s Profile</div>
            </div>
            <div className="tw-text-center tw-max-w-lg tw-mx-auto">
                <div
                    className="tw-rounded-full tw-h-24 tw-w-24 tw-mt-14 tw-mx-auto tw-overflow-hidden"
                    style={{
                        background: `url("${iconPerson}") no-repeat`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    {!!data.avatar && (
                        <img
                            className="tw-w-full tw-h-full tw-object-cover"
                            src={getImageUrl(data.avatar)}
                        />
                    )}
                </div>
                <div className="tw-mt-7">
                    <div className="tw-text-lg tw-leading-5">
                        {name}
                        {(isBailiff || isHost) && (
                            <>
                                {' '}
                                •
                                {isBailiff && (
                                    <span className="tw-pl-3">👑</span>
                                )}
                                {isHost && (
                                    <span
                                        className="tw-pl-3"
                                        style={{ filter: 'grayscale(1)' }}
                                    >
                                        👑
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                    {data.occupation && (
                        <div className="tw-text-gray-text-3 tw-text-sm">
                            {data.occupation}
                        </div>
                    )}
                    {data.bio && (
                        <div className="tw-text-gray-text-3 tw-mt-7 tw-py-7 tw-border-t tw-border-b tw-border-gray-600">
                            {data.bio}
                        </div>
                    )}
                </div>
                {data.interests && (
                    <div className="tw-text-gray-text-3 tw-mt-2 tw-border-b tw-border-gray-400 tw-pt-7 tw-pb-5">
                        {data.interests}
                    </div>
                )}
                <div className="tw-mt-9 tw-mx-2">
                    <span
                        onClick={handleDM}
                        data-test-id="lnk-direct-message"
                        className="tw-block tw-mx-auto tw-text-center py-3 tw-bg-orange-chat tw-text-white tw-text-sm tw-tracking-wider tw-max-w-xs"
                    >
                        {t('DIRECT MESSAGE')}
                    </span>
                </div>
            </div>
        </div>
    );
}

Profile.propTypes = {
    data: PropTypes.object.isRequired,
    closeHandler: PropTypes.func.isRequired,
    isBailiff: PropTypes.bool,
    isHost: PropTypes.bool,
    top: PropTypes.string,
};
