import { useState,useEffect } from 'react'
import { useFirebase } from '../contexts/FirebaseContext'
import { useBadgesCount } from './useBadgeCount'

const getConversationPartner = (users, userId) => Object.values(users).find(u => u._id !== userId)
const createConversation = conversation => {
    return {
      image: {uri: conversation.with.avatar},
      title: conversation.with.name,
      description: conversation.lastMessage.content.text,
      createdAt: new Date(conversation.lastMessage.createdAt * -1),
      conversation,
      type: 'DIRECT',
      header: 'DIRECT',
      timestamp: true,
      avatar: true,
      size: 'med',
      unread: conversation.unread,
      swipeable: true,
      swipeableType: 'notification',
      arrow: false,
      button: true,
      padding: true
    }
  }


const useConversations = (userId, ) => {
    const [conversations, setConversations] = useState([]);
    const {DMBadges} = useBadgesCount(userId)
    const { firebase } = useFirebase()
  
    const getConversations = () => {
      const collection = firebase.firestore().collection('DM');
      const unsubscribe = collection
        .where(`users.${userId}.isMember`, '==', true)
        .onSnapshot((snapshot, context) => {
          const newConversations = snapshot.docs
            .filter(doc => doc.data().lastMessage)
            .sort((a, b) => (a.data().lastMessage.createdAt < b.data().lastMessage.createdAt ? -1 : 1))
            .map(doc => {
              const conversation = { 
                ...doc.data(),
                id: doc.id,
                unread: DMBadges && DMBadges[doc.id]?.unseenCount > 0
              };
              // TODO: set conversation.unread based on badges, assign badgeCount to conversation for detecting unread
              conversation.with = getConversationPartner(conversation.users, userId);
              return createConversation(conversation);
            });
          setConversations([]);
          setConversations(newConversations);
        })
  
      return unsubscribe;
    }
  
    useEffect(() => {
      const unsubscribe = getConversations();
  
      return () => unsubscribe()
    }, [DMBadges]);
  
    return conversations;
  }

export default useConversations