import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { getPageWithBailiwikIdFromURL } from '../helpers';

import {
    getBailiwik,
    getUser,
    getUserBailiwiks,
} from '../services/databaseService';
import {
    getAuthToken,
    getBailiwiks,
    getCurrentUser,
    setBailiwiks,
    setCurrentUser,
} from '../services/deviceService';

/**
 * User store
 *
 * @author Copied from mobile app repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export default class UserStore {
    currentUser;
    bailiwiks;
    notifications;
    rootStore;
    isGuest;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.reset();
    }

    reset() {
        this.currentUser = {};
        this.isGuest = false;
        this.bailiwiks = [];
        this.notifications = {};
    }

    /**
     * Update store data
     *
     * @param {object} data
     * @param {string} source
     *
     * @returns {void}
     *
     * @author Copied from Mobile App code
     * @author Gihan S <gihanshp@gmail.com>
     */
    async updateData(data) {
        const { user, userBailiwiks } = data;
        setCurrentUser(user);
        const isGuest = user.email === process.env.GUEST_EMAIL;
        const [, bID] = getPageWithBailiwikIdFromURL();

        let bailiwiks = isGuest
            ? [bID || process.env.GUEST_DEFAULT_BAILIWIK]
            : [];

        try {
            let current = null;
            if (isGuest) {
                const bailiwik = await getBailiwik(
                    bID || process.env.GUEST_DEFAULT_BAILIWIK
                );
                current = bailiwik.bailiwik;
                await this.rootStore.bailiwikStore.load(current._id);
                bailiwiks = [current];
            } else {
                if (userBailiwiks?.bailiwiks) {
                    let bailiwiksPrep = toJS(userBailiwiks.bailiwiks);
                    setBailiwiks(bailiwiksPrep);
                    await this.rootStore.bailiwikStore.load(
                        userBailiwiks.bailiwiks[0]?._id
                    );
                }
                bailiwiks = userBailiwiks?.bailiwiks || [];
            }
            runInAction(async () => {
                this.isGuest = isGuest;
                this.currentUser = { ...user };
                this.bailiwiks = [...bailiwiks];
                this.rootStore.bailiwikStore.current = current?._id
                    ? current
                    : bailiwiks[0];
            });
        } catch (e) {
            console.log('error getting user bailiwiks', e);
        }
    }

    /**
     * Load user data from API
     *
     * @param {string} source
     *
     * @returns {null|object} user data
     *
     * @author Copied from Mobile App repo
     * @author Gihan S <gihanshp@gmail.com>
     */
    async load(source) {
        const storageToken = getAuthToken();
        if (!storageToken) {
            console.log('Cannot find user!');
            return null;
        }

        const data = await getUser(source).catch((err) => console.log(err));
        if (!data || !data.user) {
            console.log('Cannot find user!');
            return null;
        }
        await this?.updateData(data, source);
        return data.user;
    }

    /**
     * Load data from storage
     *
     * @param {string} source
     *
     * @returns {void}
     *
     * @author Copied from Mobile App repo
     * @author Gihan S <gihanshp@gmail.com>
     */
    async loadAllFromStorage(source) {
        console.log('UserStore doing loadAllFromStorage:', source);
        if (this.currentUser && this.currentUser._id) {
            return;
        }
        const token = await getAuthToken();
        if (!token) {
            return;
        }
        const user = await getCurrentUser();
        if (!user) {
            return;
        }
        await runInAction(() => {
            this.currentUser = user;
            this.isGuest = user.email === process.env.GUEST_EMAIL;
            this.bailiwiks = getBailiwiks();
        });
    }

    get getUserId() {
        if (!this.currentUser || !this.currentUser._id) {
            return null;
        }

        return this.currentUser._id;
    }

    get getUserName() {
        if (!this.currentUser || !this.currentUser._id) {
            return null;
        }

        return this.currentUser.displayName
            ? this.currentUser.displayName
            : this.currentUser.fullName
            ? this.currentUser.fullName
            : 'Another Member';
    }

    get isLoggedIn() {
        if (this.getUserId === null) {
            return false;
        }
        return true;
    }

    get getUserProfile() {
        if (!this.currentUser) {
            return null;
        }
        const {
            _id,
            avatar,
            bio,
            birthday,
            displayName,
            fullName,
            interests,
            occupation,
            pushSettings,
            pushToken,
            website,
        } = this.currentUser;

        return {
            _id,
            avatar,
            bio,
            birthday,
            displayName,
            fullName,
            interests,
            occupation,
            pushSettings: {
                ...pushSettings,
            },
            pushToken,
            website,
        };
    }

    increaseBadgeCount(data) {
        if (data.type === 'DIRECT') {
            if (this.notifications && this.notifications.direct) {
                this.notifications.direct = [
                    ...this.notifications.direct,
                    data._id,
                ];
            }
        } else {
            if (this.notifications && this.notifications.chat) {
                this.notifications.chat = [
                    ...this.notifications.chat,
                    data._id,
                ];
            }
        }
    }

    decreaseBadgeCount(item, chatID) {
        if (item) {
            if (item.type === 'Direct Message') {
                const creator = item.creator._id || item.creator;
                if (this.notifications && this.notifications.direct) {
                    this.notifications.direct =
                        this.notifications.direct.filter(
                            (notification) =>
                                notification &&
                                (notification.creator &&
                                notification.creator._id
                                    ? notification.creator._id
                                    : notification.creator) !== creator &&
                                notification.chat !== item.chat
                        );
                }
            } else {
                const creator = item.creator._id || item.creator;
                if (this.notifications && this.notifications.all) {
                    this.notifications.all = this.notifications.all.filter(
                        (notification) => notification._id !== item._id
                    );
                }

                if (this.notifications && this.notifications.chat) {
                    this.notifications.chat = this.notifications.chat.filter(
                        (notification) =>
                            notification &&
                            (notification.creator && notification.creator._id
                                ? notification.creator._id
                                : notification.creator) !== creator &&
                            notification.chat !== item.chat
                    );
                }
            }
        } else {
            // Just clear all chats if no items are provided
            //console.log('UserStore clear chat count');
            if (this.notifications && this.notifications.chat) {
                this.notifications.chat = this.notifications.chat.filter(
                    (item) => item.chat !== chatID
                );
            }
        }
    }

    isBanned(bailiwik, banList) {
        if (banList) {
            return banList.indexOf(bailiwik) > -1;
        } else {
            return (
                this.currentUser &&
                this.currentUser.bailiwiksBanned &&
                this.currentUser.bailiwiksBanned.indexOf(bailiwik) > -1
            );
        }
    }

    isBlocked(id) {
        return (
            this.currentUser &&
            this.currentUser.blocks &&
            this.currentUser.blocks.indexOf(id) > -1
        );
    }
}
