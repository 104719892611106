import React, { useState } from 'react';
import Linkify from 'react-linkify';
import moment from 'moment';

import YoutubeContent from './youtube-content';
import { sanitize, getImageUrl } from '../../lib/utils';
import MessageContent from '../MessageContent';
import { getHostnameFromDomain } from '../../helpers';
import { useStores } from '../../lib/store';

const LeftMessage = (props, ref) => {
    const [toolbar, toggleToolbar] = useState(false);
    const { userStore } = useStores();
    let { isGuest: isUserGuest } = userStore;

    return (
        <>
            <div
                onClick={() =>
                    !isUserGuest ? toggleToolbar(!toolbar) : () => {}
                }
                className="message-container-left sb3 mt-2"
            >
                <div ref={ref} className="row ml-2 chat-row">
                    <div className="chat-container-left black-font">
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => {
                                if (
                                    /^https:\/\/www.youtube\.com|https:\/\/www.youtu\.be/.test(
                                        decoratedHref
                                    )
                                ) {
                                    return (
                                        <YoutubeContent
                                            url={decoratedHref}
                                            message={props.message}
                                            key={key}
                                        />
                                    );
                                }

                                return (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="tw-underline"
                                        href={decoratedHref}
                                        key={key}
                                    >
                                        {getHostnameFromDomain(decoratedText)}
                                    </a>
                                );
                            }}
                        >
                            <MessageContent
                                showProfile={
                                    !isUserGuest ? props.showProfile : () => {}
                                }
                                text={sanitize(props.message.content.text)}
                            />
                        </Linkify>
                        {props.message.edited && (
                            <span className="edited-badge">(EDITED)</span>
                        )}
                        {(props.message.likes.count > 0 ||
                            props?.threads?.length > 0) && (
                            <span
                                style={{ right: '-2rem', bottom: '-1.5rem' }}
                                className="message-likes"
                            >
                                {props.message.likes.count > 0 && (
                                    <div className="message-action-container">
                                        <span
                                            onClick={
                                                !isUserGuest
                                                    ? props.like
                                                    : () => {}
                                            }
                                            className="message-likes-count"
                                        >
                                            {props.message.likes.count}
                                        </span>
                                        <img
                                            src="/images/icons/like.png"
                                            alt="like-btn"
                                            className="media-like mr-1"
                                        />
                                    </div>
                                )}
                                {props?.threads?.length > 0 && (
                                    <div className="message-action-container">
                                        <span className="message-likes-count">
                                            {props?.threads?.length}
                                        </span>
                                        <img
                                            src="/images/icons/thread.png"
                                            alt="comment-btn"
                                            className="media-comment mr-1"
                                        />
                                    </div>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex photo-row mt-1">
                <img
                    src={getImageUrl(props.message?.creator?.avatar) || '/images/icons/person.png'}
                    alt="user-avatar"
                    className={
                        !props.message?.creator?.avatar
                            ? 'img-left card-shadow mr-2 border border-secondary'
                            : 'img-left card-shadow mr-2'
                    }
                    onClick={() =>
                        !isUserGuest &&
                        props.showProfile(props.message?.creator)
                    }
                />

                {props.creator && (
                    <div className="user align-self-center">
                        {`${props.creator} - ${convertTime(props.time)}`}
                    </div>
                )}
            </div>
        </>
    );
};

const convertTime = (props) => {
    return `${moment(props).fromNow()}`;
};

export default React.forwardRef(LeftMessage);
