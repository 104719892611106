import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Sent from './Sent';
import MediaPost from '../../../components/media-post'
import Received from './Received';
import Modal from '../Modal';
import useToggleLike from '../../../core/hooks/useToggleLike';
import {  
    removeMessage,
} from '../../../core/utility/firebase-message-actions'

/**
 * Messages Component
 *
 * This component lists the messages in DMs area (Messages tab).
 * 
 *
 * @param {object}  * myId is the userId of the signed in user
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Messages({
    myId,
    memberId,
    memberProfile,
    myProfile,
    editHandler,
    onEditMedia = () => {},
    messages = [],
    report = () => {},
}) {
    const toggleLike = useToggleLike();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletingMessage, setDeletingMessage] = useState(null);
    const scrollingAnchor = useRef(null);
    // scroll to bottom
    // Disabling this. This keeps on pushing DMs to the bottom on reload
    // useEffect(() => {
    //     console.log(messages[0])
    //     messages[0].content.media ? setTimeout(() => scrollingAnchor.current.scrollIntoView(), 600) : scrollingAnchor.current.scrollIntoView()
    // }, [messages]);

    const deleteHandler = () => {
        removeMessage(deletingMessage)
        setDeleteModal(false);
        setDeletingMessage(null);
    };

    return (
        <div className="tw-flex tw-min-h-full tw-flex-col-reverse tw-pb-4">
            <div ref={scrollingAnchor} />
            {messages.map((message, idx) => {
                if(message.content.media) return (
                    <MediaPost
                        inThread={true}
                        edit={message.creator._id === myId || message.creator === myId ? () => onEditMedia(message) : null}
                        remove={(message.creator._id === myId || message.creator === myId)
                            ? () =>  {
                                setDeletingMessage(message);
                                setDeleteModal(true)
                            }
                            : null
                        }
                        report={message.creator._id === myId || message.creator === myId ? () => null: report}
                        like={() => toggleLike(message, {currentUser: myProfile})}
                        comment={() => {}}
                        creator={message?.creator?.fullName}
                        currentUser={myProfile}
                        time={message.createdAt}
                        post={{
                            ...message,
                            createdAt: (new Date(message.createdAt)).toISOString().toString(),
                            likes: {
                                ...message.likes, 
                                users: Object.values(
                                    message.likes.users 
                                        ? message.likes.users 
                                        : {}
                                )
                                
                            }
                        }}
                        // showProfile={showProfile}
                        threads={[]}
                    />
                )
                if (message.creator._id === myId || message.creator === myId) {
                    return (
                        <Sent
                            key={idx}
                            message={message}
                            deleteHandler={(msg) => {
                                setDeletingMessage(msg);
                                setDeleteModal(true);
                            }}
                            author={myProfile}
                            myProfile={myProfile}
                            editHandler={editHandler}
                            memberId={memberId}
                            toggleLike={(message) => toggleLike(message, {currentUser: myProfile})}
                        />
                    );
                }
                return (
                    <Received
                        key={idx}
                        userId={myId}
                        message={message}
                        author={memberProfile}
                        myProfile={myProfile}
                        memberId={memberId}
                        toggleLike={(message) => toggleLike(message, {currentUser: myProfile})}

                    />
                );
            })}
            {deleteModal && (
                <Modal
                    closeHandler={() => {
                        setDeleteModal(false);
                    }}
                >
                    <div
                        data-test-id="modal-delete-confirm"
                        className="tw-text-black-chat tw-text-center"
                    >
                        <h3 className="tw-font-bold tw-text-lg">
                            Confirm Delete
                        </h3>
                        <p className="tw-mt-10">
                            Are you sure you want to delete this media?
                        </p>
                        <div className="tw-mt-10 tw-text-xs">
                            <button
                                data-test-id="btn-confirm-delete"
                                className="tw-block tw-bg-orange-chat tw-mx-auto tw-py-2 tw-px-6  tw-tracking-widest tw-text-white"
                                onClick={deleteHandler}
                            >
                                DELETE
                            </button>
                            <button
                                data-test-id="btn-cancel-delete"
                                className="tw-mt-4 tw-block tw-w-20 tw-mx-auto tw-tracking-widest"
                                onClick={() => {
                                    setDeleteModal(false);
                                }}
                            >
                                CANCEL
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

Messages.default= {
    messages: [],
};

Messages.propTypes = {
    myId: PropTypes.string.isRequired,
    chatId: PropTypes.string.isRequired,
    memberId: PropTypes.string.isRequired,
    editHandler: PropTypes.func.isRequired,
    memberProfile: PropTypes.object.isRequired,
    myProfile: PropTypes.object.isRequired,
    messages: PropTypes.array,
};
