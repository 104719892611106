import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
// Previously, nl2br was used in media-post.jsx, left-message.jsx, and
// right-message.jsx. Those have been removed (see #774).
// The sole remaining use of nl2br is here in the Messages tab for dms.
// This tab doesn't contain user mentions, so MessageContent component is not
// used. Apparently, MessageContent takes care of linebreaks in its own way.
// I didn't investigate it further.
import nl2br from 'react-nl2br';

import { sanitize } from '../../../lib/utils';
import { getHostnameFromDomain } from '../../../helpers';

/**
 * TextMessage Component
 *
 * Display the text messages
 *
 * @param {objec} props props for the text message
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function TextMessage({ text, sender, edited }) {
    const className = sender ? 'tw-text-gray-50' : 'tw-text-black-chat';

    return (
        <div className={className}>
            <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => {

                 return(   
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="tw-underline"
                        href={decoratedHref}
                        key={key}
                    >
                        {getHostnameFromDomain(decoratedText)}
                    </a>
                    );
                }}
            >
                {nl2br(sanitize(text))}
                {edited && <span className="tw-text-xs"> (EDITED)</span>}
            </Linkify>
        </div>
    );
}

TextMessage.propTypes = {
    text: PropTypes.string.isRequired,
    sender: PropTypes.bool,
    edited: PropTypes.bool,
};

TextMessage.defaultProps = {
    sender: false,
    edited: false,
};
