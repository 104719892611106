import UserStore from '../stores/UserStore';
import BailiwikStore from '../stores/BailiwikStore';
import ChatStore from '../stores/ChatStore';
import NotificationStore from './NotificationStore';
import MemberStore from './MemberStore';

/**
 * Root store
 * @see https://dev.to/ivandotv/mobx-root-store-pattern-with-react-hooks-318d
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export default class RootStore {
    userStore
    bailiwikStore
    chatStore
    notificationStore
    memberStore

    constructor() {
        this.userStore = new UserStore(this);
        this.bailiwikStore = new BailiwikStore(this);
        this.chatStore = new ChatStore(this);
        this.notificationStore = new NotificationStore(this);
        this.memberStore = new MemberStore(this);
    }
}
