import React from 'react';
import PropTypes from 'prop-types';

/**
 * Image placeholder component
 *
 * @param {object} props
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function ImagePlaceholder({ onClick }) {
    return (
        <div
            className="tw-bg-gray-300 tw-w-full tw-h-full tw-relative tw-flex tw-justify-center tw-items-center"
            onClick={onClick}
            style={{
                backgroundImage: 'url("/images/default-flyer-picture.png")',
                backgroundSize: 'cover',
            }}
        >
            {/* <div className="tw-text-center">
                <svg
                    width="160"
                    height="108"
                    viewBox="0 0 160 108"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M129 40.9327C124.467 17.9327 104.267 0.666016 80 0.666016C60.7333 0.666016 44 11.5993 35.6667 27.5993C15.6 29.7327 0 46.7327 0 67.3327C0 89.3993 17.9333 107.333 40 107.333H126.667C145.067 107.333 160 92.3993 160 73.9993C160 56.3993 146.333 42.1327 129 40.9327ZM93.3333 60.666V87.3327H66.6667V60.666H46.6667L77.6667 29.666C79 28.3327 81.0667 28.3327 82.4 29.666L113.333 60.666H93.3333Z"
                        fill="#364C80"
                    />
                </svg>
                <div className="tw-p-5 tw-text-blue-header">Upload Image</div>
            </div> */}

            <button
                className="tw-bg-gray-900 tw-opacity-50 tw-py-3 tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-block tw-w-full"
                type="button"
            >
                CHANGE PHOTO
            </button>
        </div>
    );
}

ImagePlaceholder.propTypes = {
    onClick: PropTypes.func.isRequired,
};
