import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './components/AppContainer';
import i18n from './i18n';
import * as amplitude from '@amplitude/analytics-browser';

import * as Sentry from '@sentry/react';
console.log('Sentry init');
Sentry.init({
  dsn: 'https://5f98a52e139f4394900699b736521e82@o268446.ingest.sentry.io/4505105293443072',
  debug: true,
  maxBreadcrumbs: 40,
});
// Default settings for Amplitude browser SDK are different from
// our React Native settings, but I see no reason to change them.
amplitude.init(process.env.AMPLITUDE_API_KEY, {
  serverZone: 'US',
  logLevel: amplitude.Types.LogLevel.Warn, // Normally use Warn but can use Debug
});

ReactDOM.render(<AppContainer />, document.querySelector('#root'));
