import React, { useState, useEffect } from 'react';
import BailiwikAvatar from '../../bailiwik-avatar';
import useJoinBailiwik from '../../../core/hooks/useJoinBailiwik';
import LoadingSpinner from '../../UI/loading-spinner';
import './style.css';

import {
    updateBailiwiks
} from '../../../services/deviceService';

const JoinBailiwik = (props) => {
    const [accessCode, setAccessCode] = useState('');
    const { joinBailiwik, loading } = useJoinBailiwik();
    const handleJoin = async (e) => {
        e.preventDefault();

        if (props.bailiwik.exclusive) {
            if (props.bailiwik.accessCode !== accessCode) {
                window.alert(
                    'Incorrect code. Please check the code you received'
                );
                return false;
            }
        }

        const result = await joinBailiwik(props.bailiwik);
        updateBailiwiks(props.bailiwik); // update bailiwiks in local storage

        if (result.success) return props.onJoin(result.bailiwik);
    };

    useEffect(() => {
        // hide bottom nav bar
        props.toggleBottomNav(false);
        return () => {
            // show bottom nav bar
            props.toggleBottomNav(true);
        };
    }, []);

    return (
        <div className="w-100 join-container">
            {loading && (
                <div className="find-loading-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className="join-content p-3">
                <div className="w-100 mt-3 d-flex justify-content-center">
                    <BailiwikAvatar bailiwik={props.bailiwik} isHeader={true} />
                </div>
                <div className="join-title w-100 mt-4 d-flex justify-content-center">
                    {props.bailiwik.name}
                </div>
                {props.bailiwik.description && (
                    <div className="join-description w-100 mt-4 d-flex justify-content-center">
                        {props.bailiwik.description}
                    </div>
                )}
                <div className="join-info w-100 my-4 d-flex justify-content-around">
                    <span>{props.bailiwik.memberCount} Members</span>
                    <span>
                        {props?.bailiwik?.bailiff?.displayName
                            ? props?.bailiwik?.bailiff?.displayName
                            : props?.bailiwik?.bailiff?.fullName}{' '}
                        • 👑
                    </span>
                </div>
            </div>
            <p className="join-message mt-5">
                You are not a member of this Bailiwik yet. Click “Join This
                Bailiwik” below to become a member of this Bailiwik.
            </p>
            <form action="" onSubmit={handleJoin}>
                {props.bailiwik.exclusive === true && (
                    <div>
                        <input
                            name="access_code"
                            placeholder="Access Code"
                            className="tw-w-full tw-bg-transparent tw-py-2 tw-border-b tw-border-gray-300 tw-mt-4 tw-text-center"
                            value={accessCode}
                            onChange={(e) => setAccessCode(e.target.value)}
                        />
                    </div>
                )}
                <div className="join-button mt-5">
                    <button onClick={handleJoin}>JOIN THIS BAILIWIK</button>
                </div>
            </form>
        </div>
    );
};

export default JoinBailiwik;
