const mentionRegEx = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;

const replaceMentionValues = (value, replacer) =>
    value.replace(mentionRegEx, (fullMatch, original, trigger, name, id) =>
        replacer({
            original,
            trigger,
            name,
            id,
        })
    );

export const isWordMention = (word) =>
    word.startsWith('___MENTION') && word.endsWith('MENTION___');

export const replaceMentionHumanReadable = (word) => {
    const humanReadable = word
        .replace('___MENTION', '')
        .replace('MENTION___', '')
        .replace(new RegExp('MENTION', 'g'), ' ');

    return humanReadable;
};

export const replacedContent = (content) =>
    replaceMentionValues(
        content,
        ({ name }) =>
            `___MENTION______MENTION@${name.replace(
                /\s/g,
                'MENTION'
            )}MENTION______MENTION___`
    ).split('___MENTION___');

export const getMentionData = (content) => {
    const response = {};
    const data = content?.match(mentionRegEx);
    if (data) {
        for (let row of data) {
            const [username, id] = row.substring(2).slice(0, -1).split('](');
            response[`@${username}`] = id;
        }
    }

    return response;
};

export const getRepostData = (dealEventContent) => {
    const response = {
        allDay: dealEventContent.allDay,
        description: dealEventContent.description,
        image: dealEventContent.image,
        locationAddress: dealEventContent.locationAddress,
        locationName: dealEventContent.locationName,
        title: dealEventContent.title,
    };
    return response;
};
