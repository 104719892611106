// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l46OTVM_O2oz0Jo-p6LqQ::-moz-placeholder {\n  color: #f27f45;\n  opacity: 70%;\n}\n\n.l46OTVM_O2oz0Jo-p6LqQ:-ms-input-placeholder {\n  color: #f27f45;\n  opacity: 70%;\n}\n\n.l46OTVM_O2oz0Jo-p6LqQ::placeholder {\n  color: #f27f45;\n  opacity: 70%;\n}\n\n._3sJFHnSIwYfq0iKVNHbYu8::-moz-placeholder {\n  color: #fff;\n}\n\n._3sJFHnSIwYfq0iKVNHbYu8:-ms-input-placeholder {\n  color: #fff;\n}\n\n._3sJFHnSIwYfq0iKVNHbYu8::placeholder {\n  color: #fff;\n}\n", "",{"version":3,"sources":["webpack://./client/components/Profile/assets/styles.module.css"],"names":[],"mappings":"AAAA;EACI,cAAc;EACd,YAAY;AAChB;;AAHA;EACI,cAAc;EACd,YAAY;AAChB;;AAHA;EACI,cAAc;EACd,YAAY;AAChB;;AAEA;EACI,WAAW;AACf;;AAFA;EACI,WAAW;AACf;;AAFA;EACI,WAAW;AACf","sourcesContent":[".red::placeholder {\n    color: #f27f45;\n    opacity: 70%;\n}\n\n.frmInput::placeholder {\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": "l46OTVM_O2oz0Jo-p6LqQ",
	"frmInput": "_3sJFHnSIwYfq0iKVNHbYu8"
};
export default ___CSS_LOADER_EXPORT___;
