import React from 'react';

function BailiwikAvatar (props) {
  if (!props.bailiwik.avatar) {
    return (
      <div className={`bailiwik-icon-container${props.isHeader ? '-header' : ''}`}>
        <img
          src={props.bailiwik.icon}
          alt={`${props.bailiwik.name} avatar`}
          className={`bailiwik-icon${props.isHeader ? '-header' : ''}`} />
      </div>
    )
  } else {
    return (
      <img
        src={'https://bailiwik-dev.s3.amazonaws.com/' + props.bailiwik.avatar}
        alt={`${props.bailiwik.name} avatar`}
        className={`bailiwik-avi${props.isHeader ? '-header' : ''}`} />)
  }
}

export default BailiwikAvatar;
