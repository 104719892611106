import React from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import BailiwiksList from './bailiwiks-list';
import Header from './header';
import LoadingSpinner from './UI/loading-spinner';
import { useStores } from '../lib/store';

const Bailiwiks = (props) => {
    const { bailiwikStore } = useStores();
    const { current: currentBailiwik } = bailiwikStore;

    if (!currentBailiwik.bailiff) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Helmet>
                <title>Bailiwiks</title>
            </Helmet>
            <div className="">
                <Header
                    bailiwik={currentBailiwik}
                    bailiwiks={props.bailiwiks}
                    switchBailiwik={props.switchBailiwik}
                    currentUser={props.currentUser}
                />
                <div className="row justify-content-center align-items-center bio pt-4">
                    <div className="col-11 justify-content-center text-center">
                        {currentBailiwik.description}
                    </div>
                    <div className="mt-3 col-12 d-flex justify-evenly align-items-center">
                        <span>{currentBailiwik.memberCount} Members</span>
                        <span>{currentBailiwik.bailiff.fullName} • 👑</span>
                    </div>
                    <div className="mt-4 col-11 bailiwiks pb-2 px-2">
                        YOUR BAILIWIKS
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-6 members-container">
                <div className="col-11 p-0 bailiwik-list scrollable">
                    <BailiwiksList
                        bailiwiks={props.bailiwiks}
                        switchBailiwik={props.switchBailiwik}
                    />
                </div>
            </div>
        </>
    );
};

export default observer(Bailiwiks);
