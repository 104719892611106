import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

import { getImageUrl } from '../../../lib/utils';
import iconPerson from '../../../assets/person.png';
import moment from 'moment';

function Avatar({ img }) {
    if (!img) {
        return (
            <img
                className="tw-h-full tw-w-full tw-object-cover"
                src={iconPerson}
            />
        );
    }
    return (
        <img
            className="tw-h-full tw-w-full tw-object-cover"
            src={getImageUrl(img)}
        />
    );
}
Avatar.propTypes = {
    img: PropTypes.string,
};

export default function Member({ data, isBailiff = false, isHost = false }) {
    const history = useHistory();
    const { bailiwikId } = useParams();
    const { path } = useRouteMatch();

    return (
        <>
            <div
                className="tw-flex tw-pt-3.5 tw-pb-3 tw-border-b tw-border-white tw-border-opacity-20 tw-px-2"
                onClick={() => {
                    history.push(
                        `${path.replace('/:bailiwikId', '')}/${bailiwikId}/${
                            data._id
                        }`
                    );
                }}
                data-test-id="member"
                data-test-member={`${data._id}`}
            >
                <div className="tw-flex-grow-0 tw-flex-shrink-0 tw-w-17 tw-h-17 tw-rounded-full tw-overflow-hidden">
                    <Avatar img={data.avatar} />
                </div>
                <div className="tw-ml-2.5 tw-leading-5 tw-w-full tw-pt-1 tw-line-clamp-1">
                    <div className="tw-text-gray-text-2">
                        {data?.displayName ?? data.fullName}
                        {(isBailiff || isHost) && (
                            <>
                                {' '}
                                •
                                {isBailiff && (
                                    <span className="tw-pl-3">👑</span>
                                )}
                                {isHost && (
                                    <span
                                        className="tw-pl-3"
                                        style={{ filter: 'grayscale(1)' }}
                                    >
                                        👑
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                    {data.occupation !== '' && (
                        <div className="tw-text-gray-text-3 tw-text-xm tw-line-clamp-1">
                            {data.occupation}
                        </div>
                    )}
                    {data.bio !== '' && (
                        <div className="tw-text-gray-text-3 tw-text-xm tw-line-clamp-1">
                            {data.bio}
                        </div>
                    )}
                    {data.updatedAt !== '' && (
                        <div className="tw-text-gray-text-3 tw-text-xm tw-line-clamp-1">
                            Last seen {moment(data.updatedAt).fromNow()}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

Member.propTypes = {
    data: PropTypes.object.isRequired,
    isBailiff: PropTypes.bool,
    isHost: PropTypes.bool,
};
