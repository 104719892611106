import React from 'react';
import PropTypes from 'prop-types';

/**
 * LikeIcon component display in chat messages
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function LikeIcon({ fillColor, bgColor, left, count = 0, iLiked = false }) {
    const direction = left ? 'tw--left-2.5' : 'tw--right-2.5';
    return (
        <div className={`tw-absolute tw--bottom-2.5 ${direction}`}>
            <div className="tw-relative icon-like">
                <div
                    className={`${bgColor} tw-rounded-full tw-shadow tw-pl-2 tw-pr-2 tw-pt-1 tw-pb-1 ${iLiked ? 'i-liked': ''}`}
                >
                    <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.82605 16.2998H3.87794V7.08886H0.82605V16.2998ZM17.6115 7.85643C17.6115 7.0121 16.9248 6.32128 16.0855 6.32128H11.2711L11.996 2.81345L12.0189 2.56783C12.0189 2.25312 11.8892 1.96144 11.6831 1.7542L10.8744 0.948242L5.85403 6.00657C5.57173 6.2829 5.40388 6.66669 5.40388 7.08885V14.7646C5.40388 15.609 6.09055 16.2998 6.92982 16.2998H13.7966C14.4299 16.2998 14.9716 15.916 15.2005 15.3633L17.5046 9.95191C17.5733 9.77537 17.6115 9.59115 17.6115 9.39158V7.92551L17.6038 7.91784L17.6115 7.85643Z"
                            fill={fillColor}
                        />
                    </svg>
                </div>
                {count > 1 && (
                    <div className="tw-absolute tw--right-1 tw--bottom-1 tw-rounded-full tw-overflow-hidden tw-border tw-border-white tw-bg-orange-chat tw-w-4 tw-h-4 tw-text-center tw-text-xxs likes-count">
                        {count}
                    </div>
                )}
            </div>
        </div>
    );
}

LikeIcon.defaultProps = {
    left: false,
    count: 0,
};

LikeIcon.propTypes = {
    fillColor: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    left: PropTypes.bool,
    count: PropTypes.number,
    iLiked: PropTypes.bool
};
