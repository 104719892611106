import { useFirebase } from '../contexts/FirebaseContext';
import chatUseCase from '../../core/chat';
// #635 requires we send memberId in order to update unseenCount for dm message
const useSendMessage = (path, user, bailiwik = {}, to = false) => {
    const { firebase } = useFirebase();

    const sendMessage = (type, content, options = {}) => {
        options.bailiwik = {
          _id: bailiwik._id
        };
        if (to) {
          options.to = to;
        }
        const chatRef = firebase.database().ref(path);

        return chatUseCase.sendMessage(chatRef, type, content, user, options);
    };

    return sendMessage;
};

export default useSendMessage;
