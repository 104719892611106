import { makeAutoObservable } from 'mobx';

// CANDIDATE FOR DELETION
export default class NotificationStore {
    rootStore;
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
}
