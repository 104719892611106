const chatService = () => {
    const getMessages = (chatRef, lastItem, limit = 10) => {
        return new Promise((resolve) => {
            let ref = !lastItem
                ? chatRef.orderByChild('createdAt')
                : chatRef
                      .orderByChild('createdAt')
                      .startAfter(-1 * lastItem.createdAt);

            // for some reason the limit was 10 messages
            if (limit !== null && limit !== undefined) {
                ref = ref.limitToFirst(limit);
            }

            ref.once('value', (snapshot) => {
                const messages = [];
                snapshot.forEach((child) => {
                    messages.push(child);
                });
                resolve(messages);
            });
        });
    };

    const sendMessage = (chatRef, data) => {
        const newMessageRef = chatRef.push();
        newMessageRef.set(data);
    };

    const onChildAdded = (chatRef, callback) =>
        chatRef.endAt().limitToLast(1).on('child_added', callback);

    const onChildChanged = (chatRef, callback) =>
        chatRef.on('child_changed', callback);

    const onChildRemoved = (chatRef, callback) =>
        chatRef.on('child_removed', callback);

    return {
        getMessages,
        sendMessage,
        onChildAdded,
        onChildChanged,
        onChildRemoved,
    };
};

export default chatService;
