import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

import Modal from './Modal';
import { getCroppedImg } from '../../core/utility/imageTools';
import { observer } from 'mobx-react';

function AvatarCropper({ source, onClose, onCrop }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedPixels, setCroppedPixels] = useState();
    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedPixels(croppedAreaPixels);
    }, []);

    const closeHandler = (e) => {
        e.preventDefault();
        onClose(e);
    };

    return (
        <Modal closeHandler={closeHandler}>
            <div className="tw-p-8 tw-relative">
                <div className="tw-min-w-full tw-h-96 tw-relative">
                    <Cropper
                        image={source}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        cropShape="round"
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
            </div>
            <div className="tw-space-x-4 tw-text-center">
                <button
                    role="button"
                    className="tw-bg-orange-chat tw-py-2 tw-px-10"
                    onClick={async (e) => {
                        e.preventDefault();
                        const image = await getCroppedImg(
                            source,
                            croppedPixels,
                            400,
                            400
                        );
                        onCrop(image);
                    }}
                >
                    OK
                </button>
                <button
                    role="button"
                    className="tw-text-black-chat"
                    onClick={closeHandler}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
}

AvatarCropper.propTypes = {
    source: PropTypes.string.isRequired,
    onCrop: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default observer(AvatarCropper);
