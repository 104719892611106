import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconSVG from '../../common/iconSVG';
import styles from './assets/styles.module.css';

export default function SearchInput({ onChange, keyword }) {
    const [value, setValue] = useState(keyword);
    const onChangeHandler = (e) => {
        e.preventDefault();
        setValue(e.target.value);
        onChange(e.target.value);
    };
    const submitHandler = (e) => {
        e.preventDefault();
    };
    return (
        <form className="tw-w-full tw-py-5" action="" onSubmit={submitHandler}>
            <div className="tw-rounded-full tw-h-full tw-flex tw-justify-center tw-content-center tw-bg-blue-search tw-pl-3 tw-pr-4 tw-py-1">
                <div className="tw-bg-opacity-50 tw-mt-1 tw-h-full tw-py-2">
                    <IconSVG
                        icon="search"
                        color="#939db6"
                        width="22"
                        height="22"
                    />
                </div>
                <input
                    type="search"
                    className={`${styles.searchInput} tw-flex-grow tw-px-2 tw-bg-transparent tw-placeholder`}
                    value={value}
                    placeholder="Search by member"
                    onChange={onChangeHandler}
                    data-test-id="input-member-search"
                />
            </div>
        </form>
    );
}

SearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    keyword: PropTypes.string.isRequired,
}
