import { useFirebase } from '../../core/contexts/FirebaseContext';
import { useStores } from '../../lib/store';
import { mapEventGuestUsers } from '../../utils';
import { RSVP_STATUS } from '../../constants';
import { useLogToAmplitude } from '../../core/utility/amplitude';

export default function useEventCtaClick() {
    const { firebase } = useFirebase();
    const {
        userStore: { currentUser },
        bailiwikStore: { membersDetails, current },
    } = useStores();

    const logToAmplitude = useLogToAmplitude();

    const eventCtaClick = async (item, status, callback) => {
        const userId = currentUser._id;

        const newContent = {
            ...item.content,
            event: {
                ...item.content.event,
                guests: item.content.event.guests || [],
            },
        };

        const currentUserIndex = newContent.event?.guests?.findIndex(
            (u) => u.user?._id === userId
        );
        if (currentUserIndex > -1) {
            newContent.event.guests[currentUserIndex].rsvpStatus =
                RSVP_STATUS[status];
        } else {
            const newUser = {
                _id: userId,
                rsvpStatus: RSVP_STATUS[status],
            };
            newContent.event.guests = [...newContent.event.guests, newUser];
        }
        newContent.event.guests = newContent.event.guests.map((guest) => {
            return {
                _id: guest._id || guest.user._id,
                rsvpStatus: guest.rsvpStatus,
            };
        });

        const eventId = item._id;
        const bailiwikId = current._id;
        const bailiwikName = current.name;
        await firebase
            .database()
            .ref(`/bailiwik/${bailiwikId}/messages/${eventId}`)
            .update({ content: newContent });
        if (callback) {
            callback(
                mapEventGuestUsers([{ content: newContent }], membersDetails)[0]
            );
        }

        const p = {
            BailiwikID: bailiwikId,
            BailiwikName: bailiwikName,
            eventId: eventId,
            eventName: item.title,
            screen: 'EventsScreen',
        };
        logToAmplitude(userId, bailiwikId, bailiwikName, 'RSVP Event', p);
    };

    return eventCtaClick;
}
