import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import { useStores } from '../../../lib/store';
import { getImageUrl } from '../../../lib/utils';
import { mapMentionInputSuggestions } from '../../../utils';
import BailiwikStore from '../../../stores/BailiwikStore';

import mentionSuggestionStyle from './mentionSuggestionStyle';
import './TextInput.css';

const ALL_MENTION_ITEM = {
    id: 'a11111111111111111111111',
    name: 'All members',
    display: 'All members',
    fullName: 'All members',
    avatar: null,
};

const isHost = (user, currentBailiwik) => {
    if (currentBailiwik.hosts && currentBailiwik.hosts.length && currentBailiwik.hosts.indexOf(user._id) > -1) {
        return true;
    }

    if (user._id === currentBailiwik.bailiff._id) {
        return true;
    }

    if (user.isSuperAdmin) {
        return true;
    }

    return false;
};

const renderSuggestion = ({ fullName, name, avatar }) => {
    return (
        <div className="suggestion-item">
            <img
                src={getImageUrl(avatar) || '/images/icons/person.png'}
                alt="user-avatar"
            />
            <div>
                <span>{fullName}</span>
                <span>{name}</span>
            </div>
        </div>
    );
};

const renderCustomSuggestionsContainer = (children) => {
    return <div className="suggestions-container">{children}</div>;
};

const displayTransform = (id, display) => {
    return `@${display}`;
};

function TextInput({
    style = {},
    text,
    className,
    placeholder = '',
    onEnter,
    onChange,
}) {
    const {
        bailiwikStore: { membersDetails, current: currentBailiwik },
        userStore: { currentUser },
    } = useStores();
    const suggestions = mapMentionInputSuggestions(membersDetails);
    const contentRef = useRef(null);

    const handleChange = (e) => {
        const text = e.target.value;
        onChange(text);
    };

    const handleOnKeyDown = (e) => {
        if (e.keyCode == 13 && !e.shiftKey && onEnter) {
            onEnter(e);
            return false;
        }
        return true;
    };

    return (
        <>
            <MentionsInput
                style={{ ...mentionSuggestionStyle.mentionInput, ...style }}
                inputRef={contentRef}
                value={text}
                className={`text-input tw-w-full tw-p-2 tw-pl-0 tw-pr-0 tw-text-black tw-placeholder-black tw-min-h-11 tw-overflow-y-auto tw-flex-wrap ${className}`}
                data-test-id="dm-input-text"
                allowSuggestionsAboveCursor={true}
                allowSpaceInQuery={true}
                customSuggestionsContainer={renderCustomSuggestionsContainer}
                onKeyDown={handleOnKeyDown}
                onChange={handleChange}
                placeholder={placeholder}
                singleLine={false}
            >
                <Mention
                    markup="@[__display__](__id__)"
                    displayTransform={displayTransform}
                    trigger="@"
                    data={
                        isHost(currentUser, currentBailiwik)
                            ? [ALL_MENTION_ITEM, ...suggestions]
                            : suggestions
                    }
                    renderSuggestion={renderSuggestion}
                    style={mentionSuggestionStyle.mentionItem}
                />
            </MentionsInput>
        </>
    );
}

TextInput.defaultProps = {
    isLocked: false,
    text: '',
};

TextInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    text: PropTypes.string,
    isLocked: PropTypes.bool,
};

export default TextInput;
