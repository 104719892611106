import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import AvatarCropper from '../../common/AvatarCropper';

const PlaceholderIcon = () => (
    <div className="tw-relative tw-bg-orange-chat tw-h-full tw-w-full">
        <div className="tw-absolute tw-top-8 tw-left-8">
            <svg
                width="94"
                height="87"
                viewBox="0 0 94 87"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="94"
                    height="87"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.4897 13.0039V0.765625H20.6164V13.0039H32.8063V21.1628H20.6164V33.4011H12.4897V21.1628H0.299805V13.0039H12.4897ZM24.6785 37.4839V25.2456H36.8684V13.0073H65.3116L72.7475 21.1662H85.6282C90.0978 21.1662 93.7548 24.8377 93.7548 29.3251V78.2783C93.7548 82.7657 90.0978 86.4372 85.6282 86.4372H20.6151C16.1455 86.4372 12.4885 82.7657 12.4885 78.2783V37.4839H24.6785ZM53.1217 74.1973C64.3365 74.1973 73.4383 65.0594 73.4383 53.8001C73.4383 42.5409 64.3365 33.4029 53.1217 33.4029C41.907 33.4029 32.8052 42.5409 32.8052 53.8001C32.8052 65.0594 41.907 74.1973 53.1217 74.1973ZM40.119 53.7959C40.119 61.0165 45.9295 66.8501 53.1216 66.8501C60.3137 66.8501 66.1242 61.0165 66.1242 53.7959C66.1242 46.5753 60.3137 40.7417 53.1216 40.7417C45.9295 40.7417 40.119 46.5753 40.119 53.7959Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0)">
                    <rect
                        x="-52.5225"
                        y="-56.3516"
                        width="203.166"
                        height="203.972"
                        fill="white"
                    />
                    <mask
                        id="mask1"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="-53"
                        y="-57"
                        width="204"
                        height="205"
                    >
                        <rect
                            x="-52.5225"
                            y="-56.3516"
                            width="203.166"
                            height="203.972"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask1)"></g>
                </g>
            </svg>
        </div>
    </div>
);

function StepB({ setAvatar, uploadAvatar, skipUpload }) {
    const refFileInput = useRef(null);
    const [cropper, setCropper] = useState(false);
    const [preview, setPreview] = useState('');

    const onUpload = async (e) => {
        try {
            setPreview(URL.createObjectURL(e.target.files[0]));
            setCropper(true);
        } catch (err) {
            console.error(err);
            alert('Something went wrong. Please try again.');
        }
    };

    return (
        <div className="tw-text-center">
            <Helmet>
                <title>Sign Up - Set Profile Photo</title>
            </Helmet>
            <h1 className="tw-text-xl tw-pt-8 tw-text-center tw-tracking-wider">
                Add a Profile Photo
            </h1>
            <div className="tw-mt-4 tw-tracking-wider">
                Faces make your Bailiwik friendlier
            </div>

            {cropper && (
                <AvatarCropper
                    source={preview}
                    onClose={() => setCropper(false)}
                    onCrop={(image) => {
                        setCropper(false);
                        setPreview(URL.createObjectURL(image));
                        setAvatar(image);
                    }}
                />
            )}

            <input
                ref={refFileInput}
                type="file"
                className="tw-hidden"
                accept="image/*"
                onChange={onUpload}
            />
            <div
                data-test-id="photo-upload-clickable"
                className="tw-w-40 tw-h-40 tw-mx-auto tw-mt-28 tw-rounded-full tw-overflow-hidden"
                onClick={() => {
                    refFileInput.current && refFileInput.current.click();
                }}
            >
                {preview && (
                    <img
                        className="tw-object-cover tw-w-full tw-h-full"
                        src={preview}
                    />
                )}
                {!preview && <PlaceholderIcon />}
            </div>
            <button
                data-test-id="btn-set-photo"
                className="tw-mt-32 tw-bg-orange-chat tw-w-4/5 tw-block tw-py-4 tw-mx-auto tw-rounded-md tw-tracking-wider tw-text-lg"
                onClick={uploadAvatar}
            >
                SET PROFILE PHOTO
            </button>
            <button
                data-test-id="btn-skip"
                className="tw-block tw-mx-auto tw-mt-8"
                onClick={skipUpload}
            >
                SKIP FOR NOW
            </button>
        </div>
    );
}

export default React.memo(StepB);
