import React, { useState } from 'react';
import FirebaseMessages from '../chat/FirebaseChat';
import useMessages from '../../core/hooks/useMessages';

export default function Messages({
    bailiwikId,
    sendLike,
    eventCtaClick,
    redeemDeal,
    scrollableRef,
    toggleCommentModal,
    isGuest,
    currentUser,
    onEventDealEdit,
    onEventDealDelete,
    onEventDealRepost,
}) {
    const [page, setPage] = useState(0);
    const messagesResponse = useMessages(`bailiwik/${bailiwikId}/deals`, page);

    return (
        <FirebaseMessages
            setPage={setPage}
            messages={messagesResponse}
            isGuest={isGuest}
            type="event"
            like={(message) => sendLike(message, { currentUser })}
            eventCtaClick={eventCtaClick}
            redeemDeal={redeemDeal}
            ref={scrollableRef}
            toggleCommentModal={toggleCommentModal}
            currentUser={currentUser}
            onEventDealEdit={onEventDealEdit}
            onEventDealRepost={onEventDealRepost}
            onEventDealDelete={onEventDealDelete}
        />
    );
}
