export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.crossOrigin = "anonymous";
        image.src = url
    })

export const blobToImage = (blob) => {
    return new Promise(resolve => {
      const url = URL.createObjectURL(blob)
      let img = new Image()
      img.onload = () => {
        URL.revokeObjectURL(url)
        resolve(img)
      }
      img.src = url
    })
  }

export const getCroppedImg = async (imageSrc, pixels, width = 1000, height = 1000) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    let crop = pixels || {}

    if(!pixels) {
        crop.x = 0
        crop.y = 0

        const  size = image.height > image.width ? image.width : image.height

        crop.width = size
        crop.height = size
    }

    canvas.width = width
    canvas.height = height

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob)
        }, 'image/jpeg', 0.85)
    })
}

export const loadImage = (image) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.addEventListener('load', () => {
            return resolve(reader.result)
        }, false)

        reader.addEventListener('error', (e) => {
            console.log('loadImageError', e)
            return reject(reader.result)
        }, false)

        reader.readAsDataURL(image)
    })

}