import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

/**
 * Header component for the DM or other modules
 *
 * @param {object} props
 *
 * @returns React.ReactElement
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function Header({
    title = '',
    backButton = false,
    RightButton = null,
    LeftButton = null,
}) {
    const history = useHistory();
    const goBack = () => history.goBack();

    return (
        <div className="tw-relative tw-pt-2.5 tw-pb-3 tw-bg-blue-header tw-h-12 tw-flex tw-flex-col tw-place-content-center">
            {backButton === true && (
                <div
                    className="tw-absolute tw-top-4 tw-left-4"
                    onClick={goBack}
                >
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.83 7L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7H3.83Z"
                            fill="white"
                        />
                    </svg>
                </div>
            )}
            {LeftButton && (
                <div className="tw-absolute tw-top-3 tw-left-4">
                    {LeftButton}
                </div>
            )}
            <h1 className="tw-text-center">{title}</h1>
            <div className="tw-absolute tw-top-3 tw-right-4">{RightButton}</div>
        </div>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    backButton: PropTypes.bool,
    RightButton: PropTypes.node,
    LeftButton: PropTypes.node,
};

export default React.memo(Header);
