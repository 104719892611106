import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { validate } from 'validate.js';

import Errors from '../../common/Errors';
import { resetPassword } from '../../../services/databaseService';
import '../assets/styles.module.css';
import LoadingSpinnerWithModal from '../../common/LoadingSpinnerWithModal';

const validationConstraints = {
    email: {
        email: true,
    },
};

/**
 * Request passcode page component
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function RequestPasscode() {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false);

    const history = useHistory();
    const { url } = useRouteMatch();

    const validateInputs = () => {
        const errors = validate(
            {
                email,
            },
            validationConstraints
        );
        if (errors) {
            setErrors(errors);
            return false;
        }
        return true;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (processing) {
            return false;
        }

        // validate email
        if (!validateInputs()) {
            return false;
        }
        setProcessing(true);
        // fire api request
        resetPassword({ email: email }).then((res) => {
            if (res && res.success) {
                window.alert(
                    `An email has been sent to ${email} with your temporary password.`
                );
            }
            setProcessing(false);
            history.push(`${url}/verify-passcode?email=${email}`);
        });

        return false;
    };

    return (
        <form action="" onSubmit={submitHandler} className="tw-pt-12">
            <h1 className="tw-text-xl tw-text-center">
                Send Temporary Password
            </h1>
            <p className="tw-text-center tw-text-sm tw-mt-4 tw-mx-3 tw-tracking-wider">
                Enter your email associated to the account. We&apos;ll send you
                a temporary passcode to reset your account.
            </p>
            <label className="tw-mt-8 tw-w-full">
                EMAIL
                <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setErrors({
                            ...errors,
                            email: [],
                        });
                    }}
                    placeholder="Enter your email"
                    data-test-id="input-email"
                    className="tw-w-full tw-bg-transparent tw-border-b tw-border-gray-500 tw-py-2 tw-mt-4"
                />
                {errors.email !== undefined && (
                    <Errors attr="email" errors={errors.email} />
                )}
            </label>
            <button
                type="submit"
                data-test-id="btn-submit"
                className="tw-bg-orange-chat tw-py-4 tw-text-center tw-bottom-4 tw-left-0 tw-right-0 tw-rounded tw-w-full tw-mt-20"
                disabled={processing}
                onClick={submitHandler}
            >
                SEND TEMPORARY PASSCODE
            </button>
            {processing && <LoadingSpinnerWithModal />}
        </form>
    );
}
