export default {
    mentionInput: {
      input: {
        overflow: 'auto',
        lineHeight: '2em'
      },

      control: {
        backgroundColor: '#fff',
        fontSize: 14,
        fontWeight: 'normal',
        position: 'relative',
      },
    
      '&multiLine': {
        control: {
          paddingHorizontal: 5,
        },
        highlighter: {
          overflow: 'hidden',
          border: 'none',
        },
      },
    
      suggestions: {
        list: {
          backgroundColor: 'white',
          border: '1px solid rgba(0,0,0,0.15)',
          fontSize: 14,
        },
        item: {
          padding: '5px 15px',
        },
      },
    },
    mentionItem: {
      backgroundColor: 'rgba(59, 191, 227, 0.4)',
      color: 'white'
    }
}