import React, { useEffect, useState } from 'react'
import Message from "../message";
import { mapEventGuestUsers } from '../../../utils'
import { useStores } from '../../../lib/store'

const ParentPost = (props) => {
    const { bailiwikStore: { membersDetails } } = useStores();
    const [message, setMessage] = useState()

    const initListener =() => {

        props.message.ref.on('value', (current) => {
            const cloneMessage = { ...current.val() }
            // deleting case
            if(current.val() === null) return 
            
            cloneMessage.likes.users = Object.values(
                cloneMessage.likes.users 
                    ? cloneMessage.likes.users 
                    : {}
            )
            cloneMessage.createdAt = (new Date(-1 * cloneMessage.createdAt)).toISOString().toString()
            setMessage(mapEventGuestUsers([cloneMessage], membersDetails)[0])
        
        });
    
    }

    useEffect(() => {
        initListener()

        return () => props.message.ref.off()
    }, [props.message])



    return message && message.createdAt ? <Message {...props} message={message}/> : <></>
}

export default ParentPost