import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { useFirebase, firebaseImageFetchUrl  } from '../../core/contexts/FirebaseContext';
import GooglePlaceSearch from '../GooglePlaceSearch';
import Modal from '../chat/StandardModal';
import Toast from '../toast';
import Image from '../chat/Image';
import DateRangePicker from '../DateRangePicker';
import useSendMessage from '../../core/hooks/useSendMessage';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from '../UI/loading-spinner';

import * as FilePond from 'filepond';
import { openEditor, processImage, createDefaultImageReader, createDefaultImageWriter, getEditorDefaults } from '@pqina/pintura';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster
);

import 'filepond/dist/filepond.min.css';
import '@pqina/pintura/pintura.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

const initialValues = {
    startTime: moment().toDate(),
    endTime: moment().add(1, 'days').toDate(),
    title: '',
    description: '',
    image: '',
    location: null,
    mode: null
};

const sanitizeInitialValue = (data) => {
  if (!data?.content?.deal) {
    initialValues.mode = 'create';
        return initialValues;
    }

    return {
        ...data.content.deal,
        startTime: moment(data.content.deal.startTime).toDate(),
        endTime: moment(data.content.deal.endTime).toDate(),
        location: {
            label: data.content.deal.locationName,
      },
      mode:'edit'
    };
};

export default function DealModal({
    user,
    bailiwik,
    data: initialData,
    onClose,
}) {
  const toastRef = useRef();
  const { firebase } = useFirebase();
  const [data, setData] = useState({});

  useEffect(() => {
    setData(sanitizeInitialValue(initialData));
  }, []);


  const [errors, setErrors] = useState({});
  
  const sendMessage = useSendMessage(
      `bailiwik/${bailiwik._id}/messages`,
      user,
      bailiwik
  );
  
  const [imageLoading, setImageLoading] = useState(false);
  const [editFilesLoading, setEditFilesLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  let imagePathName;
  const [editedImage, setEditedImage] = useState([]);
  const [createFilePond, setCreateFilePond] = useState(false);
  let lockout;
  const refDateRange = useRef({});
    // Reposted data is a new post with content taken from an existing post.
    // It does not have a message _id. #451. See similar code for Event.
  const isReposting = !!initialData['content'] && !!!initialData['_id'];
  const isCreating = initialData['_id'] === undefined && !isReposting;
  const isEditing = !isCreating && !isReposting;

    const validate = () => {
        const newErrors = {};
        if (!data.title) {
            newErrors.title = true;
        }
        if (!data?.location?.label) {
            newErrors.location = true;
        }
        if (!data?.description) {
            newErrors.description = true;
        }
        setErrors(newErrors);
        return Object.values(newErrors).length === 0;
    };

    const saveImageToFirebase = async (e) => {
        setImageLoading(true);
        const file = editedImage[0];
        const type = file.type.split('/')[1];
        const storage = firebase.storage();
      const imageName = `images/${uuid()}.${type}`;
      imagePathName = imageName;
        const storageRef = storage.ref(imageName);
        try {
            await storageRef.put(file);
            onDataChange('image', imageName);
        } catch (error) {
            if (error.code === 'storage/unauthorized') {
                toastRef.current.notify('The size limit for images is 5.0 MB');
            }
        } finally {
            setImageLoading(false);
        }
    };

    const onDataChange = useCallback(
        (key, value) => {
            setData((oldData) => {
              const newData = { ...oldData };
              newData[key] = value;
              return newData;
            });
        },
        [data]
    );

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }

        const input = {
            deal: {
                title: data.title,
                description: data.description,
                locationName: data.location?.label,
                locationAddress: data.location?.label,
                allDay: data.allDay || false,
                startTime: moment(refDateRange.current.startTime).toString(),
                endTime: moment(refDateRange.current.endTime).toString(),
                bailiwik: bailiwik._id,
                creator: {
                    _id: user.currentUser._id,
                    ...(user.currentUser.avatar && {
                        avatar: user.currentUser.avatar,
                    }),
                    ...(user.currentUser.birthday && {
                        birthday: user.currentUser.birthday,
                    }),
                    ...(user.currentUser.displayName && {
                        displayName: user.currentUser.displayName,
                    }),
                    email: user.currentUser.email,
                    ...(user.currentUser.fullName && {
                        fullName: user.currentUser.fullName,
                    }),
                },
            },
        };

      if (editedImage.length > 0) {
        //this automatically uses editedImage[0] from FilePond
        await saveImageToFirebase();
        input.deal.image = imagePathName;
      }
      
        if (isCreating || isReposting) {
            sendMessage('DEAL', input);
        } else if (!initialData?._id) {
            // Should not update a firebase ref if _id does not exist.
          alert('Sorry, there was a problem posting your deal!');
          
        } else {
            firebase
                .database()
                .ref(`/bailiwik/${bailiwik._id}/messages/${initialData._id}`)
                .update({
                    content: {
                        ...initialData.content,
                        ...input,
                    },
                });
        }
        onClose(isReposting);
    };

  
    useEffect(() => {
        if (initialData?.content?.deal) {
          //setData(sanitizeInitialValue(initialData));
        }
      //just using this to clear before close modal
        return () => {
            setData({});
            setErrors({});
        };
    }, [initialData]);

    useEffect(() => {
      if (createFilePond === true) { return; }
        console.log('Deal Modal fetch useEffect data.mode:', data);
        if ( data?.mode == 'create' ) {
          console.log('Deal Modal fetch useEffect data.mode CREATE setCreateFilePond TRUE');
          setCreateFilePond(true);
        }
      if (data?.mode == 'edit') {
        setLoading(true);
          console.log('Deal Modal fetch useEffect data.mode EDIT');
          if (data?.image) {
            console.log('Deal Modal fetch useEffect data.mode EDIT load image');
            const imageName = data.image;
            const cleanImageName = imageName.replace('images/', '');

            console.log('Deal Modal fetch firebase image imageName:', imageName);

            const params = new URLSearchParams({
              fileKey: imageName,
              type: 'firebase'
            });
            console.log('Event Modal fetch firebase image params:', params.toString());
            console.log('Event Modal fetch firebase image fetchUrl:', firebaseImageFetchUrl);

            fetch(`${firebaseImageFetchUrl}?${params.toString()}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            })
              .then(response => {
                console.log('Deal Modal fetch firebase image response:', response);
                return response.blob();
              })
              .then(blob => {
                console.log('Deal Modal fetch firebase image blob:', blob);
                const file = new File([blob], cleanImageName, { type: blob.type });
                console.log('Deal Modal fetch getDownloadURL file:', file);
                setLoading(false);
                setImage([file]);
                setCreateFilePond(true);
              })
              .catch((error) => {
                console.log('Deal Modal fetch getDownloadURL error:', error);
                console.log('Deal Modal fetch due to error, treating this as not having an image setCreateFilePond TRUE');
                setLoading(false);
                setImage([]);
                setCreateFilePond(true);
              });
            

            
          } else {
            console.log('index useEffect data.mode EDIT no image setCreateFilePond TRUE');
            setCreateFilePond(true);
          }
        }
      }, [data?.mode]);
  
  
  
  
  
  
    
      useEffect(() => {
        if (!createFilePond) {
          console.log('index useEffect !createFilePond');
          return;
        }
    
          lockout = true; //only create FilePond once
      
          let pond;
      
          const mql = window.matchMedia('(max-width: 600px)');
          let filePosterMaxHeight = mql.matches ? 130 : 300;
          const screenTest = e => {
            filePosterMaxHeight = e.matches ? 130 : 300;
            if (pond) {
              console.log('screenTest updating filePosterMaxHeight:', filePosterMaxHeight);
              pond.setOptions({
                filePosterMaxHeight
              });
            }
          };
          mql.addEventListener(screenTest);
          
          console.log('index FilePond.create');
          setLoading(false);
    
          pond = FilePond.create(document.querySelector('.uploader-content'), {
          /* FilePond core properties */
          filePosterMaxHeight,
          files: image,
          allowMultiple: true,
          allowReorder: true,
          maxFiles: 1,
          checkValidity: true,
          /* drag and drop */
            dropValidation: true,
            stylePanelAspectRatio: 1,//3:4 ratio
      
          onupdatefiles: (fileItems) => {
            console.log('FilePond onupdatefiles fileItems:', fileItems );
      
            setImage( fileItems );
            },
          onremovefile: (error, file) => {
            console.log('FilePond onremovefile error:', error);
            console.log('FilePond onremovefile file:', file);
            
            setEditedImage(prevImages => {
              // Filter the array to exclude the file that was removed
              const updatedImages = prevImages.filter(image => image.id !== file.id);
              return updatedImages;
            });
      
          },
            onpreparefile(fileItem, output) {
            console.log('FilePond onpreparefile fileItem:', fileItem );
            const file = fileItem.file; // get the actual File object
           //console.log('FilePond onpreparefile starting file:');
           //console.log(file);
          
           //console.log('FilePond onpreparefile before setEditedImage with output:');
           //console.log(output);
            
            const updatedImage = new File([output], file.name, { type: file.type });
            
            // Add the FilePond id as a property on the updatedImage
            updatedImage.id = fileItem.id;
            
           //console.log('FilePond onpreparefile updatedImage:');
           //console.log(updatedImage);
          
            setEditedImage((prevImages) => {
              const imageExists = prevImages.some((image) => image.id === fileItem.id);
              if (imageExists) {
                const updatedImages = prevImages.map((image) => {
                  if (image.id === fileItem.id) {
                    return updatedImage;
                  }
                  return image;
                });
                return updatedImages;
              } else {
                return [...prevImages, updatedImage];
              }
            });
          },
          /* Image Editor plugin properties */
          imageEditor: {
            // Maps legacy data objects to new imageState objects (optional)
            // not sure if this even exists in the filepond anymore
            // legacyDataToImageState: legacyDataToImageState,
      
            createEditor: openEditor,
            imageReader: [createDefaultImageReader],
            imageWriter: [
              createDefaultImageWriter,
              {
                targetSize: {
                  width: 1000,
                  height: 1000,
                  fit: 'cover',
                },
              },
            ],
            imageProcessor: processImage,
            editorOptions: {
              ...getEditorDefaults(),
              imageCropAspectRatio: 1// just square for now
            },
          },
        });
      
        // Cleanup the FilePond instance when the component unmounts
            return () => {
              console.log('index FilePond.destroy');
              mql.removeEventListener(screenTest);
              pond.destroy();
            };
      }, [editFilesLoading, createFilePond]);
  
  
  
  
  
  
  
  
  
  
    return (
        <Modal
            onClose={onClose}
            title={`${
                isCreating ? 'Create' : isReposting ? 'Repost' : 'Edit'
            } Deal`}
        >
            <Toast ref={toastRef} />
            <form onSubmit={onSubmit}>
          <div className="create-deal-body">
          {loading && <div className="uploader-image-loading">
                <Loading />   
            </div>}
                <input
                  id="image"
                  className="uploader-content"
                />
                    <input
                        className="create-deal-input"
                        placeholder="Add title"
                        value={data.title}
                        data-error={errors.title}
                        onChange={(e) => onDataChange('title', e.target.value)}
                    />
                    <textarea
                        className="create-deal-input textarea"
                        placeholder="Add Description"
                        data-error={errors.description}
                        value={data.description}
                        onChange={(e) =>
                            onDataChange('description', e.target.value)
                        }
                    />
                    <div
                        className="google-place-search-error-handler"
                        data-error={errors.location}
                    >
                        <GooglePlaceSearch
                            value={data.location}
                            onChange={(e) => onDataChange('location', e)}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-space-between">
                        <label htmlFor="allDay" className="create-edit-label">
                            All Day
                        </label>
                        <input
                            id="allDay"
                            type="checkbox"
                            checked={data.allDay}
                            onChange={() =>
                                onDataChange('allDay', !data.allDay)
                            }
                        />
                        <DateRangePicker
                            defaultStartDate={data.startTime}
                            defaultEndDate={data.endTime}
                            calendarClassName="create-edit-date-picker-calendar"
                            className="create-edit-date-picker"
                            refDateRange={refDateRange}
                            allDay={data.allDay}
                        />
                    </div>
                    <button className="create-deal-submit-button">
                        {isCreating
                            ? 'Create'
                            : isReposting
                            ? 'Repost'
                            : 'Update'}
                    </button>
                </div>
            </form>
        </Modal>
    );
}
