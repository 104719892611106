import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

/**
 * Edit Text message
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function EditTextMessage({
    closeHandler,
    text,
    submitHandler,
    title,
}) {
    const [value, setValue] = useState(text);

    const onSubmit = (e) => {
        e.preventDefault();
        submitHandler(value);
    };

    return (
        <Modal closeHandler={closeHandler}>
            <form
                data-test-id="frm-edit-post"
                className=""
                action=""
                onSubmit={onSubmit}
            >
                <div
                    data-test-id="edit-post-title"
                    className="tw-text-black-chat tw-text-lg tw-font-bold tw-pb-4 tw-text-center"
                >
                    {title}
                </div>
                <div>
                    <textarea
                        data-test-id="input-edit-post"
                        className="tw-w-full tw-h-36 tw-bg-gray-input tw-rounded-2xl tw-text-black-chat tw-py-2 tw-px-4"
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    ></textarea>
                </div>
                <div className="tw-text-center">
                    <button
                        data-test-id="btn-edit-comment-submit"
                        className="tw-bg-orange-chat tw-text-white tw-py-3 tw-w-3/5 tw-mt-6 tw-mb-4"
                    >
                        POST
                    </button>
                </div>
            </form>
        </Modal>
    );
}

EditTextMessage.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    submitHandler: PropTypes.func.isRequired,
};
