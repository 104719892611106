import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources
  });

/*
    resources: {
      en: {
        translation: {
          description: {
            part1: 'Edit App.js and save to reload.',
            part2: 'Learn React'
          },
          "A new kind of neighborhood":"A new kind of neighborhood",
          "Already have an account":"Already have an account?",
          "Search Location":"Search Location",
          "Search By Bailiwik Or Host Name":"Search By Bailiwik or Host Name",
          "Create a new Bailiwik":"Create a new Bailiwik"
        }
      }
    }
*/
export default i18n;