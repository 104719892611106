import React from 'react' 
import './style.css'

const Modal = (props) => {
    return (
        <div className="mm-wrapper">
            <div className="mm-container">
                <div className="mm-headline">
                    <button onClick={props.onClose}>
                        <img src="/images/icons/close.png" />
                    </button>
                </div>
                <p className="mm-title">{props.title}</p>
                <div className="mm-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal