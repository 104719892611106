import { makeAutoObservable, runInAction } from 'mobx';

import { getMembers } from '../services/databaseService';

export default class MemberStore {
    rootStore;
    bailiwikMembers; // database output
    membersState; // loading, loaded, empty, error
    currentMemberListBailiwikId; //what the MemberStore thinks the current
    bailiff = null; // processed bailiff for MembersScreen

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.reset();
    }

    reset() {
        //console.log('MemberStore reset source:',source);
        this.bailiwikMembers = [];
        this.membersState = 'empty';
        this.bailiff = null;
    }

    // get members for a bailiwik or cached members if available
    // use reload if we need to refresh the members from the same bailiwik
    async load(bailiwikId, reload = false, source) {
        const { userStore, bailiwikStore } = this.rootStore;
        if (source === undefined) {
            return false;
        }
        if (this.membersState === 'loading') {
            this.membersState = 'loaded';
            return this.bailiwikMembers;
        }
        this.membersState = 'loading';
        let lookupBailiwik = '';

        if (bailiwikId) {
            lookupBailiwik = bailiwikId;
        } else {
            lookupBailiwik = bailiwikStore.getBailiwikId;
        }
        if (
            reload === false &&
            this.currentMemberListBailiwikId &&
            lookupBailiwik &&
            this.currentMemberListBailiwikId === lookupBailiwik
        ) {
            this.membersState = 'loaded';
            return this.bailiwikMembers;
        }

        const data = await getMembers(lookupBailiwik, source).catch((err) =>
            console.log(err)
        );

        if (data && data.members) {
            runInAction(() => {
                this.bailiwikMembers = data?.members?.slice();
                this.currentMemberListBailiwikId = lookupBailiwik;
                try {
                    const BailiffDataSet = data?.members?.filter((member) => {
                        if (member._id === bailiwikStore.getBailiffId) {
                            return true;
                        }
                        return false;
                    })[0];

                    const populatedBailiff = {
                        _id: BailiffDataSet._id,
                        title:
                            (BailiffDataSet?.fullName
                                ? BailiffDataSet?.fullName
                                : BailiffDataSet._id) + ' • 👑',
                        name: BailiffDataSet?.fullName
                            ? BailiffDataSet?.fullName
                            : BailiffDataSet._id,
                        detail: BailiffDataSet.occupation
                            ? `${BailiffDataSet.displayName}\n${BailiffDataSet.occupation}`
                            : BailiffDataSet.displayName,
                        occupation: BailiffDataSet.occupation
                            ? BailiffDataSet.occupation
                            : null,
                        bio: BailiffDataSet.bio ? BailiffDataSet.bio : null,
                        birthday: BailiffDataSet.birthday
                            ? BailiffDataSet.birthday
                            : null,
                        website: BailiffDataSet.website
                            ? BailiffDataSet.website
                            : null,
                        interests: BailiffDataSet.interests
                            ? BailiffDataSet.interests
                            : null,
                        blocks: BailiffDataSet.blocks,
                        bans: BailiffDataSet.bans,
                        blocked: userStore.isBlocked(BailiffDataSet._id),
                        banned: userStore.isBanned(
                            bailiwikStore.getBailiwikId,
                            BailiffDataSet.bailiwiksBanned
                        ),
                        button: true,
                        padding: true,
                        size: 'med',
                        swipeable: BailiffDataSet._id !== userStore.getUserId,
                        swipeableType:
                            bailiwikStore.isBailiff || bailiwikStore.isHost()
                                ? 'bailiff'
                                : 'user',
                        checkbox: true,
                        arrow: false,
                        avatar: true,
                        pushToken: BailiffDataSet.pushToken
                            ? BailiffDataSet.pushToken
                            : null,
                        pushSettings: BailiffDataSet.pushSettings
                            ? BailiffDataSet.pushSettings
                            : null,
                        image: BailiffDataSet.avatar
                            ? {
                                  uri: `${process.env.ASSETS_URL}/${BailiffDataSet.avatar}`,
                              }
                            : null,
                        updatedAt: BailiffDataSet.updatedAt
                            ? BailiffDataSet.updatedAt
                            : null,
                    };

                    this.bailiff = populatedBailiff;
                } catch (error) {
                    // Sentry.captureException(error);
                }
                const BailiwikMemberList = data.members?.map((member) => {
                    const populatedMember = {
                        _id: member._id,
                        title:
                            (member?.fullName ? member?.fullName : member._id) +
                            (bailiwikStore.isHost(member._id) ||
                            bailiwikStore.current.bailiff._id === member._id
                                ? ' • 👑'
                                : ''),
                        name: member?.fullName ? member?.fullName : member._id,
                        detail: member.occupation
                            ? `${member.displayName}\n${member.occupation}`
                            : member.displayName,
                        occupation: member.occupation
                            ? member.occupation
                            : null,
                        bio: member.bio ? member.bio : null,
                        birthday: member.birthday ? member.birthday : null,
                        website: member.website ? member.website : null,
                        interests: member.interests ? member.interests : null,
                        button: true,
                        size: 'med',
                        bans: member.bailiwiksBanned,
                        blocks: member.blocks,
                        blocked: userStore.isBlocked(member._id),
                        banned: userStore.isBanned(
                            bailiwikStore.getBailiwikId,
                            member.bailiwiksBanned
                        ),
                        arrow: false,
                        padding: true,
                        swipeable: member._id !== userStore.getUserId,
                        swipeableType:
                            bailiwikStore.isBailiff || bailiwikStore.isHost()
                                ? 'bailiff'
                                : 'user',
                        avatar: true,
                        pushToken: member.pushToken ? member.pushToken : null,
                        pushSettings: member.pushSettings
                            ? member.pushSettings
                            : null,
                        image: member.avatar || null,
                        updatedAt: member.updatedAt ? member.updatedAt : null,
                    };
                    return populatedMember;
                });
                this.bailiwikMembersList = BailiwikMemberList.slice();
            });

            this.membersState = 'loaded';
        } else {
            this.membersState = 'error';
        }

        return this.bailiwikMembersList;
    }

    get getMembers() {
        return this.bailiwikMembers && this.bailiwikMembers.length > 0
            ? this.bailiwikMembers.slice()
            : false;
    }
}
