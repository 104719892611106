import React, {useState} from 'react'
import './style.css'

const EditTextModal = (props) => {
    const [text, setText] = useState(props.text)
    const handlePost = () => props.onPost(text)

    return (
            <>
                <div className="mm-input">
                    <textarea 
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Say something">

                    </textarea>
                </div>
                <div className="mm-button">
                    <button onClick={handlePost}>
                        POST
                    </button>
                </div>
            </>
    )
}

export default EditTextModal