import { observer } from 'mobx-react';
import React, { useState, useRef } from 'react';

import { useStores } from '../../../../lib/store';
import iconPerson from '../../../../assets/person.png';
import { getImageUrl } from '../../../../lib/utils';
import { updateUser } from '../../../../services/databaseService';
import AvatarCropper from '../../../common/AvatarCropper';

/**
 * Avatar component
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function Avatar() {
    const { userStore } = useStores();
    const [preview, setPreview] = useState('');
    const { currentUser } = userStore;
    const inputFileRef = useRef();

    const [processing, setProcessing] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const buildFormData = (image) => {
        const formData = new FormData();
        formData.append('user[appVersion]', process.env.APP_VERSION);
        formData.append('avatar', image);
        return formData;
    };

    const uploadAvatar = (image) => {
        if (processing) {
            return false;
        }
        setProcessing(true);
        const formData = buildFormData(image);
        updateUser(userStore.getUserId, formData, 'AccountScreen').then(
            async (res) => {
                if (!res) {
                    throw new Error('Error response');
                }
                setProcessing(false);
                setCroppedImage(URL.createObjectURL(image)); // show instant preview
                setPreview(''); // close modal
                await userStore.load('AccountScreen handleSubmit');
            }
        );
    };

    const onChangeHandler = async (e) => {
        try {
            setPreview(URL.createObjectURL(e.target.files[0]));
        } catch (err) {
            console.error(err);
            alert('Something went wrong. Please try again.');
        }
    };

    const closeCropper = (e) => {
        e.preventDefault();
        if (processing) {
            return false;
        }
        setPreview('');
    };

    return (
        <div className="tw-mt-7">
            {preview && (
                <AvatarCropper
                    source={preview}
                    onCrop={(image) => uploadAvatar(image)}
                    onClose={closeCropper}
                />
            )}
            <div
                data-test-id="btn-avatar"
                className="tw-relative tw-w-14 tw-h-14 tw-mx-auto "
                onClick={() => {
                    inputFileRef.current && inputFileRef.current.click();
                }}
            >
                <input
                    ref={inputFileRef}
                    type="file"
                    onChange={onChangeHandler}
                    className="tw-hidden"
                    accept="image/*"
                />
                <div className="tw-w-full tw-h-full tw-rounded-full tw-overflow-hidden">
                    <img
                        className="tw-object-cover tw-h-full tw-w-full"
                        src={
                            croppedImage
                                ? croppedImage
                                : currentUser.avatar
                                ? getImageUrl(currentUser.avatar)
                                : iconPerson
                        }
                    />
                </div>
                <div className="tw-absolute tw-bottom-0 tw-right-0 tw-bg-orange-chat tw-w-6 tw-h-6 tw-rounded-full tw-overflow-hidden">
                    <div className="tw-flex tw-h-full tw-justify-center tw-items-center">
                        <svg
                            width="12"
                            height="11"
                            viewBox="0 0 12 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask
                                id="mask0"
                                mask-type="alpha"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="12"
                                height="11"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.49795 1.91977V0.451172H2.47314V1.91977H3.93594V2.89883H2.47314V4.36743H1.49795V2.89883H0.0351562V1.91977H1.49795ZM2.96124 4.85645V3.38785H4.42403V1.91926H7.83722L8.72952 2.89832H10.2752C10.8116 2.89832 11.2504 3.3389 11.2504 3.87739V9.75178C11.2504 10.2903 10.8116 10.7308 10.2752 10.7308H2.47364C1.93728 10.7308 1.49845 10.2903 1.49845 9.75178V4.85645H2.96124ZM6.37394 9.26274C7.7197 9.26274 8.81192 8.16618 8.81192 6.81507C8.81192 5.46396 7.7197 4.36741 6.37394 4.36741C5.02817 4.36741 3.93595 5.46396 3.93595 6.81507C3.93595 8.16618 5.02817 9.26274 6.37394 9.26274ZM4.81379 6.81495C4.81379 7.68142 5.51105 8.38145 6.3741 8.38145C7.23715 8.38145 7.93441 7.68142 7.93441 6.81495C7.93441 5.94848 7.23715 5.24845 6.3741 5.24845C5.51105 5.24845 4.81379 5.94848 4.81379 6.81495Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0)">
                                <rect
                                    x="-6.30371"
                                    y="-6.40234"
                                    width="24.3799"
                                    height="24.4766"
                                    fill="white"
                                />
                                <mask
                                    id="mask1"
                                    mask-type="alpha"
                                    maskUnits="userSpaceOnUse"
                                    x="-7"
                                    y="-7"
                                    width="26"
                                    height="26"
                                >
                                    <rect
                                        x="-6.30371"
                                        y="-6.40234"
                                        width="24.3799"
                                        height="24.4766"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask1)"></g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="tw-text-xs tw-text-center tw-my-3">
                UPLOAD PHOTO
            </div>
        </div>
    );
}

export default observer(Avatar);
