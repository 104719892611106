import { handleSignOut } from '../../services/authService';

export default function useGuestSignup(bailiwik) {
    const guestJoin = () => {
        if (bailiwik) {
            window.sessionStorage.setItem(
                'guest-join-bw',
                JSON.stringify(bailiwik)
            );
        }
        handleSignOut();
        window.location.href = window.location.origin + '/sign-up';
    };

    return guestJoin;
}
