export const getPageWithBailiwikIdFromURL = () => {
    const splittedUrl = window.location.pathname.split('/');

    return [splittedUrl[1], splittedUrl[2]];
};

const urlMaxLength = 50; // Adjust this value as needed

export const getHostnameFromDomain = (url) => {
    if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
    }

    const shortenUrl = (url) => {
        if (url.length > urlMaxLength) {
            return (
                url.slice(0, urlMaxLength / 2) +
                '...' +
                url.slice(-urlMaxLength / 2)
            );
        }
        return url;
    };

    return shortenUrl(url);

    // const domain = new URL(url);
    // return domain.hostname;
};
