import React from 'react';
import TextInput from '../common/ChatToolbar/TextInput';
import { withTranslation } from 'react-i18next';

class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputHeight: this.props.inputHeight,
            messageContent: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ messageContent: event });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.state.messageContent);
        this.setState({
            ...this.state,
            messageContent: '',
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="chat-box-wrapper">
                <form
                    style={{
                        maxWidth: '1120px',
                        margin: '0 auto',
                    }}
                    className="chat-box input-container mb-0 row d-flex justify-content-center align-items-center"
                    onSubmit={this.handleSubmit}
                >
                    <div className="d-flex w-100 px-4 justify-content-center">
                        <div className="media-button">
                            <button
                                type="button"
                                onClick={() => this.props.onMedia()}
                                className="button-bg button-upload w-flex-grow-0 tw-flex-shrink-0 tw-w-11 tw-h-11 tw-rounded-full tw-bg-white tw-overflow-hidden tw-mt-1"
                            >
                                <div className="tw-w-6 tw-mx-auto">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 27 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3 3.5H0.5L1.5 24H21.5V22.5H4L3 3.5Z"
                                            fill="#364C80"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5 0H27L26.5 20.5H6.5L5 0ZM9.00004 18L15 10L17.5 13.5L19.5 9L23.5 18H9.00004Z"
                                            fill="#364C80"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div className="message-row tw-max-w-3xl">
                            <TextInput
                                onEnter={(e) => this.handleSubmit(e)}
                                className="message-input"
                                text={this.state.messageContent}
                                onChange={this.handleChange}
                                placeholder={t('Say Something ellipsis')}
                            />
                            <div className="input-button-bg">
                                <button
                                    type="submit"
                                    value="Submit"
                                    className="button-bg"
                                >
                                    <img
                                        src="/images/icons/send-white.svg"
                                        alt="deals"
                                        className="input-button"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default withTranslation()(MessageBox);
