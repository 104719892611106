import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../../core/contexts/FirebaseContext';
import Modal from '../chat/StandardModal';

export default function DeleteDealPopup({
    data,
    onClose,
}) {
    const { t } = useTranslation();
    const { firebase } = useFirebase();

    const onSubmit = () => {
        firebase
            .database()
            .ref(`/bailiwik/${data.bailiwik._id}/messages/${data._id}`)
            .remove();
        onClose();
    }

    return (
        <Modal
            onClose={onClose}
            title={t('Are you sure you want to delete this deal')}>
            <div className="create-deal-body">
                <button
                    className="delete-deal-submit-button"
                    onClick={onSubmit}>
                    {t('Confirm delete')}
                </button>
            </div>
        </Modal>
    )
}
