import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import RootStore from '../stores/RootStore';

/**
 * Store Context
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export const StoreContext = createContext({});

/**
 * Provider component for MobX
 * 
 * @param {React.Element} props 
 * 
 * @returns {React.Element}
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export function StoresProvider({ children }) {
    const rootStore = new RootStore();
    return (
        <StoreContext.Provider value={rootStore}>
            {children}
        </StoreContext.Provider>
    );
}
StoresProvider.propTypes = {
    children: PropTypes.node,
}

/**
 * Hook for store context
 * 
 * @returns {function}
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export const useStores = () => useContext(StoreContext);
