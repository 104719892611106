import React, { useCallback, useState, useMemo } from 'react'

export const LocalStorageContext = React.createContext([])

const LocalStorageProvider = (props) => {
    const [storage, setStorage] = useState()
    const [key, setKey] = useState()

    const value = useMemo(() => {
        if(storage) return storage

        let data

        try {
            data = JSON.parse(localStorage.getItem(key))
        } catch (e) {
            data = localStorage.getItem(key)
        }
        setStorage(data || [])
    }, [key, storage])

    const setValue = useCallback((data, key) => {
        const value = JSON.stringify(data)
        
        localStorage.setItem(key, value)
        setStorage(data)
    }, [setStorage])

    return (
        <LocalStorageContext.Provider value={{
            setKey,
            value, 
            setValue
        }}>
            {props.children}
        </LocalStorageContext.Provider>
    )

}

export default LocalStorageProvider