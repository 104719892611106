import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

export default function DateRangePicker({
    allDay,
    refDateRange,
    defaultStartDate,
    defaultEndDate,
    ...rest
}) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [minEndDate, setMinEndDate] = useState(new Date());

    useEffect(() => {
        refDateRange.current = {
            startTime: startDate,
            endTime: endDate,
        }
    }, [startDate, endDate])

    useEffect(() => {
        setStartDate(getDateFromString(defaultStartDate));
        setEndDate(getDateFromString(defaultEndDate));
    }, [defaultStartDate, defaultEndDate])

    const getDateFromString = (d) => {
        if (typeof d == "string") {
            return new Date(d);
        } else {
            return d;
        }
    };

    return (
        <>
            <DatePicker
                placeholderText="Start Date"
                showTimeInput={!allDay}
                selected={startDate}
                dateFormat={allDay ? 'll' : 'lll'}
                // minDate={new Date()}
                value={moment(startDate).format(allDay ? 'll' : 'lll')}
                onChange={(e) => {
                    setStartDate(e);
                    setMinEndDate(e);
                    if (e > endDate) {
                        const newEndDate = moment(e).add(1, 'hours').toDate()
                        setEndDate(newEndDate);
                    }
                }}
                {...rest} />
            <DatePicker
                placeholderText="End Date"
                // minDate={moment(startDate).add(1, 'days').toDate()}
                minDate={minEndDate}
                showTimeInput={!allDay}
                selected={endDate}
                dateFormat={allDay ? 'll' : 'lll'}
                value={moment(endDate).format(allDay ? 'll' : 'lll')}
                onChange={setEndDate}
                {...rest} />
        </>
    )
}
