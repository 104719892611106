import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPageWithBailiwikIdFromURL } from '../helpers';
import useConversations from '../core/hooks/useConversations'
import { useStores } from '../lib/store';
import { useLogToAmplitude } from '../core/utility/amplitude';

const Navbar = ({ isGuest }) => {
    let [, bailiwikId] = getPageWithBailiwikIdFromURL();
    const { t } = useTranslation();
  const { userStore } = useStores();
  const { bailiwikStore } = useStores();
  const logToAmplitude = useLogToAmplitude();
  const { current: currentBailiwik } = bailiwikStore;
  console.log('navbar currentBailiwik?._id:', currentBailiwik?._id);

  if (!bailiwikId && currentBailiwik?._id) {
    //fallback if needed and available
    //bailiwikId from getPageWithBailiwikIdFromURL isn't always available
    bailiwikId = currentBailiwik?._id;
  }

    const conversations  = useConversations(userStore.currentUser._id);

    const unreadConversationsCount = useMemo(() => {
        if (conversations?.length > 0) {
          return conversations.filter(el => el.conversation.unread !== false)
        }
        
        return 0
    }, [conversations]);

    const onGuestProtectedClick = (e, tabName) => {
        if (isGuest) {
            e.preventDefault();
            e.stopPropagation();
        }
        recordAction(tabName);
    };

    const onUnprotectedClick = (e, tabName) => {
        recordAction(tabName);
    };

    const recordAction = (tabName) => {
        logToAmplitude(userStore?.currentUser?._id,
            bailiwikStore?.currentBailiwik?._id,
            bailiwikStore?.currentBailiwik?.name, tabName);
    };

    return (
        <div className="bottom-nav ">
            <Link to={`/chat/${bailiwikId}`} className="nav-icon-container"
                onClick={(e) => onUnprotectedClick(e, `Home Tab`)}
                >
                <img
                    src="/images/icons/home.png"
                    alt="home"
                    className="nav-icon"
                />
                <div>{t('Home')}</div>
            </Link>
            <Link
                to={`/members/${bailiwikId}`}
                className={`nav-icon-container ${
                    isGuest ? 'nav-icon-disabled' : ''
                }`}
                onClick={(e) => onGuestProtectedClick(e, `Members Tab`)}
            >
                <img
                    src="/images/icons/community.png"
                    alt="community"
                    className={`nav-icon`}
                />
                <div>
                    {t('Members')} {isGuest && '🔒'}
                </div>
            </Link>
            <Link
                to={`/dm/${bailiwikId}`}
                className={`nav-icon-container ${
                    isGuest ? 'nav-icon-disabled' : ''
                }`}
                onClick={(e) => onGuestProtectedClick(e, `Messages Tab`)}
            >
                <img
                    src="/images/icons/chat.png"
                    alt="chat"
                    className={`nav-icon`}
                />
                <div>
                    {t('Messages')} {isGuest && '🔒'}
                    {unreadConversationsCount > 0 && (
                        <span className="red-dot">
                            {unreadConversationsCount}
                        </span>
                    )}
                </div>
            </Link>
            <Link to={`/events/${bailiwikId}`} className="nav-icon-container"
                onClick={(e) => onUnprotectedClick(e, `Events Tab`)}
            >
                <img
                    src="/images/icons/events.png"
                    alt="events"
                    className="nav-icon"
                />
                <div>{t('Events')}</div>
            </Link>
            <Link to={`/deals/${bailiwikId}`} className="nav-icon-container"
                onClick={(e) => onUnprotectedClick(e, `Deals Tab`)}
            >
                <img
                    src="/images/icons/deals.png"
                    alt="deals"
                    className="nav-icon"
                />
                <div>{t('Deals')}</div>
            </Link>
        </div>
    );
};

export default Navbar;
