import { useFirebase } from '../../core/contexts/FirebaseContext';
import { useStores } from '../../lib/store';
import { useLogToAmplitude } from '../../core/utility/amplitude';

export default function useRedeemDeal() {
    const { firebase } = useFirebase()
    const { userStore: { currentUser },
        bailiwikStore: { current } } = useStores();
    const logToAmplitude = useLogToAmplitude();
    const redeemDeal = async (item) => {
        const bailiwikId = current?._id;
        const bailiwikName = current?.name;
        const userId = currentUser._id;
        const newContent = {
            ...item.content,
            deal: {
                ...item.content.deal,
                redeemed: item.content.deal.redeemed ? [...item.content.deal.redeemed, userId] : [userId],
            }
        };
        await firebase.database().ref(`/bailiwik/${item.bailiwik._id}/messages/${item._id}`).update({ content: newContent })
        const p = {
            BailiwikID: bailiwikId,
            BailiwikName: bailiwikName,
            dealId: item._id,
            dealName: item.title,
            screen: 'DealsScreen',
        };
        logToAmplitude(userId, bailiwikId, bailiwikName, 'Redeem Deal', p);
    }

    return redeemDeal
}
