const AUTH_KEY = '@Bailiwikv1:token';
const DMS_KEY = '@Bailiwikv1:dms';
export const CURR_USER_ID_KEY = '@Bailiwikv1:userId';
const CURR_USER_KEY = '@Bailiwikv1:currentUser';
const CURR_BAILI_KEY = '@Bailiwikv1:current';
const UNSENT_CHAT_KEY = '@Bailiwikv1:cachedChatUnsent';
const CHAT_KEY = '@Bailiwikv1:cachedChat';
const REMEMBER_KEY = '@Bailiwikv1:remember';
const CACHED_DMS_KEY = 'cachedDMs';
const STORED_NOTIFICATION_KEY = 'storedNotifications';
const BAILIWIKS_KEY = 'bailiwiks';
const CURRENT_BAILIWIKS_KEY = 'currentBailiwik';
const LOGGED_IN_KEY = 'loggedIn';
const CURRENT_CHAT_ID_KEY = 'currentChatId';

/**
 * Contains auth related keys that should be wipped out when logout
 *
 * @FIXME: This should be updated as features complete
 * Refer mobile app AuthServie.handleSignOut
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const AuthDataKeys = [
    AUTH_KEY,
    DMS_KEY,
    CURR_USER_ID_KEY,
    CURR_USER_KEY,
    CURR_BAILI_KEY,
    CHAT_KEY,
    REMEMBER_KEY,
    CACHED_DMS_KEY,
    STORED_NOTIFICATION_KEY,
    BAILIWIKS_KEY,
    CURRENT_BAILIWIKS_KEY,
    LOGGED_IN_KEY,
    // @PATCH:
    // following keys should be properly placed
    'token',
    'key',
    'currentUser',
    'chat',
];

/**
 * Storage engine
 *
 * Base on user preference to remember login
 */
let storageEngine = null;

/**
 * Get stored remember login flag
 *
 * @returns {boolean}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getRememberLogin = () =>
    JSON.parse(localStorage.getItem(REMEMBER_KEY) || 'false');

/**
 * Set storage engine
 */
const setStorageEngine = () => {
    const remember = getRememberLogin();
    if (remember === false) {
        storageEngine = window.sessionStorage;
    } else {
        storageEngine = window.localStorage;
    }
};

/**
 * Set remember login flag
 *
 * @param {boolean} value
 *
 * @returns void
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setRememberLogin = (value) => {
    localStorage.setItem(REMEMBER_KEY, JSON.stringify(value));
    setStorageEngine();
};

/**
 * Get from storage engine
 *
 * @param {string} key
 *
 * @returns {mixed}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const get = (key) => {
    if (storageEngine === null) {
        setStorageEngine();
    }
    return storageEngine.getItem(key);
};

/**
 * Store in storage engine
 *
 * @param {string} key
 * @param {mixed} value
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const set = (key, value) => {
    if (storageEngine === null) {
        setStorageEngine();
    }
    return storageEngine.setItem(key, value);
};

/**
 * Remove item from storage
 *
 * @param {string} key
 *
 * @returns {void}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const remove = (key) => {
    if (storageEngine === null) {
        setStorageEngine();
    }
    return storageEngine.removeItem(key);
};

/**
 * Get Auth token
 *
 * @returns {string}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getAuthToken = () => get(AUTH_KEY);

/**
 * Store auth token
 *
 * @param {string} token
 *
 * @returns {void}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setAuthToken = (token) => set(AUTH_KEY, token);

/**
 * Get saved DMs
 *
 * @returns {object} Returns array object
 *
 * @author Gihan S <gihanshp>
 */
export const getSavedDMs = () => {
    const dms = get(DMS_KEY) || '[]';
    return JSON.parse(dms);
};

/**
 * Get last saved DM
 *
 * @param {object} options
 *
 * @returns {boolean|string} Returns recent chat id if found otherwise false
 *
 * @author Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getLastSavedDMId = ({ to, from }) => {
    const savedDMs = getSavedDMs();
    if (savedDMs.length === 0) {
        return false;
    }

    const saved = savedDMs.filter(
        (item) => item.to === to && item.from === from
    );
    return saved.length > 0 ? saved[0]._id : false;
};

/**
 * Store DMs
 *
 * @param {object} payload
 *
 * @returns {void}
 *
 * @author Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setSavedDMs = (payload) => {
    const dms = [...getSavedDMs(), payload];
    set(DMS_KEY, JSON.stringify(dms));
};

/**
 * Store cached DMs
 *
 * @param {object} payload
 *
 * @returns {void}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setCachedDMs = (payload) =>
    set(CACHED_DMS_KEY, JSON.stringify(payload));

/**
 * Get cached DMs
 *
 * @returns {object}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getCachedDMs = () => JSON.parse(get(CACHED_DMS_KEY) || '[]');

/**
 * Store current user
 *
 * @param {object} payload
 *
 * @returns {void}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setCurrentUser = (payload) => {
    set(CURR_USER_KEY, JSON.stringify(payload));
};

/**
 * Get stored current user
 *
 * @returns {object}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getCurrentUser = () => {
    const data = get(CURR_USER_KEY);
    if (!data) {
        return null;
    }
    return JSON.parse(data);
};

/**
 * Store current Bailiwik data
 *
 * @param {object} payload
 *
 * @returns {void}
 *
 * @author Copied from Mobile App code
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setCurrentBailiwik = (payload) => {
    set(CURR_BAILI_KEY, JSON.stringify(payload));
    set(CURRENT_BAILIWIKS_KEY, JSON.stringify(payload));
};

/**
 * Get stored bailiwik data
 *
 * @returns {object}
 *
 * @author Copied from Mobile App code
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getCurrentBailiwik = () => {
    const data = get(CURR_BAILI_KEY);
    if (!data) {
        return null;
    }
    return JSON.parse(data);
};

/**
 * store unsent messages
 *
 * @param {object} content
 *
 * @author Copied from Mobile App code
 * @author Gihan S <gihanshp@gmail.com>
 */
export const cachedChatUnsent = (content) => {
    set(UNSENT_CHAT_KEY, JSON.stringify(content));
};

/**
 * Get unsent messages
 *
 * @returns {object}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getCachedChatUnsent = () =>
    JSON.parse(get(UNSENT_CHAT_KEY) || '[]');

/**
 * Store all chat data
 *
 * @param {object} content
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const storeCachedChat = (content) => {
    set(CHAT_KEY, content);
};

/**
 * Get stored chats
 *
 * @returns {object}
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getCachedChat = () => JSON.parse(get(CHAT_KEY) || '[]');

/**
 * Store notifications on device
 *
 * @param {object} content
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setStoredNotifications = (content) =>
    set(STORED_NOTIFICATION_KEY, JSON.stringify(content));

/**
 * Get stored notifications on device
 *
 * @author Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getStoredNotifications = () =>
    JSON.parse(get(STORED_NOTIFICATION_KEY) || '[]');

/**
 * Remove auth data from device
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const removeAuthData = () => {
    AuthDataKeys.forEach((key) => {
        remove(key);
    });
};

/**
 * Set user bailiwiks
 *
 * @param {object} data
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setBailiwiks = (data) => set(BAILIWIKS_KEY, JSON.stringify(data));

/**
 * Get stored user bailiwiks
 *
 * @returns {object}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getBailiwiks = () => JSON.parse(get(BAILIWIKS_KEY) || '[]');

/**
 * Update user bailiwiks
 *
 * @param {object} newData - The updated bailiwiks data
 */
export const updateBailiwiks = (newData) => {
    const existingData = getBailiwiks(); // get existing bailiwiks
    existingData.push(newData); // add new bailiwik to existing bailiwiks
    setBailiwiks(existingData); // update bailiwiks
};

/**
 * Get stored user ID
 *
 * @returns {string}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getStoredUserId = () => JSON.parse(get(CURR_USER_ID_KEY) || '""');

/**
 * Set user ID
 *
 * @param {string}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setStoredUserId = (payload) =>
    set(CURR_USER_ID_KEY, JSON.stringify(payload));

/**
 * Set login status
 *
 * @param {boolean}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setLoggedIn = (data) => set(LOGGED_IN_KEY, JSON.stringify(data));

/**
 * Get login status
 *
 * @returns {boolean}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getLoggedIn = () => JSON.parse(get(LOGGED_IN_KEY) || '""');

/**
 * Store current chat ID
 *
 * @param {string} id ChatID
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const setCurrentChatId = (id) =>
    set(CURRENT_CHAT_ID_KEY, JSON.stringify(id));

/**
 * Get stored current chat ID
 *
 * @returns string
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getCurrentChatId = () =>
    JSON.parse(get(CURRENT_CHAT_ID_KEY) || '""');
