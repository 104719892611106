import { useState } from 'react';

import { useStores } from '../../lib/store';
import { login, getUserBailiwiks } from '../../services/databaseService';
import {
    setRememberLogin,
    setAuthToken,
    setLoggedIn,
    setStoredUserId,
} from '../../services/deviceService';

/**
 * useLogin hook for reusable login function
 *
 * @TODO: unitTest
 *
 * @param {string} initialSource
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function useLogin(initialSource) {
    const { userStore } = useStores();
    const [source] = useState(initialSource);

    /**
     * Perform login
     *
     * @param {string} email
     * @param {string} password
     * @param {boolean} remember
     *
     * @returns {Promise}
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    return async (email, password, remember = false, callback) => {
        const user = {
            email: email,
            password: password,
        };
        const userData = await login(user)
            .then(async (user) => {
                if (user.success) {
                    return user.user;
                } else if (user.errors.email) {
                    alert(user.errors.email);
                } else if (user.errors.password) {
                    alert(user.errors.password);
                } else {
                    alert('Something went wrong. Please refresh');
                }
            })
            .catch((err) => console.error(err));
        if (userData) {
            setRememberLogin(remember);
          const TOKEN = userData.token;
          console.log('useLogin TOKEN:', TOKEN);
            setAuthToken(TOKEN);
            setStoredUserId(userData._id);
            const userBailiwiks = await getUserBailiwiks();
            if (callback) {
                callback(
                    userBailiwiks.bailiwiks && userBailiwiks.bailiwiks[0]?._id
                );
            }
            await userStore.updateData(
                { user: userData, userBailiwiks },
                'useLogin'
            );

            setLoggedIn(true);

            return Promise.resolve();
        }
    };
}
