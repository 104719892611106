import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../UI/loading-spinner';

/**
 * Loading spinner inside a modal overlay
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function LoadingSpinnerWithModal({ message }) {
    return (
        <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black-chat tw-bg-opacity-50">
            <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                <div data-test-id="loading-spinner">
                    <LoadingSpinner />
                    {message !== '' && (
                        <div className="tw-text-center tw-text-sm tw-w-3/4 tw-mx-auto tw-mt-4">
                            {message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

LoadingSpinnerWithModal.defaultProps = {
    message: '',
};

LoadingSpinnerWithModal.propTypes = {
    message: PropTypes.string.isRequired,
};
