import React from 'react';
import BailiwikAvatar from '../../bailiwik-avatar';
import { Link, useHistory } from 'react-router-dom';
import './style.css';

const BailiwiksItem = (props) => {
    const history = useHistory();
    function handleBailiwikClick() {
        props.switchBailiwik(props.bailiwik);
        history.push(`/chat/${props.bailiwik._id}`);
    }

    return (
        <Link
            to={`/chat/${props.bailiwik._id}`}
            className="col-12 d-flex my-1 p-2 align-items-center find-bailiwik-font switch-hover"
            onClick={handleBailiwikClick}
        >
            <BailiwikAvatar bailiwik={props.bailiwik} />
            <div className="ml-3">
                {!props.showDescription && (
                    <p className="fb-hostname">
                        HOST: {props.bailiwik.bailiff.fullName}{' '}
                    </p>
                )}
                <p>
                    {props.bailiwik.name}
                    {props.bailiwik.exclusive && (
                        <i className="tw-w-4 tw-h-4 tw-inline-block tw-ml-1 tw-align-text-bottom">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 1 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </i>
                    )}
                </p>
                {!props.showDescription && (
                    <p className="fb-members">
                        {props.bailiwik.memberCount}
                        {' Active Member'}
                    </p>
                )}
                {props.bailiwik.description && props.showDescription && (
                    <p className="fb-members mt-1">
                        {props.bailiwik.description}
                    </p>
                )}
            </div>
        </Link>
    );
};

export default BailiwiksItem;
