import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import { Helmet } from 'react-helmet';
import MessageBox from './message-box';
import ParentPost from './ParentPost';
import Header from '../header';
import CommentsModal from './CommentsModal';
import ImageUploaderModal from './ImageUploader';
import { getAuthToken } from '../../services/deviceService';
import Modal from './StandardModal';
import DeleteModal from './StandardModal/DeleteMediaModal';
import EditTextModal from './StandardModal/EditTextModal';
import ReportPost from './StandardModal/ReportPostModal';
import JoinBailiwik from './JoinBailiwik';
import Toast from '../toast';
import { sanitize } from '../../lib/utils.js';
import AddBadge from '../common/AddPostBadge';
import { useStores } from '../../lib/store';
import { mapEventGuestUsers } from '../../utils';
import Profile from '../common/MembersList/Profile';
import FirebaseMessages from './FirebaseChat/index';
import useRedeemDeal from '../../core/hooks/useRedeemDeal';
import useEventCtaClick from '../../core/hooks/useEventCtaClick';
import useSendMessage from '../../core/hooks/useSendMessage';
import useMessages from '../../core/hooks/useMessages';
import useGuestJoin from '../../core/hooks/useGuestJoin';
import useGuestSignup from '../../core/hooks/useGuestSignup';
import useToggleLike from '../../core/hooks/useToggleLike';
import DealModal from '../Deal/Modal';
import DeleteDealPopup from '../Deal/DeletePopup';
import EventModal from '../Event/Modal';
import DeleteEventPopup from '../Event/DeletePopup';
import {
    editMediaMessage,
    editMessage,
    removeMessage,
    reportMessage,
} from '../../core/utility/firebase-message-actions';
import useJoinBailiwik from '../../core/hooks/useJoinBailiwik';
import { BASE_URL } from '../../env';
import { replaceMentionValues } from '../../utils';
import { getRepostData } from '../../core/utility/messageContent';
import { useLogToAmplitude } from '../../core/utility/amplitude';

import {
    updateBailiwiks
} from '../../services/deviceService';

const getMentionIds = (text) => {
    const ids = [];
    if (text) {
        replaceMentionValues(text, ({ id }) => ids.push(id));
    }
    return ids;
};

const Chat = (props) => {
    const {
        userStore: { load: loadUser },
    } = useStores();
    const [commentsMessage, setCommentsMessage] = useState();
    const [mediaModalData, setMediaModalData] = useState();
    const [messageToDelete, setMessageToDelete] = useState();
    const [messageToEdit, setMessageToEdit] = useState();
    const [messageToReport, setMessageToReport] = useState();
    const { joinBailiwik } = useJoinBailiwik();
    const guestJoin = useGuestJoin(props.currentBailiwik);
    const guestSignup = useGuestSignup(props.currentBailiwik);
    const eventCtaClick = useEventCtaClick();
    const sendMessage = useSendMessage(
        `bailiwik/${props.currentBailiwik._id}/messages`,
        { currentUser: props.currentUser },
        props.currentBailiwik
    );
    const toggleLike = useToggleLike();
    const scrollableRef = useRef();
    const toastRef = useRef();
    const prevBailiwik = useRef(props.currentBailiwik);
    const logToAmplitude = useLogToAmplitude();
    const isMember =
        props.currentBailiwik?.members?.findIndex(
            (member) =>
                member === props.currentUser._id ||
                member._id === props.currentUser._id
        ) >= 0;
    const scrallableRef = useRef();
    const userToken = getAuthToken();
    const user = { currentUser: props.currentUser };

    const handleJoinBailiwik = async (bailiwik) => {
        try {
            const result = await joinBailiwik(bailiwik);
            handleJoin(result.bailiwik, true);
        } catch (e) {
            console.log('>>> error switching bailiwik', e);
        }
    };

    const sendLike = useCallback((item) => toggleLike(item, user), [user]);

    const deleteMessage = (item) => removeMessage(item);

    const handleSubmit = (text) => {
        const sanitized = sanitize(text);

        if (!sanitized) return;
        scrallableRef.current.scroll(0, 0);
        const content = { text: sanitized };
        const mentionIds = getMentionIds(text);
        if (mentionIds?.length > 0) {
            content.mentionIds = mentionIds;
        }
        sendMessage('TEXT', content, {});

        logToAmplitude(user?.currentUser?._id, props.currentBailiwik._id,
            props.currentBailiwik.name, 'Post',
            { type: 'text' });
    };
    const handleUpdate = (message) => {
        editMessage(messageToEdit, message);
        setMessageToEdit(false);
        toastRef.current.notify('POST UPDATED!');
    };

  const onUpload = (media) => {
      //mediaModalData is set when editing a media post
      //media is from the image uploader
    if (mediaModalData.content) {
      console.log('chat-firebase submit to editMediaMessage');
            editMediaMessage(mediaModalData, media);
            toastRef.current.notify('MEDIA UPDATED!');
        } else {
            sendMessage('MEDIA', { media: media });
            toastRef.current.notify('MEDIA UPLOADED');
        }
        setMediaModalData(false);

        logToAmplitude(
            props.currentUser?._id,
            props.currentBailiwik?._id,
            props.currentBailiwik?.name,
            'Post',
            { type: 'media', screen: 'home' }
          );
    };

  const onRemoveMedia = async () => {
      console.log('chat-firebase onRemoveMedia');
        const req = {
            method: 'DELETE',
            headers: {
                Authorization: `Token ${userToken}`,
            },
        };
    let result;
    let resultJson;
    if (messageToDelete?.content?.media?._id) {
      result = await fetch(
        `${BASE_URL}/api/media/${messageToDelete.content.media._id}`,
        req
      );
      resultJson = await result.json();
    }

        if ( !messageToDelete?.content?.media?._id || resultJson?.success) {
            deleteMessage(messageToDelete);
            setMessageToDelete(false);
            toastRef.current.notify('MEDIA HAS BEEN DELETED');
        }
    };

    const onRemoveMessage = () => {
        deleteMessage(messageToDelete);
        setMessageToDelete(false);
        toastRef.current.notify('POST HAS BEEN DELETED');
    };

    const onReportPost = async () => {

        let areReported = null;
        const item = messageToReport;
        const res = await reportMessage(item, user, areReported);
      
         const body = {
            bailiwik: {
                _id: props.currentBailiwik?._id,
                name: props.currentBailiwik?.name,
            },
            creator : {
                _id: messageToReport?.creator?._id,
                fullName: messageToReport?.creator?.fullName,
            },
            createdAt: new Date(messageToReport?.createdAt).getTime(), 
            content: messageToReport.type === "MEDIA"
            ? { media_id: messageToReport._id } // Include media_id for MEDIA type
            : (messageToReport.type === "EVENT" || messageToReport.type === "DEAL")
                ? undefined  // Exclude content for EVENT and DEAL
                : (messageToReport.type === "TEXT")
                    ? { text: messageToReport.content.text } // Include text for TEXT type
                    : {
                        media_id: messageToReport._id,
                    },
            ref: messageToReport?.ref,

        };

        
        const req = {
            method: 'PUT',
            headers: {
                Authorization: `Token ${userToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };

        const result = await fetch(
            `${BASE_URL}/api/bailiwiks/${messageToReport.bailiwik}/report_firebase_chat`,
            req
        );
        const resultJson = await result.json();
        
        if (resultJson.success && res.committed) {
            setMessageToReport(false);
            toastRef.current.notify('POST HAS BEEN REPORTED');
        }else{
            setMessageToReport(false);
            toastRef.current.notify('POST HAS BEEN UNREPORTED');
        }

    };

    const handleJoin = useCallback(
        async (bailiwik, reLoadUser) => {
            await props.switchBailiwik(bailiwik);
            await props.updateUserData();
            updateBailiwiks(bailiwik); // update bailiwiks in local storage

            if (reLoadUser) {
                await loadUser('Sign Up');
            }

           window.location.href = `${window.location.origin}/chat/${bailiwik._id}`;
        },
        [props]
    );

    useEffect(() => {
        if (scrollableRef.current && scrollableRef.current.el)
            scrollableRef.current.el.scrollTo(
                0,
                scrollableRef.current.el.scrollHeight
            );
    }, [props.currentBailiwik._id]);

    useEffect(() => {
        const isPrevMember =
            prevBailiwik.current?.members?.findIndex(
                (member) =>
                    member === props.currentUser._id ||
                    member._id === props.currentUser._id
            ) >= 0;
        const isCurrentMember =
            props.currentBailiwik?.members?.findIndex(
                (member) =>
                    member === props.currentUser._id ||
                    member._id === props.currentUser._id
            ) >= 0;

        if (
            !isPrevMember &&
            isCurrentMember &&
            prevBailiwik.current._id === props.currentBailiwik._id
        ) {
            toastRef.current.notify(
                `YOU'VE JOINED ${props.currentBailiwik.name}`
            );
        }

        prevBailiwik.current = props.currentBailiwik;
    }, [props.currentBailiwik]);

    useEffect(() => {
        const guestJoinBWId = window.sessionStorage.getItem('guest-join-bw');
        const guestVisitBWId = window.sessionStorage.getItem('guest-visit-bw');
     
        // if guest is visiting a bailiwik, redirect to chat
        // came from Log In page
        if(guestVisitBWId){
            const visitedBailiwik = JSON.parse(guestVisitBWId);
            window.sessionStorage.removeItem('guest-visit-bw');
            window.location.href = `${window.location.origin}/chat/${visitedBailiwik._id}`;
        }
        
        if (
            guestJoinBWId &&
            !props?.currentUser?.email !== process.env.GUEST_EMAIL
        ) {
            handleJoinBailiwik(JSON.parse(guestJoinBWId));
            window.sessionStorage.removeItem('guest-join-bw');
        }
    }, []);

    const [showProfile, setShowProfile] = useState(false);
    const [profile, setProfile] = useState();
    const CommentsModalMemo = useMemo(() => {
        if (!commentsMessage) return;

        const currentMessage = commentsMessage;
        return (
            currentMessage && (
                <CommentsModal
                    currentUser={props.currentUser}
                    // updateData={updateComment}
                    parent={currentMessage}
                    isDM={true}
                    close={() => setCommentsMessage(false)}
                >
                    {(currentMessage.content.media ||
                        currentMessage.content.text ||
                        currentMessage.content.event ||
                        currentMessage.content.deal) && (
                        <ParentPost
                            inThread={true}
                            isDeal={currentMessage.content.deal ? true : false}
                            isDM={false}
                            eventCtaClick={(item, status) =>
                                eventCtaClick(currentMessage, status)
                            }
                            onEditMedia={() =>
                                setMediaModalData(currentMessage)
                            }
                            toggleModal={() =>
                                setCommentsMessage(currentMessage)
                            }
                            remove={() => setMessageToDelete(currentMessage)}
                            currentUser={props.currentUser}
                            like={() =>
                                console.log(currentMessage, 'currentMessage') ||
                                sendLike(currentMessage)
                            }
                            report={() => setMessageToReport(currentMessage)}
                            showProfile={(data) => {
                                setProfile(data);
                                setShowProfile(true);
                            }}
                            message={currentMessage}
                            update={() => setMessageToEdit(currentMessage)}
                            key={currentMessage['_id']}
                        />
                    )}
                </CommentsModal>
            )
        );
    }, [commentsMessage, props.currentUser, sendLike]);

    return (
        <>
            <Helmet>
                <title>Bailiwik Chat : {props.currentBailiwik.name}</title>
                {(props.currentBailiwik.description) &&
                    <meta name="description" content={`Chat in our local community! ` + props.currentBailiwik.description}/>}
            </Helmet>
            {props.isGuest && (
                <div className="add-badge">
                    <AddBadge isGuest={props.isGuest} buttonText="LOGIN" onClick={guestJoin} />
                    <p className="text-lowercase">or</p>
                    <AddBadge textClassName="text-white" className="ab-container white-outline" isGuest={props.isGuest} buttonText="SIGN UP" onClick={guestSignup} />
                </div>
            )}
            {(messageToDelete || messageToEdit || messageToReport) && (
                <Modal
                    onClose={() =>
                        setMessageToDelete(false) ||
                        setMessageToEdit(false) ||
                        setMessageToReport(false)
                    }
                    title={
                        messageToDelete
                            ? 'Confirm Delete'
                            : messageToEdit
                            ? 'Edit Message'
                            : 'Confirm Report'
                    }
                >
                    {messageToDelete && (
                        <DeleteModal
                            onClose={() =>
                                setMediaModalData(false) ||
                                setMessageToDelete(false)
                            }
                            onConfirm={
                                messageToDelete.content.text
                                    ? onRemoveMessage
                                    : onRemoveMedia
                            }
                        />
                    )}
                    {messageToEdit && (
                        <EditTextModal
                            text={messageToEdit.content.text}
                            onPost={handleUpdate}
                        />
                    )}
                    {messageToReport && (
                        <ReportPost
                            onClose={() => setMessageToReport(false)}
                            onConfirm={onReportPost}
                        />
                    )}
                </Modal>
            )}

            {mediaModalData && (
                <ImageUploaderModal
                    source="chat-firebase"
                    message={mediaModalData}
                    onClose={() => setMediaModalData(false)}
                    onUpload={onUpload}
                    user={props.currentUser}
                    bailiwik={props.currentBailiwik}
                />
            )}

            <Header
                bailiwiks={props.bailiwiks}
                switchBailiwik={props.switchBailiwik}
                bailiwik={props.currentBailiwik}
                currentUser={props.currentUser}
                isGuest={props.isGuest}
                isMember={isMember}
            />

            {/* {isLoading && <div className="find-loading-container"><LoadingSpinner /></div>} */}
            {!isMember && !props.isGuest && (
                <JoinBailiwik
                    onJoin={handleJoin}
                    bailiwik={props.currentBailiwik}
                    toggleBottomNav={props.toggleBottomNav}
                />
            )}
            <Messages
                isGuest={props.isGuest}
                setMessageToDelete={setMessageToDelete}
                setMessageToEdit={setMessageToEdit}
                setMediaModalData={setMediaModalData}
                setMessageToReport={setMessageToReport}
                scrallableRef={scrallableRef}
                setCommentsMessage={setCommentsMessage}
                sendLike={sendLike}
                setProfile={setProfile}
                setShowProfile={setShowProfile}
                bailiwikId={props.currentBailiwik._id}
                currentBailiwik={props.currentBailiwik}
                currentUser={props.currentUser}
            />
            {isMember && (
                <div>
                    <MessageBox
                        inputHeight="36px"
                        onMedia={() => setMediaModalData(true)}
                        onSubmit={handleSubmit}
                    />
                </div>
            )}
            {CommentsModalMemo}
            <Toast ref={toastRef} />
            {showProfile && (
                <Profile
                    data={profile}
                    closeHandler={() => {
                        setShowProfile(false);
                    }}
                    isBailiff={false}
                    isHost={false}
                />
            )}
        </>
    );
};

const Messages = ({
    setMessageToDelete,
    setMessageToEdit,
    setMediaModalData,
    setMessageToReport,
    scrallableRef,
    setCommentsMessage,
    sendLike,
    setProfile,
    setShowProfile,
    bailiwikId,
    currentUser,
    currentBailiwik,
    isGuest,
}) => {
    const [page, setPage] = useState(0);
    const [modal, setModal] = useState({ open: false, data: {}, type: '' });
    const messages = useMessages(`bailiwik/${bailiwikId}/messages`, page);

    const {
        bailiwikStore: { membersDetails },
    } = useStores();
    const redeemDeal = useRedeemDeal();
    const eventCtaClick = useEventCtaClick();

    /**
     * How can we force InfiniteScroll to reset when a user has finished a REPOST?
     * Getting "scrollableDiv" and setting scrollTop is hacky, but does the job.
     *
     * @param {*} isReposting if true, reset InfiniteScroll to bottom.
     */
    const onModalClose = (isReposting) => {
        if (isReposting) {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                div.scrollTop = 0;
            }
        }
        setModal({ open: false, data: {}, type: '' });
    };

    const onEventDealEdit = (data) => {
        setModal({
            open: true,
            data,
            type: `create-edit-${data.type === 'EVENT' ? 'event' : 'deal'}`,
        });
    };

    const onEventDealRepost = (data) => {
        const isEvent = data.type === 'EVENT';
        const repostData = isEvent
            ? {
                  content: {
                      event: {
                          ...getRepostData(data.content.event),
                      },
                  },
              }
            : {
                  content: {
                      deal: {
                          ...getRepostData(data.content.deal),
                      },
                  },
              };
        setModal({
            open: true,
            data: repostData,
            type: `create-repost-${isEvent ? 'event' : 'deal'}`,
        });
    };

    const onEventDealDelete = (data) => {
        setModal({
            open: true,
            data,
            type: `delete-${data.type === 'EVENT' ? 'event' : 'deal'}`,
        });
    };

    return (
        <>
            <FirebaseMessages
                isGuest={isGuest}
                like={sendLike}
                remove={setMessageToDelete}
                editMessage={setMessageToEdit}
                editMedia={setMediaModalData}
                report={setMessageToReport}
                ref={scrallableRef}
                redeemDeal={(item) => redeemDeal(item)}
                messages={mapEventGuestUsers(messages, membersDetails)}
                setPage={setPage}
                toggleCommentModal={setCommentsMessage}
                showProfile={(data) => {
                    setProfile(data);
                    setShowProfile(true);
                }}
                currentUser={currentUser}
                eventCtaClick={(item, status) => eventCtaClick(item, status)}
                onEventDealEdit={onEventDealEdit}
                onEventDealRepost={onEventDealRepost}
                onEventDealDelete={onEventDealDelete}
            />
            {modal.open && modal.type === 'create-edit-deal' && (
                <DealModal
                    user={{ currentUser }}
                    bailiwik={currentBailiwik}
                    data={modal.data}
                    onClose={onModalClose}
                />
            )}
            {modal.open && modal.type === 'create-repost-deal' && (
                <DealModal
                    user={{ currentUser }}
                    bailiwik={currentBailiwik}
                    data={modal.data}
                    onClose={() => {
                        onModalClose(true);
                    }}
                />
            )}
            {modal.open && modal.type === 'delete-deal' && (
                <DeleteDealPopup data={modal.data} onClose={onModalClose} />
            )}
            {modal.open && modal.type === 'create-edit-event' && (
                <EventModal
                    user={{ currentUser }}
                    bailiwik={currentBailiwik}
                    data={modal.data}
                    onClose={onModalClose}
                />
            )}
            {modal.open && modal.type === 'create-repost-event' && (
                <EventModal
                    user={{ currentUser }}
                    bailiwik={currentBailiwik}
                    data={modal.data}
                    onClose={() => {
                        onModalClose(true);
                    }}
                />
            )}
            {modal.open && modal.type === 'delete-event' && (
                <DeleteEventPopup data={modal.data} onClose={onModalClose} />
            )}
        </>
    );
};

export default Chat;
