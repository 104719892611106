export default {
    clearIndicator: (p) => ({
      ...p,
      backgroundColor: 'red'
    }),
    container: (p) => ({
      ...p,
      borderRadius: '10px',
    }),
    control: (p) => ({
      ...p,
      border: '1px solid rgb(179, 179, 179) !important',
      boxShadow: 'none',
      borderRadius: '10px',
    }),
    dropdownIndicator: (p) => ({
      ...p,
      opacity: 0
    }),  
    indicatorSeparator: (p) => ({
      ...p,
      opacity: 0
    }),
    input: (p) => ({
      ...p,
      color: 'black',
      height: '42px'
    }),
    placeholder: (p) => ({
      ...p,
      color: '#9CA3AF',
    }),
    menu: (p) => ({
      ...p,
      width: 'calc(100%)',
      zIndex: '10000 !important'
    }),
    singleValue: (p) => ({
      ...p,
      color: 'black',

    }),
    option: (p) => ({
      ...p,
      color: 'black',
    }),
    
    }
  