import { handleSignOut } from '../../services/authService';

export default function useGuestJoin(bailiwik) {
    const guestJoin = () => {
        if (bailiwik) {
            // if the bailiwik exists, store it in sessionStorage
            window.sessionStorage.setItem(
                'guest-visit-bw',
                JSON.stringify(bailiwik)
            );
        }
        handleSignOut();
        window.location.href = window.location.origin + '/sign-in';
    };

    return guestJoin;
}
