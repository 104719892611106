import React from 'react';
import PropTypes from 'prop-types';

const map = {};
export const I_USER = 'I_USER';
map[I_USER] = {
    path:
        'M10.332 3.66732C10.332 5.50898 8.84036 7.00065 6.9987 7.00065C5.15703 7.00065 3.66536 5.50898 3.66536 3.66732C3.66536 1.82565 5.15703 0.333984 6.9987 0.333984C8.84036 0.333984 10.332 1.82565 10.332 3.66732ZM0.332031 12.0007C0.332031 9.78398 4.7737 8.66732 6.9987 8.66732C9.2237 8.66732 13.6654 9.78398 13.6654 12.0007V13.6673H0.332031V12.0007Z',
    width: 14,
    height: 14,
};
export const I_EMAIL = 'I_EMAIL';
map[I_EMAIL] = {
    path:
        'M21.3333 0.667969H2.66659C1.38325 0.667969 0.344919 1.71797 0.344919 3.0013L0.333252 17.0013C0.333252 18.2846 1.38325 19.3346 2.66659 19.3346H21.3333C22.6166 19.3346 23.6666 18.2846 23.6666 17.0013V3.0013C23.6666 1.71797 22.6166 0.667969 21.3333 0.667969ZM20.8666 5.6263L12.6183 10.783C12.2449 11.0163 11.7549 11.0163 11.3816 10.783L3.13325 5.6263C2.84159 5.43963 2.66659 5.12464 2.66659 4.7863C2.66659 4.00464 3.51825 3.53797 4.18325 3.9463L11.9999 8.83463L19.8166 3.9463C20.4816 3.53797 21.3333 4.00464 21.3333 4.7863C21.3333 5.12464 21.1583 5.43963 20.8666 5.6263Z',
    width: 24,
    height: 20,
};
export const I_OCCUPATION = 'I_OCCUPATION';
map[I_OCCUPATION] = {
    path:
        'M14.3333 4.9987V2.66536H9.66659V4.9987H14.3333ZM2.66659 8.4987V18.9987C2.66659 19.6404 3.19159 20.1654 3.83325 20.1654H20.1666C20.8083 20.1654 21.3333 19.6404 21.3333 18.9987V8.4987C21.3333 7.85703 20.8083 7.33203 20.1666 7.33203H3.83325C3.19159 7.33203 2.66659 7.85703 2.66659 8.4987ZM21.3333 4.9987C22.6283 4.9987 23.6666 6.03703 23.6666 7.33203V20.1654C23.6666 21.4604 22.6283 22.4987 21.3333 22.4987H2.66659C1.37159 22.4987 0.333252 21.4604 0.333252 20.1654L0.344919 7.33203C0.344919 6.03703 1.37159 4.9987 2.66659 4.9987H7.33325V2.66536C7.33325 1.37036 8.37159 0.332031 9.66659 0.332031H14.3333C15.6283 0.332031 16.6666 1.37036 16.6666 2.66536V4.9987H21.3333Z',
    width: 24,
    height: 23,
};
export const I_BIO = 'I_BIO';
map[I_BIO] = {
    path:
        'M11.9883 0.332031C5.54825 0.332031 0.333252 5.5587 0.333252 11.9987C0.333252 18.4387 5.54825 23.6654 11.9883 23.6654C18.4399 23.6654 23.6666 18.4387 23.6666 11.9987C23.6666 5.5587 18.4399 0.332031 11.9883 0.332031ZM11.9999 21.332C6.84325 21.332 2.66659 17.1554 2.66659 11.9987C2.66659 6.84203 6.84325 2.66536 11.9999 2.66536C17.1566 2.66536 21.3333 6.84203 21.3333 11.9987C21.3333 17.1554 17.1566 21.332 11.9999 21.332ZM16.0833 10.832C17.0516 10.832 17.8333 10.0504 17.8333 9.08203C17.8333 8.1137 17.0516 7.33203 16.0833 7.33203C15.1149 7.33203 14.3333 8.1137 14.3333 9.08203C14.3333 10.0504 15.1149 10.832 16.0833 10.832ZM7.91659 10.832C8.88492 10.832 9.66659 10.0504 9.66659 9.08203C9.66659 8.1137 8.88492 7.33203 7.91659 7.33203C6.94825 7.33203 6.16659 8.1137 6.16659 9.08203C6.16659 10.0504 6.94825 10.832 7.91659 10.832ZM11.9999 18.4154C14.7183 18.4154 17.0283 16.712 17.9616 14.332H6.03825C6.97159 16.712 9.28159 18.4154 11.9999 18.4154Z',
    width: 24,
    height: 24,
};
export const I_WEBSITE = 'I_WEBSITE';
map[I_WEBSITE] = {
    path:
        'M11.9883 0.332031C5.54825 0.332031 0.333252 5.5587 0.333252 11.9987C0.333252 18.4387 5.54825 23.6654 11.9883 23.6654C18.4399 23.6654 23.6666 18.4387 23.6666 11.9987C23.6666 5.5587 18.4399 0.332031 11.9883 0.332031ZM20.0733 7.33203H16.6316C16.2583 5.8737 15.7216 4.4737 15.0216 3.1787C17.1683 3.9137 18.9533 5.40703 20.0733 7.33203ZM11.9999 2.71203C12.9683 4.11203 13.7266 5.6637 14.2283 7.33203H9.77159C10.2733 5.6637 11.0316 4.11203 11.9999 2.71203ZM2.96992 14.332C2.78325 13.5854 2.66659 12.8037 2.66659 11.9987C2.66659 11.1937 2.78325 10.412 2.96992 9.66537H6.91325C6.81992 10.4354 6.74992 11.2054 6.74992 11.9987C6.74992 12.792 6.81992 13.562 6.91325 14.332H2.96992ZM3.92659 16.6654H7.36825C7.74159 18.1237 8.27825 19.5237 8.97825 20.8187C6.83159 20.0837 5.04659 18.602 3.92659 16.6654ZM7.36825 7.33203H3.92659C5.04659 5.39536 6.83159 3.9137 8.97825 3.1787C8.27825 4.4737 7.74159 5.8737 7.36825 7.33203ZM11.9999 21.2854C11.0316 19.8854 10.2733 18.3337 9.77159 16.6654H14.2283C13.7266 18.3337 12.9683 19.8854 11.9999 21.2854ZM14.7299 14.332H9.26992C9.16492 13.562 9.08325 12.792 9.08325 11.9987C9.08325 11.2054 9.16492 10.4237 9.26992 9.66537H14.7299C14.8349 10.4237 14.9166 11.2054 14.9166 11.9987C14.9166 12.792 14.8349 13.562 14.7299 14.332ZM15.0216 20.8187C15.7216 19.5237 16.2583 18.1237 16.6316 16.6654H20.0733C18.9533 18.5904 17.1683 20.0837 15.0216 20.8187ZM17.0866 14.332C17.1799 13.562 17.2499 12.792 17.2499 11.9987C17.2499 11.2054 17.1799 10.4354 17.0866 9.66537H21.0299C21.2166 10.412 21.3333 11.1937 21.3333 11.9987C21.3333 12.8037 21.2166 13.5854 21.0299 14.332H17.0866Z',
    width: 24,
    height: 24,
};
export const I_INTERESTS = 'I_INTERESTS';
map[I_INTERESTS] = {
    path:
        'M10.4484 20.0998C4.00844 14.3248 0.275104 11.1865 0.333437 6.81147C0.380104 3.34647 3.01677 1.6898 3.0751 1.64313C7.28677 -1.22687 11.1134 1.8998 12.0001 2.93813C12.8751 1.91147 16.6551 -1.15687 20.8201 1.5848C21.4268 1.98147 23.4218 3.5098 23.6434 6.39147C24.0168 11.3848 18.8134 15.4448 13.5518 20.1115C13.1084 20.5081 12.5484 20.6948 11.9884 20.6948C11.4401 20.6948 10.8918 20.4965 10.4484 20.0998Z',
    width: 24,
    height: 21,
};
export const I_PENCIL = 'I_PENCIL';
map[I_PENCIL] = {
    path:
        'M0.5 12.3751V15.5001H3.625L12.8417 6.28346L9.71667 3.15846L0.5 12.3751ZM15.2583 3.8668C15.5833 3.5418 15.5833 3.0168 15.2583 2.6918L13.3083 0.741797C12.9833 0.416797 12.4583 0.416797 12.1333 0.741797L10.6083 2.2668L13.7333 5.3918L15.2583 3.8668Z',
    width: 16,
    height: 16,
};
export const I_LOCK = 'I_LOCK';
map[I_LOCK] = {
    path:
        'M11.5 0.9375C7.75458 0.9375 4.71875 3.97333 4.71875 7.71875V9.65625H1.8125C1.27725 9.65625 0.84375 10.0898 0.84375 10.625V26.125C0.84375 26.6602 1.27725 27.0938 1.8125 27.0938H21.1875C21.7227 27.0938 22.1562 26.6602 22.1562 26.125V10.625C22.1562 10.0898 21.7227 9.65625 21.1875 9.65625H18.2812V7.71875C18.2812 3.97333 15.2454 0.9375 11.5 0.9375ZM16.3438 9.65625V7.71875C16.3438 5.04379 14.175 2.875 11.5 2.875C8.82504 2.875 6.65625 5.04379 6.65625 7.71875V9.65625H16.3438ZM11.5 14.5C10.4295 14.5 9.5625 15.367 9.5625 16.4375C9.5625 17.1544 9.95242 17.7804 10.5312 18.1159V20.3125H12.4688V18.1159C13.0476 17.7805 13.4375 17.1544 13.4375 16.4375C13.4375 15.367 12.5705 14.5 11.5 14.5Z',
    width: 23,
    height: 28,
};
export const I_CAKE = 'I_CAKE';
map[I_CAKE] = {
    path:
        'M19.1667 9.66641C19.1667 8.38307 18.1167 7.33307 16.8333 7.33307H12.1667V5.64141C12.3417 5.53641 12.505 5.43141 12.645 5.27974C13.1 4.82474 13.3333 4.20641 13.3333 3.62307C13.3333 3.03974 13.1 2.43307 12.645 1.97807L11.4083 0.741406C11.175 0.508073 10.8133 0.508073 10.58 0.741406L9.34333 1.97807C8.9 2.43307 8.66667 3.03974 8.66667 3.62307C8.66667 4.20641 8.9 4.82474 9.355 5.27974C9.50667 5.43141 9.67 5.53641 9.83333 5.64141V7.33307H5.16667C3.88333 7.33307 2.83333 8.38307 2.83333 9.66641V13.1664H19.1667V9.66641ZM19.1667 15.4997H2.83333C1.55 15.4997 0.5 16.5497 0.5 17.8331V22.4997C0.5 23.1414 1.025 23.6664 1.66667 23.6664H20.3333C20.975 23.6664 21.5 23.1414 21.5 22.4997V17.8331C21.5 16.5497 20.45 15.4997 19.1667 15.4997Z',
    width: 22,
    height: 24,
};
export const I_ARROW_R = 'I_ARROW_R';
map[I_ARROW_R] = {
    path:
        'M1.71255 0L0 1.85526L5.56275 7.89474L0 13.9342L1.71255 15.7895L9 7.89474L1.71255 0Z',
    width: 9,
    height: 16,
};

/**
 * Icon component
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Icon({
    name,
    fill = 'white',
    bgColor = 'none',
    onClick,
}) {
    const { width, height, path } = map[name];
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill={bgColor}
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />
        </svg>
    );
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    fill: PropTypes.string,
    bgColor: PropTypes.string,
    onClick: PropTypes.func,
};
