import { formatUser } from '../../utils';

const formatMessage = (data) => ({
    ...data.val(),
    _id: data.key,
    ref: data.ref,
    thread: data.val().thread || [],
    reports: data.val().reports || [],
    createdAt: data.val().createdAt * -1,
});

const createChatUseCase = (chatRepository) => {
    const getMessages = async (chatRef, lastItem, limit) => {
        if (!chatRef) {
            throw new Error('chatRef is required');
        }

        let messages = await chatRepository.getMessages(
            chatRef,
            lastItem,
            limit
        );

        messages = messages
            .filter(
                (message) =>
                    message.val().content.text ||
                    message.val().content.media ||
                    message.val().content.event ||
                    message.val().content.deal
            )
            .map((message) => formatMessage(message));

        return messages;
    };

    const sendMessage = (
        chatRef,
        type,
        content,
        user,
        { bailiwik, to } = {}
    ) => {
        // See #635 use 'to' (a MongoDB user id) in order to update unseenCount for
        // dm messages.
        if (!content || !chatRef || !user) {
            throw new Error('missing required fields!');
        }

        if (!['MEDIA', 'TEXT', 'DEAL', 'EVENT'].includes(type)) {
            throw new Error('unsupported message type');
        }

        if (type === 'MEDIA') {
            content.media.creator = formatUser(user);
        }

        const messageData = {
            thread: [],
            reports: [],
            creator: formatUser(user),
            type,
            content,
            likes: {
                count: 0,
                users: [],
            },
            createdAt: new Date().getTime() * -1,
            updatedAt: new Date().getTime() * -1,
        };

        if (bailiwik) {
            messageData.bailiwik = bailiwik;
        }
        console.log('logging to: ' + to);
        if (to) messageData.to = to;

        chatRepository.sendMessage(chatRef, messageData);

        return messageData;
    };

    const onChildAdded = (chatRef, callback) => {
        chatRepository.onChildAdded(chatRef, (child) => {
            callback((oldMessages) =>
                oldMessages.find((message) => message._id === child.key)
                    ? oldMessages
                    : [formatMessage(child), ...oldMessages]
            );
        });
    };

    const onChildChanged = (chatRef, callback) => {
        chatRepository.onChildChanged(chatRef, (child) => {
            callback((oldMessages) => {
                return oldMessages.map((message) => {
                    if (message._id === child.key) {
                        return formatMessage(child);
                    }

                    return message;
                });
            });
        });
    };

    const onChildRemoved = (chatRef, callback) => {
        chatRepository.onChildRemoved(chatRef, (child) => {
            callback((oldMessages) =>
                oldMessages.filter((message) => message._id !== child.key)
            );
        });
    };

    return {
        getMessages,
        sendMessage,
        onChildAdded,
        onChildChanged,
        onChildRemoved,
    };
};

export default createChatUseCase;
