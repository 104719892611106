import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useStores } from '../../lib/store';
import NavBar from '../navbar';

function ProtectedRoute({
    children,
    isLoggedIn,
    requireBailiwik = false,
    bottomNav = true,
    blockGuests,
    ...rest
}) {
    const location = useLocation();
    const { bailiwikStore, userStore } = useStores();

    if (blockGuests && userStore.isGuest) {
        return <Redirect to="/404" />;
    }

    if (
        isLoggedIn &&
        requireBailiwik &&
        !bailiwikStore.getBailiwikId &&
        userStore.bailiwiks.length === 0 &&
        !userStore.isGuest
    ) {
        return <Redirect to="/find-bailiwik" />;
    }
    return (
        <Route {...rest}>
            {bottomNav && (
                <div className="nav-bar-wrapper fixed-bottom">
                    <NavBar isGuest={userStore.isGuest} />
                </div>
            )}
            {!isLoggedIn && (
                <Redirect
                    to={{
                        pathname: '/sign-in',
                        state: { referrer: location },
                    }}
                />
            )}
            {isLoggedIn && children}
        </Route>
    );
}
export default observer(ProtectedRoute);

ProtectedRoute.propTypes = {
    children: PropTypes.node,
    isLoggedIn: PropTypes.bool.isRequired,
    requireBailiwik: PropTypes.bool,
};
