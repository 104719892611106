import { formatUser } from '../../utils';
import { useLogToAmplitude } from '../../core/utility/amplitude';
import { useStores } from '../../lib/store';

export default function useToggleLike() {
    const { bailiwikStore } = useStores();
    const logToAmplitude = useLogToAmplitude();
    const toggleLike = (item, user, callback) => {
        const formattedUser = formatUser(user);
        // For debugging, determine if type is event, deal, or other
        // const itemType = item.type.toLowerCase();

        item.ref.transaction(item => {
            // item should vanish if it does not exist
            if (!item) { // see #714
              if (callback) {
                callback({});
              }
              return;
            }
            const userId = user.currentUser._id;
            const currentBailiwik = bailiwikStore.current;
            const bailiwikId = currentBailiwik._id;
            const bailiwikName = currentBailiwik.name;
            const extras = item?.type ? { type: item?.type } : {};
            if (!item.likes) {
              item.likes = [];
            }
            if (item.likes.users && item.likes.users[userId]) {
              logToAmplitude(userId, bailiwikId, bailiwikName, 'Unlike', extras);
              item.likes.count--;
              item.likes.users[userId] = null;
            } else {
              logToAmplitude(userId, bailiwikId, bailiwikName, 'Like', extras);
              item.likes.count++;
              if (!item.likes.users) {
                  item.likes.users = {};
              }
              item.likes.users[userId] = formattedUser;
            }
            if (callback) {
              callback({
                ...item,
                likes: {
                  ...item.likes,
                  users: Object.values(item.likes.users).filter(user => user)
                }
              })
            }
            return item;
        });
    }

    return toggleLike;
}
