import React, { useEffect, useState } from 'react';

import {
    Route,
    Redirect,
    Switch,
    useHistory,
    useLocation,
} from 'react-router-dom';
import { useAsync } from 'react-use';
import { observer } from 'mobx-react';

import { ProfilePreviewProvider } from '../core/contexts/ProfilePreviewContext';
import DetectGuestWrapper from '../components/DetectGuestWrapper';

import ProtectedRoute from './common/ProtectedRoute';
import SignIn from './sign-in';
import SignInForm from './sign-in-form';
import SocialSignInForm from './sign-in-form-social';
import Bailiwiks from './bailiwiks';
import Chat from './chat/chat-firebase';
// import Chat from './chat/chat';
import DirectMessage from './DirectMessage';
import NoMatch from './routes/NoMatch';
import { useStores } from '../lib/store';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';
import SocialSignUp from './SocialSignUp';
import Flyer from './Flyer';

import '../views/styles.css';
import { getAuthToken, getLoggedIn } from '../services/deviceService';
import MemberDirectory from './MemberDirectory';
import Profile from './Profile';
import FindBailiwik from './FindBailiwik';
import Deal from './Deal';
import Event from './Event';
import useTracking from '../core/hooks/useTracking';
import { BASE_URL } from '../env';
import { isAuthTokenExpired } from '../services/authService';
import { getPageWithBailiwikIdFromURL } from '../helpers';

// const BASE_URL = "http://localhost:5000"; // Use during development
function App() {
    useTracking(process.env.GA_ID);
    const [chat, setChat] = useState([]);
    const [isNewChat, setIsNewChat] = useState(false);
    const [bailiwikId, setBailiwikId] = useState(getPageWithBailiwikIdFromURL()?.[1] || '');
    const [redirectTo, setRedirectTo] = useState(null);
    const [bottomNav, setBottomNav] = useState(true);
    const history = useHistory();
    const location = useLocation();

    const { userStore, bailiwikStore, chatStore } = useStores();
    let { isLoggedIn, currentUser, bailiwiks, isGuest } = userStore;
    let { current: currentBailiwik } = bailiwikStore;

    useEffect(() => {
        const isExpired = isAuthTokenExpired();
        if (isExpired === true) {
            console.log('Token is expired. Signing out...');
            history.push('/sign-in');
        }
    }, []);

    useEffect(() => {
        if (currentUser?._id && currentBailiwik?._id) {
            if (
                location.pathname.includes('/chat') ||
                location.pathname.includes('/events') ||
                location.pathname.includes('/deals') ||
                location.pathname.includes('/dm') ||
                location.pathname.includes('/members')
            ) {
                history.push(location.pathname);
            } else {
                history.push(`/chat/${currentBailiwik._id}`);
            }
        }
    }, [currentUser?._id]);

    const switchBailiwik = async (bailiwik, reload = false) => {
        if (currentBailiwik && currentBailiwik.chat !== bailiwik.chat) {
            setChat([]);
        }
        await bailiwikStore.switch(bailiwik);

        if(!reload){
            history.push(`/chat/${bailiwik?._id || bailiwik}`)
        }else{   
            window.location.href = `${window.location.origin}/chat/${bailiwik?._id || bailiwik}`;
        }
    };

    const { loading } = useAsync(async () => {
        const parsedLoggedIn = getLoggedIn();
        if (parsedLoggedIn) {
            const parsedChat = JSON.parse(localStorage.getItem('chat')) || [];
            setChat(parsedChat);

            // populate mobx stores
            await bailiwikStore.load(bailiwikId);
            await userStore.loadAllFromStorage('onload');
            await chatStore.loadAllFromStorage('onload');

            isLoggedIn = userStore.isLoggedIn;
            currentUser = userStore.currentUser;
            currentBailiwik = bailiwikStore.current;
            bailiwiks = userStore.bailiwiks;

            // if no bailiwikId is provided, use the first bailiwik and is logged in
            // go to the default bailiwik chat 
            if(isLoggedIn && !currentUser?.bailiwikId){
                setRedirectTo(`/chat/${process.env.GUEST_DEFAULT_BAILIWIK}`);
            }
          
        }
        appHeight();
    });

  
    // wait until bootstrapping finishes
    // @todo improve UX
    if (loading) {
        return null;
    }

    return (
        <DetectGuestWrapper>
            <ProfilePreviewProvider>
                <Switch>
                    <Route exact path="/">
                        {!isLoggedIn ? (
                            <SignIn
                                setBailiwikId={setBailiwikId}
                                setRedirectTo={setRedirectTo}
                            />
                        ) : (
                            redirectTo && <Redirect to={redirectTo} />
                        )}
                    </Route>
                    <Route exact path="/archive-sign-in">
                        {!isLoggedIn ? (
                            <SignInForm
                                setBailiwikId={setBailiwikId}
                                setRedirectTo={setRedirectTo}
                            />
                        ) : (
                            redirectTo && <Redirect to={redirectTo} />
                        )}
                    </Route>
                    <Route path="/archive-sign-up">
                        <SignUp />
                    </Route>
                    <Route exact path="/sign-in">
                        {!isLoggedIn ? (
                            <SocialSignInForm
                                setBailiwikId={setBailiwikId}
                                setRedirectTo={setRedirectTo}
                            />
                        ) : (
                            redirectTo && <Redirect to={redirectTo} />
                        )}
                    </Route>
                    <Route path="/sign-up">
                        <SocialSignUp />
                    </Route>
                    <Route
                        path="/terms-and-conditions"
                        component={() => {
                            window.location.href =
                                'https://www.bailiwik.com/wp-content/uploads/2020/12/Terms.of_.Use-2019-07-11.pdf';
                            return null;
                        }}
                    />
                    <Route path="/forgot-password">
                        <ForgotPassword setBailiwikId={setBailiwikId} />
                    </Route>
                    <ProtectedRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/home"
                        requireBailiwik
                    >
                        <Bailiwiks
                            currentUser={currentUser}
                            bailiwiks={bailiwiks}
                            switchBailiwik={switchBailiwik}
                            loggedIn={isLoggedIn}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute
                        exact
                        isLoggedIn={isLoggedIn}
                        path="/chat/:bailiwikId"
                        requireBailiwik
                        bottomNav={bottomNav}
                    >
                        <Chat
                            updateUserData={() => userStore.load()}
                            currentUser={currentUser}
                            isGuest={isGuest}
                            bailiwiks={bailiwiks}
                            switchBailiwik={switchBailiwik}
                            currentBailiwik={currentBailiwik}
                            chat={chat}
                            isNewChat={isNewChat}
                            toggleBottomNav={setBottomNav}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute
                        exact
                        path="/profile"
                        isLoggedIn={isLoggedIn}
                    >
                        <Profile />
                    </ProtectedRoute>
                    <ProtectedRoute
                        path="/find-bailiwik"
                        isLoggedIn={isLoggedIn}
                    >
                        <FindBailiwik
                            isPage
                            onClose={() => {
                                window.history.back();
                            }}
                            currentUser={currentUser}
                            bailiwiks={bailiwiks}
                            switchBailiwik={switchBailiwik}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute
                        path="/deals/:bailiwikId"
                        isLoggedIn={isLoggedIn}
                    >
                        <Deal
                            updateUserData={() => userStore.load()}
                            isGuest={isGuest}
                            currentUser={currentUser}
                            bailiwiks={bailiwiks}
                            switchBailiwik={switchBailiwik}
                            currentBailiwik={currentBailiwik}
                            toggleBottomNav={setBottomNav}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute
                        path="/events/:bailiwikId"
                        isLoggedIn={isLoggedIn}
                    >
                        <Event
                            updateUserData={() => userStore.load()}
                            isGuest={isGuest}
                            currentUser={currentUser}
                            bailiwiks={bailiwiks}
                            switchBailiwik={switchBailiwik}
                            currentBailiwik={currentBailiwik}
                            toggleBottomNav={setBottomNav}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute
                        path="/flyer"
                        isLoggedIn={isLoggedIn}
                        bottomNav={false}
                    >
                        <Flyer />
                    </ProtectedRoute>
                    <ProtectedRoute
                        path="/dm/:bailiwikId"
                        isLoggedIn={isLoggedIn}
                        requireBailiwik
                        blockGuests
                    >
                        <DirectMessage bailiwik={currentBailiwik} />
                    </ProtectedRoute>
                    <ProtectedRoute
                        path="/members/:bailiwikId"
                        isLoggedIn={isLoggedIn}
                        requireBailiwik
                        blockGuests
                    >
                        <MemberDirectory />
                    </ProtectedRoute>
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </ProfilePreviewProvider>
        </DetectGuestWrapper>
    );
}

export default observer(App);
