import React, { useEffect, useRef, useState } from 'react'
import Loading from '../../UI/loading-spinner'
import { getAuthToken } from '../../../services/deviceService'
import TextInput from '../../common/ChatToolbar/TextInput'
import { BASE_URL } from '../../../env'
import { useTranslation } from 'react-i18next';

import * as FilePond from 'filepond';
import { openEditor, processImage, createDefaultImageReader, createDefaultImageWriter, getEditorDefaults } from '@pqina/pintura';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster
);

import textareaStyles from './textareaStyles'
import './style.css'
import 'filepond/dist/filepond.min.css';
import '@pqina/pintura/pintura.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

const ImageUploaderModal = ({
    source,
    bailiwik, 
    onUpload, 
    onClose, 
    message,
    memberId, // if this is a DM, memberId is user to which dm is sent
}) => {
  console.log( 'ImageUploaderModal mounted source:', source );
  const [description, setDescription] = useState(message?.content?.media?.description || '');
  const [images, setImages] = useState([]);
  const [editedImages, setEditedImages] = useState([]);
  const [loading, setLoading] = useState(false);//show loader until FilePond is created
  const [aspectRatio, setAspectRatio] = useState('none');//'none' = not set, null = original image aspect ratio, '1:1', '4:5', '16:9';

  const [uploadLimit, setUploadLimit] = useState(4);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [aspectRatioString, setAspectRatioString] = useState('Original aspect ratio of the image will be used');
  const userToken = getAuthToken();
  const [ editFilesLoading, setEditFilesLoading ] = useState(false);
  let lockout;// only create FilePond instance once per component instance

  const containerRef = useRef()
  const { t } = useTranslation();
  
  const validateAspectRatio = (aspectRatio, deviation = 0.05) => {
    const predefinedAspectRatios = [1, 0.8, 1.77];
    const closestPredefined = predefinedAspectRatios.reduce((closest, current) => 
      Math.abs(current - aspectRatio) < Math.abs(closest - aspectRatio) ? current : closest
    );
    return Math.abs(closestPredefined - aspectRatio) <= deviation ? closestPredefined : null;
    // null is a valid value for FilePond, it means original aspect ratio of the image will be used
  }



    useEffect(() => {
      console.log('ImageUploaderModal useEffect message:', message);
    
      if (!message?.content?.media?.images || message?.content?.media?.images?.length < 0) { return }
      setEditFilesLoading(true);
      setLoading(true);
      
      // Create a promise for each image fetch request
      const fetchPromises = message.content.media.images.map((imageFilename) => {
        let imageUrl = `https://us-central1-bailiwik-mobile-app-dev.cloudfunctions.net/ImageFetch?fileKey=${imageFilename}`;
    
        console.log('fetch image:', imageFilename);
    
        return fetch(imageUrl)
          .then(response => response.blob())
          .then(blob => {
            // Create a file from the blob
            const file = new File([blob], imageFilename, { type: blob.type });
    
            // Create a URL for the file
            const fileUrl = URL.createObjectURL(file);
    
            // Create a new image object
            const img = new Image();
    
            // Return a promise that resolves with the file and the aspect ratio
            return new Promise((resolve, reject) => {
              img.onload = function() {
                // Calculate the aspect ratio
                const aspectRatio = this.width / this.height;
                const validatedAspectRatio = validateAspectRatio(aspectRatio);

                console.log('aspectRatio:', aspectRatio);
                // Resolve the promise with the file and the aspect ratio
                resolve({ 
                  file, 
                  aspectRatio: validatedAspectRatio
                });
              };
              img.onerror = function() {
                reject('Failed to load image');
              };
              img.src = fileUrl;
            });
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      });
    
      Promise.all(fetchPromises).then(results => {
        // Extract only file objects to pass to FilePond
        const files = results.map(result => result.file);

        // Get the first aspect ratio from the results
        let editAspectRatio = validateAspectRatio(results[0]?.aspectRatio || 'none');
        //note: if aspect ratio is not valid, it will be set to null, which means original aspect ratio of the image will be used
        //'none' means the aspect ration is not properly set yet and is not valid for FilePond
    
        console.log('files:', files);
        console.log('ImageUplaoderModal file example:', files[0]);
    
        setImages(files);
        setAspectRatio(editAspectRatio);
        setEditFilesLoading(false);
      });
        
    }, [message]);
    
  
  
  


  useEffect(() => {

    console.log('ImageUploaderModal useEffect images:', images);
    //const jsonArray = JSON.stringify(images);
    //console.log('ImageUploaderModal useEffect images JSON:', jsonArray);

  }, [images]);

  useEffect(() => {

    console.log('ImageUploaderModal editedImages:', editedImages);
    //const editedJsonArray = JSON.stringify(editedImages);
    //console.log('ImageUploaderModal editedImages:', editedJsonArray );

  }, [editedImages]);







  const postMessage = async () => {
    console.log('postMessage submit start');

    if (!editedImages.length && !images.length) {

      window.alert('Please select an image to post');
      return;
    }

    const formData = new FormData();
    let postDescription = description.trim() || "";
      
    formData.append('media[bailiwik]', bailiwik._id);

    if (postDescription.length > 0) {
      formData.append('media[description]', postDescription);
    }
    
    let files = editedImages.length > 0 ? editedImages : images;
    
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      console.log('postMessage file:', file);
      formData.append('images', file, 'image.jpg');
    }

      const req = {
        method: 'POST',
        headers: {
          Authorization: `Token ${userToken}`
        },
        body: formData
      };
        
      setLoading(true);
        
    try {
      console.log('postMessage sending POST file upload req:', req);

      const resultJson = await fetch(`${BASE_URL}/api/media/v2/`, req)
          .then(response => response.json())
          .catch(error => console.error('postMessage fetch POST media/v2 error:', error));

      console.log('postMessage POST file upload resultJson:', resultJson);
      
      setLoading(false);

        const media = { ...resultJson.media, description };
        if (memberId && typeof memberId == 'string') {
          media.to = { _id: memberId };
        }

      
      onUpload(media);
      
      } catch (error) {
        console.error('postMessage Error posting message:', error);
        setLoading(false);
      }
    };
    

  
  
  
  
  
  //TODO: after finalizing all editing, reduce new/edit down to one function
  const editMessage = async () => {
    console.log('editMessage submit start');
      const formData = new FormData();
      let editedDescription = description.trim() || "";//allow a user to remove a description
      //I'm not going to require a description if it's not required for a new post

      //formData.append('media[title]', bailiwik._id);
        formData.append('media[bailiwik]', bailiwik._id);
        formData.append('media[description]', editedDescription);
    
        let files = editedImages.length > 0 ? editedImages : images;
    
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          console.log('postMessage file:', file);
          formData.append('images', file, 'image.jpg');
        }
      
        const req = {
            method: 'POST',
            headers: {
                Authorization: `Token ${userToken}`
            },
            body: formData
        };
    
        setLoading(true);

      try {
        console.log('editMessage sending POST file upload req:', req);
        
        const resultJson = await fetch(`${BASE_URL}/api/media/v2/`, req)
        .then(response => response.json())
        .catch(error => console.error('editMessage fetch POST media/v2 error:', error));

        console.log( 'editMessage POST file upload resultJson:', resultJson );
        console.log( 'editMessage POST file upload resultJson.media:', resultJson.media );

        setLoading(false);


        
        onUpload({ ...resultJson.media, description });

        } catch (error) {
        console.error('editMessage Error posting message:', error);
        setLoading(false);
      }
    }

  
  
  
  
  
  
  
  
  
  

  const handleClickOutside = (event) => {
    //console.log('handleClickOutside', event.target, containerRef.current);
    if (containerRef && !containerRef.current.contains(event.target)) {
         //console.log( 'handleClickOutside container' );
            //onClose()
        }
    }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
   console.log('aspectRatioString:', aspectRatioString);
   console.log('aspectRatio:', aspectRatio);
  }, [aspectRatio,aspectRatioString]);


  const handleAspectRatioSelection = (ratio) => { 
    console.log('handleAspectRatioSelection ratio:', ratio);

    if (ratio != null) {
      //a value of null means original image aspect ratio
      console.log('aspectRatio not null ratio:', ratio);
      if (ratio == 1) {
        setAspectRatioString('Using a square aspect ratio 1:1');
      } 
      if (ratio < 1) {
        setAspectRatioString( 'Using a tall aspect ratio 4:5');
      } 
      if (ratio > 1) {
        setAspectRatioString('Using a wide aspect ratio 16:9');
      }
    }
    console.log('handleAspectRatioSelection:', aspectRatioString);
    if ( ratio == null ) {
      setUploadLimit(1); //defaults to 4 if not null
    }
    setAspectRatio(ratio);
  }







  useEffect(() => {

    console.log('useEffect changed aspectRatio:', aspectRatio);
    if ( editFilesLoading || aspectRatio == 'none' || lockout ) {
      console.log('useEffect aspectRatio == none, returning');
      return;
    }
    lockout = true; //only create FilePond once

    console.log('init pond at aspectRatio:', aspectRatio);
    let pond;

    const mql = window.matchMedia('(max-width: 600px)');
    let filePosterMaxHeight = mql.matches ? 130 : 300;
    const screenTest = e => {
      filePosterMaxHeight = e.matches ? 130 : 300;
      if (pond) {
        console.log('screenTest updating filePosterMaxHeight:', filePosterMaxHeight);
        pond.setOptions({
          filePosterMaxHeight
        });
      }
    };
    mql.addEventListener(screenTest);
    
    console.log('index FilePond.create');
    setLoading(false);

    pond = FilePond.create(document.querySelector('.uploader-content'), {
    /* FilePond core properties */
    filePosterMaxHeight,
    files: images,
    allowMultiple: true,
    allowReorder: true,
    maxFiles: uploadLimit,
    checkValidity: true,
    /* drag and drop */
      dropValidation: true,
      stylePanelAspectRatio: 0.75,//3:4 ratio

    onupdatefiles: (fileItems) => {
      console.log('FilePond onupdatefiles fileItems:', fileItems );

      setImages( fileItems );
      },
    onremovefile: (error, file) => {
      console.log('FilePond onremovefile error:', error);
      console.log('FilePond onremovefile file:', file);
      
      setEditedImages(prevImages => {
        // Filter the array to exclude the file that was removed
        const updatedImages = prevImages.filter(image => image.id !== file.id);
        return updatedImages;
      });

    },
    onreorderfiles(files, origin, target) {
     console.log('FilePond onreorderfiles files:', files);
     console.log('FilePond onreorderfiles origin index:', origin);
     console.log('FilePond onreorderfiles target index:', target);
    
      // Create a new array of ids based on the order of files
      const newOrderIds = files.map(fileItem => fileItem.id);
    
      setEditedImages((prevImages) => {
        // Create a new array based on the newOrderIds
        const reorderedImages = newOrderIds.map(id => {
          // Find the image with the matching id
          const matchingImage = prevImages.find(image => image.id === id);
          return matchingImage;
        });
    
        return reorderedImages;
      });
    },
      onpreparefile(fileItem, output) {
      console.log('FilePond onpreparefile fileItem:', fileItem );
      const file = fileItem.file; // get the actual File object
     //console.log('FilePond onpreparefile starting file:');
     //console.log(file);
    
     //console.log('FilePond onpreparefile before setEditedImages with output:');
     //console.log(output);
      
      const updatedImage = new File([output], file.name, { type: file.type });
      
      // Add the FilePond id as a property on the updatedImage
      updatedImage.id = fileItem.id;
      
     //console.log('FilePond onpreparefile updatedImage:');
     //console.log(updatedImage);
    
      setEditedImages((prevImages) => {
        const imageExists = prevImages.some((image) => image.id === fileItem.id);
        if (imageExists) {
          const updatedImages = prevImages.map((image) => {
            if (image.id === fileItem.id) {
              return updatedImage;
            }
            return image;
          });
          return updatedImages;
        } else {
          return [...prevImages, updatedImage];
        }
      });
    },
    
    /* Image Editor plugin properties */
    imageEditor: {
      // Maps legacy data objects to new imageState objects (optional)
      // not sure if this even exists in the filepond anymore
      // legacyDataToImageState: legacyDataToImageState,

      createEditor: openEditor,
      imageReader: [createDefaultImageReader],
      imageWriter: [
        createDefaultImageWriter,
        {
          targetSize: {
            width: 1000,
            height: 1000,
            fit: 'cover',
          },
        },
      ],
      imageProcessor: processImage,
      editorOptions: {
        ...getEditorDefaults(),
        imageCropAspectRatio: aspectRatio,//null(original images ratio), 1, 0.8, 1:777;

        /*
        crop: (rect, size) => {
          console.log('crop rect:', rect);
          const currentAspectRatio = rect.width / rect.height;
          
          if (currentAspectRatio > 1.777) {
            rect.width = rect.height * 1.777;
          } else if (currentAspectRatio < 0.5625) {
            rect.height = rect.width / 0.5625;
          }
      
          console.log('crop rect updated:', rect);
          // Now apply the constrained rect values
          return {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height
          };
        },
//*/
      },
    },
  });

  // Cleanup the FilePond instance when the component unmounts
      return () => {
        console.log('index FilePond.destroy');
        mql.removeEventListener(screenTest);
        pond.destroy();
      };
}, [aspectRatio, editFilesLoading]);


  
  
  
  
  
  
  
  
  
    return (
        <>
          <div className="image-uploader-container">
            <div
            ref={containerRef}
            className="uploader-content-wrapper"
            >
              <div className="uploader-modal-close">
                <button onClick={() => onClose()}>
                  <img src={'/images/icons/close.png'} />
                </button>
              </div>
              <div className="uploader-headline-wrapper">
                <p>
                  {!message.content ? 'Create' : 'Edit'} Post
                </p>
              </div>
            {aspectRatio != 'none' &&  <> 
              <input className="uploader-content"
                type="file"
                multiple
              />
              <div className="aspect-ratio-note">
                {aspectRatioString}
              </div>
              </>
            }
            {aspectRatio == 'none' && !message.content &&
              <>
              <div className="aspectRatioSelector">
                <div
                  className="upload-ratio-cell ar-original"
                  onClick={() => handleAspectRatioSelection(null)}
                >
                  <div className="ar-outer-wrap">
                    <div className="ar-label-text">
                      Free-Form
                    </div>
                    <div className="ar-icon-container-stack">
                      <div className="ar-icon-square"></div>
                      <div className="ar-icon-tall"></div>
                      <div className="ar-icon-wide"></div>
                    </div>
                  </div>
                  <div className="uploader-note">*only for a single image</div>
                </div>
                <div
                  className="upload-ratio-cell ar-square"
                  onClick={() => handleAspectRatioSelection(1)}
                >
                  <div className="ar-outer-wrap">
                    <div className="ar-label-text">
                      1:1
                    </div>
                    <div className="ar-icon-container">
                      <div className="ar-icon-square"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="upload-ratio-cell ar-tall"
                  onClick={() => handleAspectRatioSelection(0.8)}
                >
                  <div className="ar-outer-wrap">
                    <div className="ar-label-text">
                      4:5
                    </div>
                    <div className="ar-icon-container">
                      <div className="ar-icon-tall"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="upload-ratio-cell ar-wide"
                  onClick={() => handleAspectRatioSelection(1.777)}
                  >
                    <div className="ar-outer-wrap">
                      <div className="ar-lable-text">
                        16:9
                      </div>
                      <div className="ar-icon-container">
                        <div className="ar-icon-wide"></div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="aspect-ratio-selector-instructions">
                Please start by selecting an aspect ratio for your images
                </div>
              </>
            }
            {loading && <div className="uploader-image-loading">
                <Loading />   
            </div>}
             {editFilesLoading && <div>Loading images for Editing</div>}
                                  <div className="uploader-comment-wrapper">
                                  <TextInput
                                      style={textareaStyles}
                                      className="message-input message-input-media-post"
                                      text={description}
                                      onChange={setDescription}
                                      onEnter={() => { }}
                                      placeholder={t('Say Something ellipsis')} />
                                  <button onClick={() =>loading 
                                          ? false
                                          // eslint-disable-next-line no-extra-boolean-cast
                                          : !!(message.content) 
                                          ? editMessage() 
                                          : postMessage()}>
                                      POST
                                  </button>
                              </div>

                </div>
            </div>
        </>
    )
}
export default ImageUploaderModal
