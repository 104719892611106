// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3cQ9zHYU72L2A5eD-yHNTf::-moz-placeholder {\n  font-size: inherit !important;\n  opacity: 50%;\n}\n._3cQ9zHYU72L2A5eD-yHNTf:-ms-input-placeholder {\n  font-size: inherit !important;\n  opacity: 50%;\n}\n._3cQ9zHYU72L2A5eD-yHNTf::placeholder {\n  font-size: inherit !important;\n  opacity: 50%;\n}\n", "",{"version":3,"sources":["webpack://./client/components/common/MembersList/assets/styles.module.css"],"names":[],"mappings":"AAAA;EACI,6BAA6B;EAC7B,YAAY;AAChB;AAHA;EACI,6BAA6B;EAC7B,YAAY;AAChB;AAHA;EACI,6BAA6B;EAC7B,YAAY;AAChB","sourcesContent":[".searchInput::placeholder {\n    font-size: inherit !important;\n    opacity: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "_3cQ9zHYU72L2A5eD-yHNTf"
};
export default ___CSS_LOADER_EXPORT___;
