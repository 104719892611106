import React, { useState } from 'react';
import FirebaseMessages from '../FirebaseChat';
import useMessages from '../../../core/hooks/useMessages';

export default function Messages({
    id,
    setCommentToDelete,
    setCommentToEdit,
    currentUser,
    like,
}) {
    const [page, setPage] = useState(0);
    const messages = useMessages(`thread/${id}/messages`, page, null); // null means no limitatios for comments 

    return (
        <FirebaseMessages
            messages={messages}
            isThread={true}
            remove={(comment) => setCommentToDelete(comment)}
            editMessage={(comment) => setCommentToEdit(comment) }
            like={like}
            currentUser={currentUser}
            setPage={setPage} />
    )
}
