import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MessageListItem from './components/MessageListItem';
import { getStoredNotifications } from '../../../../services/deviceService';
import useConversations from '../../../../core/hooks/useConversations'

function SearchMemberDirectoryIcon() {
    return (
        <svg
            width="31"
            height="20"
            viewBox="0 0 31 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8391 4.31666C13.7159 5.49408 12.2468 7.15668 12.8624 8.56744C13.478 9.9782 16.3759 11.5809 17.4965 10.4062C18.6172 9.23154 19.1444 7.54744 18.5806 6.25542C18.0168 4.96341 15.9624 3.13923 14.8391 4.31666ZM19.8428 12.9215H10.9239L9.25427 19.461H20.9555L19.8428 12.9215Z"
                fill="white"
                fillOpacity="0.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5051 0.379156C23.3818 1.55658 21.9127 3.21918 22.5283 4.62994C23.144 6.0407 26.0418 7.64343 27.1625 6.46874C28.2832 5.29404 28.8104 3.60994 28.2465 2.31792C27.6827 1.02591 25.6284 -0.79827 24.5051 0.379156ZM29.5087 8.98395H20.5899L19.799 11.8791H20.7312L21.6099 15.5235H30.6214L29.5087 8.98395Z"
                fill="white"
                fillOpacity="0.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.05191 0.379156C4.92862 1.55658 3.45952 3.21918 4.07516 4.62994C4.69079 6.0407 7.58864 7.64343 8.70931 6.46874C9.82999 5.29404 10.3572 3.60994 9.79335 2.31792C9.22954 1.02591 7.17519 -0.79827 6.05191 0.379156ZM11.0556 8.98395H2.13667L0.467041 15.5235H9.25429L10.0415 11.8642H11.0556V8.98395Z"
                fill="white"
                fillOpacity="0.5"
            />
        </svg>
    );
}

export default function MessageList({ myId }) {
    // get stored notifications on every render
    // because parent component is listening for incomming notifications
    // and store the notifications in storage, which results in rerendering
    const notifications = useConversations(myId);
    // const notifications = getStoredNotifications();

  //const allRouteInfo = useRouteMatch();
  //console.log('allRouteInfo', allRouteInfo);

  const { params } = useRouteMatch();

    return (
        <div>
            <Helmet>
                <title>Direct Messages</title>
            </Helmet>
            <div className="tw-py-5 tw-px-2 tw-border-b tw-border-gray-500">
                <Link
                    to={`/members/${params.bailiwikId}`}
                    className="tw-flex tw-bg-orange-chat tw-px-6 tw-rounded-md tw-text-white tw-text-sm tw-space-x-2 tw-justify-center tw-tracking-wide tw-py-4"
                    data-test-id="lnk-member-directory"
                >
                    <div className="tw-w-8">
                        <SearchMemberDirectoryIcon />
                    </div>
                    <div>SEARCH MEMBER DIRECTORY</div>
                </Link>
            </div>
            <div className="tw-border-b tw-border-gray-600">
                {notifications.length > 0 &&
                    notifications.map((notification, idx) => (
                        <MessageListItem
                            key={idx}
                            notification={notification}
                            myId={myId}
                        />
                    ))}
            </div>
            <div className="tw-flex tw-justify-center tw-content-center tw-p-8 tw-text-xs tw-text-gray-300 tw-text-center">
                Tip: Message new members through the Members Directory
            </div>
        </div>
    );
}

MessageList.propTypes = {
    myId: PropTypes.string.isRequired,
};
