import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Chat from './Chat';
import MessageList from './components/MessageList';
import { useStores } from '../../lib/store';
import {
    getCurrentChatId,
    getStoredNotifications,
    setStoredNotifications,
} from '../../services/deviceService';
import MemberDirectory from './components/MemberDirectory';
import HeaderFooterLayout from '../common/HeaderFooterLayout';

function DirectMessage({ bailiwik }) {
    const { path } = useRouteMatch();
    const { userStore, bailiwikStore } = useStores();
    const [notifications, setNotifications] = useState([]);
    const latestNotifications = useRef(notifications);

    const updateLatestNotifications = (n11s) => {
        setNotifications(n11s);
        latestNotifications.current = n11s;
    };

    // is currently on DM page?
    const isOnDMPage = () => {
        // don't use location from react hook
        // it leads to stale values because of notificationsHandler closure
        return /^\/dm\/.+/.test(window.location.pathname);
    };

    useEffect(() => {
        updateLatestNotifications(getStoredNotifications());
    }, []);

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <HeaderFooterLayout title="Messages">
                        <MessageList myId={userStore.getUserId} />
                    </HeaderFooterLayout>
                </Route>
                <Route exact path={`${path}/members/:bailiwikId`}>
                    <HeaderFooterLayout title="Members" backButton>
                        <MemberDirectory />
                    </HeaderFooterLayout>
                </Route>
                {/* <Route path={`${path}/:memberId`}>
                    <Chat />
                </Route> */}
                <Route path={`${path}/:memberId/:conversationId`}>
                    <Chat bailiwik={bailiwik} />
                </Route>
            </Switch>
        </>
    );
}

export default observer(DirectMessage);
