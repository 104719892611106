import React from 'react';
import BailiwikAvatar from './bailiwik-avatar';
import { Link } from 'react-router-dom';

const BailiwiksListItem = (props) => {
    function handleBailiwikClick() {
        props.switchBailiwik(props.bailiwik);
    }

    return (
        <Link
            to="/chat"
            className="col-12 d-flex mt-3 p-0 align-items-center bailiwik-font switch-hover"
            onClick={handleBailiwikClick}
        >
            <BailiwikAvatar bailiwik={props.bailiwik} />
            <div className="ml-2">
                {props.bailiwik.name}
                {props.bailiwik.exclusive === true && (
                    <span className="tw-w-4 tw-inline-block tw-ml-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="2 1 16 17"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                )}
            </div>
        </Link>
    );
};

export default BailiwiksListItem;
