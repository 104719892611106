import { useCallback, useMemo, useState } from 'react';
import { getAuthToken } from '../../services/deviceService';
import { useStores } from '../../lib/store';
import { BASE_URL } from '../../env';

// const BASE_URL = "http://localhost:5000"; // Use during development
// const BASE_URL = 'https://staging.bailiwik.com'; // Use for production

const useJoinBailiwik = () => {
    const [loading, setLoading] = useState(false);
    const {
        userStore: { currentUser },
    } = useStores();
    const token = getAuthToken();

    const requestData = useMemo(
        () => ({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        }),
        [token]
    );

    const joinBailiwik = useCallback(
        (bailiwik) => {
            const bailiwikId = bailiwik?._id;
            requestData.body = JSON.stringify({});
            setLoading(true);

            return fetch(
                `${BASE_URL}/api/bailiwiks/${bailiwikId}/join`,
                requestData
            )
                .then((res) => {
                    if (res && res.bailiwik) {
                        logToAmplitude(
                            currentUser?._id,
                            bailiwikId,
                            bailiwik?.name,
                            'Join'
                        );
                    }
                    return res.json();
                })
                .catch((e) => console.log(e))
                .finally(() => setLoading(false));
        },
        [requestData]
    );
    return { joinBailiwik, loading };
};

export default useJoinBailiwik;
