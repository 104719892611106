import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Linkify from 'react-linkify';

import { sanitize, getImageUrl } from '../lib/utils';
import MessageContent from './MessageContent';

class MediaPost extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toolbar: false,
        };

        this.actionsRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.toolbar === false && this.state.toolbar === true)
            document.addEventListener('mousedown', this.handleClickOutside);
        if (prevState.toolbar === true && this.state.toolbar === false)
            document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (
            this.actionsRef &&
            !this.actionsRef.current.contains(event.target)
        ) {
            this.setState((prev) => ({ ...prev, toolbar: !prev.toolbar }));
        }
    }

    convertDbTime(timeStamp) {
        const months = [
            'JAN.',
            'FEB.',
            'MAR.',
            'APR.',
            'MAY.',
            'JUN.',
            'JUL.',
            'AUG.',
            'SEP.',
            'OCT.',
            'NOV.',
            'DEC.',
        ];
        const timeArr = timeStamp.split('T');
        const dateArr = timeArr[0].split('-');
        const month = Number(dateArr[1]) - 1;
        const day = dateArr[2];
        return months[month] + ' ' + day;
    }

    render() {
        const avatar = '/images/icons/person.png';
        const moreThan1 = this.props.post?.content?.media?.images?.length > 1;
        const isUserGuest =
            this.props.currentUser.email === process.env.GUEST_EMAIL;

        return (
            <div className="d-flex justify-content-center tw-max-w-2xl tw-mx-auto">
                <div className="col-12 p-0">
                    <div className="card mt-4 border-0 card-shadow">
                        {typeof this.props.post.content.media.images !==
                            'undefined' && (
                            <Swiper
                                {...{
                                    loop: moreThan1,
                                    pagination: moreThan1
                                        ? {
                                              el: '.swiper-pagination',
                                              type: 'bullets',
                                              clickable: true,
                                          }
                                        : false,
                                    navigation: moreThan1
                                        ? {
                                              nextEl: '.swiper-button-next',
                                              prevEl: '.swiper-button-prev',
                                          }
                        : false,
                      shouldSwiperUpdate: true,
                      rebuildOnUpdate: true,
                                }}
                            >
                                {this.props.post.content.media.images.map(
                                    (image) => (
                                        <img
                                            key={image}
                                            className="card-img-top w-100 h-100"
                                            src={
                                                'https://bailiwik-dev.s3.amazonaws.com/' +
                                                image
                                            }
                                            alt="Card image cap"
                                        />
                                    )
                                )}
                            </Swiper>
                        )}
                        <div className="text-dark pb-0 card-bottom-section">
                            <div className="media-post-author tw-items-start">
                                <div className="MEDIA container text-left">
                                    <div className="row">
                                        <div className="col-sm-2 col-2 pl-0 pr-0 d-flex justify-content-center align-items-center">
                                            <img
                                                src={getImageUrl(this.props.post?.creator?.avatar) || avatar}
                                                alt="user-avatar"
                                                className={
                                                    !this.props.post.creator?.avatar
                                                        ? 'avatar-media border border-secondary'
                                                        : 'avatar-media'
                                                }
                                                onClick={() =>
                                                    !isUserGuest &&
                                                    this.props.showProfile(
                                                        this.props.post?.creator
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-6 col-6 pl-0 pr-0 d-flex justify-content-left align-items-center">
                                            <div className="d-flex justify-content-center align-items-center media-description-container">
                                                <div>
                                                    {this.props.post.creator && (
                                                        <p className="media-heading mb-0">
                                                            <span className="text-uppercase opacity-text">
                                                                {this.props.post.content
                                                                    .media?.creator
                                                                    ?.fullName ||
                                                                    this.props.post.content
                                                                        .media?.creator
                                                                        ?.displayName}
                                                            {' '}
                                                            -{' '}
                                                            
                                                            {this.convertDbTime(
                                                                this.props.post.createdAt ||
                                                                    this.props.post
                                                                        .updatedAt
                                                            )}
                                                            </span>
                                                            {this.props.post.content.media
                                                                .edited ? (
                                                                <span className="tw-tracking-wider apacity-text edited-text">
                                                                    (EDITED)
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}

                                                        </p>
                                                    )}
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-sm-3 col-3 pl-0 d-flex justify-content-end align-items-center pr-0">
                                            <div className='media-column'>
                                              <p className="card-type">MEDIA</p>
                                            </div>
                                        </div>
                                        <div className="col-1 col-sm-1 pl-0 d-flex justify-content-center align-items-center pr-0">
                                            <div
                                                ref={this.actionsRef}
                                                onClick={() =>
                                                    !isUserGuest &&
                                                    this.setState((prev) => ({
                                                        ...prev,
                                                        toolbar: !prev.toolbar,
                                                    }))
                                                }
                                                style={{ textAlign: 'end', zIndex: 1000 }}
                                                className="media-post-actions-wrapper tw-flex-shrink-0"
                                            >
                                                {this.state.toolbar && (
                                                    <div className="media-post-actions">
                                                        <ul>
                                                            {this.props.edit && (
                                                                <li
                                                                    onClick={() =>
                                                                        !isUserGuest &&
                                                                        this.props.edit()
                                                                    }
                                                                >
                                                                    Edit
                                                                </li>
                                                            )}
                                                            {this.props.remove && (
                                                                <li
                                                                    onClick={
                                                                        !isUserGuest
                                                                            ? this.props
                                                                                .remove
                                                                            : () => {}
                                                                    }
                                                                >
                                                                    Delete
                                                                </li>
                                                            )}
                                                            {this.props.report && (
                                                                <li
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (!isUserGuest) {
                                                                            this.props.report(this.props.post);
                                                                        }
                                                                    }}
                                                                >
                                                                    Report
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                                <i className="fas fa-ellipsis-v media-more"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="media-description">
                                            <MessageContent
                                                showProfile={
                                                    !MessageContent &&
                                                    this.props.showProfile
                                                }
                                                text={sanitize(
                                                    this.props.post.content
                                                    .media.description
                                                )}
                                            />
                                            </p>
                                        </div>
                                    </div>
                                </div>            
                            </div>
                            {this.props.threads.length > 0 && (
                                <>
                                    <div className="media-post-comment-wrapper">
                                        <div className="media-post-comment">
                                            {!this.props.inThread && (
                                                <Comments
                                                    isUserGuest={isUserGuest}
                                                    showProfile={
                                                        this.props.showProfile
                                                    }
                                                    comment={this.props.comment}
                                                    thread={this.props.threads}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="col-12 p-0 media-post-actions-wrapper">
                                <div className="media-card-actions-container tw-flex">
                                    <div className="card-likers-img">
                                        {this.props.post.likes.users.map(
                                            (user) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <div
                                                    key={user._id}
                                                    className="card-likers"
                                                >
                                                    <img
                                                        src={
                                                            getImageUrl(user.avatar) || avatar
                                                        }
                                                        onClick={() =>
                                                            !isUserGuest &&
                                                            this.props.showProfile(
                                                                user
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="card-likes tw-flex-grow tw-w-full">
                                        <div>
                                            {this.props.post.likes.count >
                                                0 && (
                                                <p className="card-likes-count">
                                                    {
                                                        this.props.post.likes
                                                            ?.users[0]?.fullName
                                                    }{' '}
                                                    {
                                                        this.props.post.likes
                                                            ?.users[2]?.fullName
                                                    }{' '}
                                                    {this.props.post.likes
                                                        .count - 1
                                                        ? '& others'
                                                        : ''}{' '}
                                                    liked this
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-actions tw-flex-shrink-0">
                                        <div className="actions-wrapper">
                                            <div
                                                onClick={
                                                    !isUserGuest
                                                        ? this.props.like
                                                        : () => {}
                                                }
                                                className="media-btn-container"
                                            >
                                                <img
                                                    src={`/images/icons/${
                                                        this.props.post.likes.users.find(
                                                            (user) =>
                                                                user._id ===
                                                                this.props
                                                                    .currentUser
                                                                    ._id
                                                        )
                                                            ? 'like-theme'
                                                            : 'thumbs-up'
                                                    }.png`}
                                                    alt="like-btn"
                                                    className="media-like mr-3"
                                                />
                                                {this.props.post.likes.count ? (
                                                    <div className="media-display-num">
                                                        {
                                                            this.props.post
                                                                .likes.count
                                                        }
                                                    </div>
                                                ) : null}
                                            </div>
                                            {!this.props.inThread && (
                                                <div
                                                    onClick={() =>
                                                        !isUserGuest &&
                                                        this.props.comment(
                                                            this.props.post
                                                        )
                                                    }
                                                    className="media-btn-container"
                                                >
                                                    <img
                                                        src="/images/icons/thread.png"
                                                        alt="comment-btn"
                                                        className="media-comment"
                                                    />
                                                    {this.props.threads
                                                        .length ? (
                                                        <div className="media-display-num">
                                                            {
                                                                this.props
                                                                    .threads
                                                                    .length
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const Comments = (props) => {
    const thread = props.thread;
    const lastComment = thread[thread.length - 1];
    const commentCreator = lastComment?.creator?.fullName;
    const commentText = lastComment.content.text;
    const isUserGuest = props.isUserGuest;

    return (
        <>
            {thread.length > 1 && (
                <div
                    onClick={!isUserGuest ? props.comment : () => {}}
                    className="col-12 my-2 p-0 view-comments card-comment-headline"
                >
                    VIEW {thread.length} COMMENTS
                </div>
            )}
            <div className="col-12 p-0 mb-2 mt-3 comment-preview">
                <span className="comment-name">{commentCreator}: </span>
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="tw-underline"
                            href={decoratedHref}
                            key={key}
                        >
                            {decoratedText}
                        </a>
                    )}
                >
                    <MessageContent
                        showProfile={
                            !isUserGuest ? props.showProfile : () => {}
                        }
                        text={sanitize(commentText)}
                    />
                </Linkify>
            </div>
        </>
    );
};

export default MediaPost;
