import { useLocation } from 'react-router-dom';

/**
 * Custom hook to get the URL query parameters
 * 
 * @see https://reactrouter.com/web/example/query-parameters
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function useQuery() {
    return new URLSearchParams(useLocation().search);
}
