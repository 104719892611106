import React from 'react';
import PropTypes from 'prop-types';

/**
 * Display input validation errors
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Errors({ attr, errors }) {
    if (!errors || errors.length === 0) {
        return null;
    }
    return (
        <ul
            className="tw-text-red-300 tw-text-sm"
            data-test-id={`${attr}-errors`}
        >
            {errors.map((err, idx) => (
                <li key={idx}>{err}</li>
            ))}
        </ul>
    );
}

Errors.propTypes = {
    attr: PropTypes.string.isRequired,
    errors: PropTypes.array,
};
