export const createMockChatRepository = () => ({
    getMessages: jest.fn(() => new Promise((resolve) => resolve([]))),
    sendMessage: jest.fn(() => new Promise((resolve) => resolve())),
    onChildAdded: jest.fn((_, callback) =>
        callback({ key: '1234', val: () => ({}), ref: {} })
    ),
    onChildChanged: jest.fn((_, callback) =>
        callback({ key: 1, val: () => ({ content: 'new content' }), ref: {} })
    ),
    onChildRemoved: jest.fn((_, callback) =>
        callback({ key: 1, val: () => ({}), ref: {} })
    ),
});

const chatRepository = (chatService) => {
    const getMessages = (chatRef, lastItem, limit) =>
        chatService.getMessages(chatRef, lastItem, limit);
    const sendMessage = (chatRef, data) =>
        chatService.sendMessage(chatRef, data);
    const onChildAdded = (chatRef, callback) =>
        chatService.onChildAdded(chatRef, callback);
    const onChildChanged = (chatRef, callback) =>
        chatService.onChildChanged(chatRef, callback);
    const onChildRemoved = (chatRef, callback) =>
        chatService.onChildRemoved(chatRef, callback);

    return {
        getMessages,
        sendMessage,
        onChildAdded,
        onChildChanged,
        onChildRemoved,
    };
};

export default chatRepository;
