import React, { useState } from 'react';
import Modal from '../../chat/StandardModal';
import './style.css';
import { useTranslation } from 'react-i18next';

export const ComingSoonModal = (props) => {
    const { t } = useTranslation();
    let firstPart = t(
        'You can create events from the mobile app. This feature'
    );
    if (props.type == 'deal') {
        firstPart = t('You can create deals from the mobile app. This feature');
    }
    return (
        <Modal title={t('Coming Soon')} onClose={props.onClose}>
            <div className="mm-question">
                <p>
                    {firstPart}
                    <br />
                    {t('will be coming to our webapp soon')}
                </p>
            </div>
            <div className="mm-button">
                <button onClick={props.onClose}>{t('OK')}</button>
            </div>
        </Modal>
    );
};

const AddBadge = (props) => {
    const { t } = useTranslation();
    const firstPart = props.type === 'event' ? t('POST AN') : t('POST');
    const secondPart = props.type === 'event' ? t('EVENT') : t('A DEAL');

    return (
        <>
            <button
                onClick={props.onClick}
                className={props.className || 'ab-container'}
                data-guest={props.isGuest}
            >
                <div className="ab-content">
                    {!props.isGuest ? (
                        <>
                            <img
                                className="ab-icon"
                                src="/images/icons/add-post.png"
                            />
                            <div>
                                <p className="ab-description">{firstPart}</p>
                                <p className="ab-description">{secondPart}</p>
                            </div>
                        </>
                    ) : (
                        <p className={`guest-join ${props.textClassName || ''}`}>{props.buttonText}</p>
                    )}
                </div>
            </button>           
        </>
    );
};

export default AddBadge;
