import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';

import Modal from '../../common/Modal';
import { getCroppedImg } from '../../../core/utility/imageTools';
import PropTypes from 'prop-types';

export default function ImageCropper({
    source,
    changePhoto,
    setCroppedPixels,
}) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedPixels(croppedAreaPixels);
    }, []);
    return (
        <>
            <div className="tw-w-full tw-h-full tw-relative tw-bg-gray-400">
                <Cropper
                    image={source}
                    crop={crop}
                    zoom={zoom}
                    aspect={3 / 2}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    classes={{
                        cropAreaClassName:
                            'tw-top-0 tw-left-0 tw-h-full tw-w-full',
                    }}
                    style={{
                        cropAreaStyle: {
                            top: '0',
                            left: 0,
                            width: 'auto',
                            height: 'auto',
                            transform: 'none',
                        },
                        mediaStyle: {
                            maxHeight: 'none',
                        },
                    }}
                />
            </div>
            <button
                className="tw-bg-gray-900 tw-opacity-50 tw-py-3 tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-block tw-w-full"
                onClick={changePhoto}
                type="button"
            >
                CHANGE PHOTO
            </button>
        </>
    );
}
