import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import SearchInput from './SearchInput';
// import RecentlyContacted from './RecentlyContacted';
import GroupedMembers from './GroupedMembers';
import { useStores } from '../../../lib/store';
import { getCurrentBailiwik } from '../../../services/deviceService';
import { groupMembers } from '../../../lib/utils';
import Member from './Member';
import ProfileRouter from './ProfileRouter';
import AddMemberDrawer from './AddMemberDrawer';
import { useTranslation } from 'react-i18next';

function MembersList() {
    const [keyword, setKeyword] = useState('');
    const [isAddMemberDrawerVisible, setIsAddMemberDrawerVisible] =
        useState(false);
    const { memberStore, bailiwikStore } = useStores();
    const { bailiwikMembers } = memberStore;
    const { current } = bailiwikStore;
    const { t } = useTranslation();
    const currentBailiwik = getCurrentBailiwik();

    useEffect(() => {
        //memberStore.load(currentBailiwik._id, false, 'member list');

        // go ahead and reload to load fresh data
        memberStore.load(currentBailiwik._id, true, 'member list');
    }, []);

    const groupedMembers = useMemo(() => {
        if (bailiwikMembers.length === 0) {
            return [];
        }
        const skip = [...current.hosts];
        skip.push(current.bailiff._id);

        return groupMembers(bailiwikMembers, keyword, skip);
    }, [bailiwikMembers, keyword, current]);

    const getHosts = useMemo(() => {
        if (!current || current.hosts.length === 0) {
            return [];
        }
        const { hosts } = current;
        if (!bailiwikMembers || bailiwikMembers.length === 0) {
            return [];
        }

        const hostsData = [];
        for (let i = 0; i < bailiwikMembers.length; i += 1) {
            const curr = bailiwikMembers[i];
            if (!hosts.includes(curr._id)) {
                continue;
            }

            hostsData.push(curr);

            // found all hosts?
            if (hostsData.length === current.hosts.length) {
                break;
            }
        }
        return hostsData;
    }, [current, bailiwikMembers]);

    const { path } = useRouteMatch();

    /**
     * Process search input value change
     *
     * @param {string} value
     */
    const onChangeHandler = (value) => {
        setKeyword(value.trim());
    };

    return (
        <div className="">
            <div className="tw-mx-auto">
                <SearchInput keyword={keyword} onChange={onChangeHandler} />
            </div>
            {/* <RecentlyContacted /> */}
            <div className="tw-mb-2.5">
                <div>
                    <div className="tw-pb-4 tw-pt-6">{t('THE HOST')}</div>
                    <Member data={bailiwikStore.current.bailiff} isBailiff />
                </div>
                {getHosts.length > 0 && (
                    <>
                        <div className="tw-text-xs tw-pb-4 tw-pt-6">
                            {t('THE CO-HOSTS')}
                        </div>
                        <div className="tw-divide-y tw-divide-gray-500">
                            {getHosts.map((host, idx) => (
                                <Member key={idx} data={host} isHost />
                            ))}
                        </div>
                    </>
                )}
            </div>
            {groupedMembers.length > 0 && (
                <div className="tw-space-y-3.5">
                    {groupedMembers.map((group, idx) => (
                        <GroupedMembers key={idx} group={group} />
                    ))}
                </div>
            )}
            <Switch>
                <Route exact path={`${path}/:memberId`}>
                    <ProfileRouter
                        members={bailiwikMembers}
                        bailiff={bailiwikStore.current.bailiff}
                        hosts={getHosts}
                    />
                </Route>
            </Switch>
            <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50 tw-flex tw-justify-center">
                <div
                    className="tw-w-full tw-relative"
                    style={{ maxWidth: '1120px' }}
                >
                    <div
                        className="tw-absolute tw-bg-orange-chat tw-text-white tw-rounded-full tw-bottom-20 tw-right-2 tw-px-4 tw-py-4 tw-w-32 tw-flex tw-flex-row tw-items-center tw-space-x-2"
                        onClick={() => {
                            setIsAddMemberDrawerVisible(true);
                        }}
                        role="button"
                    >
                        <span>
                            <svg
                                width="29"
                                height="21"
                                viewBox="0 0 29 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.6547 3.93569C15.6978 5.00966 17.0619 6.52618 16.4903 7.81298C15.9186 9.09979 13.2278 10.5617 12.1872 9.49022C11.1466 8.41874 10.6571 6.8826 11.1806 5.70411C11.7041 4.52562 13.6117 2.86171 14.6547 3.93569ZM10.0086 11.7845L18.2902 11.7845L19.8406 17.7494H8.97539L10.0086 11.7845Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.6793 0.345842C6.72232 1.41981 8.08647 2.93633 7.51482 4.22314C6.94317 5.50994 4.25236 6.97186 3.21175 5.90037C2.17114 4.82889 1.68163 3.29276 2.20516 2.11426C2.7287 0.935772 4.63627 -0.728131 5.6793 0.345842ZM1.03312 8.1946L9.3148 8.1946L10.0492 10.8354H9.18355L8.36761 14.1596L-5.34058e-05 14.1596L1.03312 8.1946Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M22.8142 0.345842C23.8572 1.41981 25.2213 2.93633 24.6497 4.22314C24.078 5.50994 21.3872 6.97186 20.3466 5.90037C19.306 4.82889 18.8165 3.29276 19.34 2.11426C19.8636 0.935772 21.7711 -0.728131 22.8142 0.345842ZM18.168 8.1946L26.4497 8.1946L28 14.1596L19.8406 14.1596L19.1096 10.8218H18.168V8.1946Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M23.7492 16.1726V20H21.2066V16.1726L17.3927 16.1726V13.6209L21.2066 13.6209L21.2066 9.79347H23.7492L23.7492 13.6209H27.5631V16.1726H23.7492Z"
                                    fill="white"
                                    stroke="#F27F45"
                                />
                            </svg>
                        </span>
                        <span className="tw-leading-none tw-text-xs">
                            {t('ADD MEMBERS')}
                        </span>
                    </div>
                </div>
            </div>
            {isAddMemberDrawerVisible && (
                <AddMemberDrawer
                    close={() => {
                        setIsAddMemberDrawerVisible(false);
                    }}
                />
            )}
        </div>
    );
}

export default observer(MembersList);
