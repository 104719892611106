import React, { useCallback, useState, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import useRedeemDeal from '../../core/hooks/useRedeemDeal';
import Card from '../chat/Card';
import CommentsModal from '../chat/CommentsModal';
import JoinBailiwik from '../chat/JoinBailiwik';
import AddBadge from '../common/AddPostBadge';
import Header from '../header';
import { useTranslation } from 'react-i18next';
import useToggleLike from '../../core/hooks/useToggleLike';
import useGuestJoin from '../../core/hooks/useGuestJoin';
import useGuestSignup from '../../core/hooks/useGuestSignup';
import Messages from './Messages';
import Modal from './Modal';
import DeletePopup from './DeletePopup';
import { getRepostData } from '../../core/utility/messageContent';

import './style.css';

const DealScreen = (props) => {
    const redeemDeal = useRedeemDeal();
    const guestJoin = useGuestJoin(props.currentBailiwik);
    const guestSignup = useGuestSignup(props.currentBailiwik);
    const [commentsMessage, setCommentsMessage] = useState();
    const [modal, setModal] = useState({ open: false, data: {}, type: '' });
    const scrollableRef = useRef();
    const toggleLike = useToggleLike();
    const { t } = useTranslation();

    const isMember =
        props.currentBailiwik?.members?.findIndex(
            (member) =>
                member === props.currentUser._id ||
                member._id === props.currentUser._id
        ) >= 0;

    const onCreateModalOpen = () =>
        setModal({ data: {}, open: true, type: 'create-edit' });

    /**
     * How can we force InfiniteScroll to reset when a user has finished a REPOST?
     * Getting "scrollableDiv" and setting scrollTop is hacky, but does the job.
     *
     * @param {*} isReposting if true, reset InfiniteScroll to bottom.
     */
    const onModalClose = (isReposting) => {
        if (isReposting) {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                div.scrollTop = 0;
            }
        }
        setModal({ open: false, data: {}, type: '' });
    };

    const onEventDealEdit = (data) =>
        setModal({ open: true, data, type: 'create-edit' });

    const onEventDealRepost = (data) => {
        const repostData = {
            content: {
                deal: {
                    ...getRepostData(data.content.deal),
                },
            },
        };
        /*
        const repostData = {
            content : {
                deal : {
                    allDay : data.content.deal.allDay,
                    description : data.content.deal.description,
                    image : data.content.deal.image,
                    locationAddress: data.content.deal.locationAddress,
                    locationName: data.content.deal.locationName,
                    title: data.content.deal.title,
                }
            }
        };*/
        setModal({ open: true, data: repostData, type: 'create-repost' });
    };

    const onEventDealDelete = (data) =>
        setModal({ open: true, data, type: 'delete' });

    const handleJoin = useCallback(
        (bailiwik) => {
            props.switchBailiwik(bailiwik);
            props.updateUserData();
        },
        [props]
    );

    const CommentsModalMemo = useMemo(() => {
        if (!commentsMessage) return;
        return (
            commentsMessage && (
                <CommentsModal
                    currentUser={props.currentUser}
                    updateData={() => {}}
                    parent={commentsMessage}
                    close={() => setCommentsMessage(false)}
                    isDeal={true}
                    isDM={false}
                >
                    {(commentsMessage.content.event ||
                        commentsMessage.content.deal) && (
                        <Card
                            inThread={true}
                            like={() => {
                                toggleLike(
                                    commentsMessage,
                                    { currentUser: props.currentUser },
                                    (item) => {
                                        setCommentsMessage({
                                            ...commentsMessage,
                                            likes: item.likes,
                                        });
                                    }
                                );
                            }}
                            toggleModal={setCommentsMessage}
                            currentUser={props.currentUser}
                            message={commentsMessage}
                            redeem={(item) =>
                                redeemDeal(item) && setCommentsMessage(false)
                            }
                            key={commentsMessage['_id']}
                        />
                    )}
                </CommentsModal>
            )
        );
    }, [commentsMessage, props.currentUser]);

    return (
        <>
            <Helmet>
                <title>
                    Bailiwik {t('Deals')} : {props.currentBailiwik.name}
                </title>
                {props.currentBailiwik.description && (
                    <meta
                        name="description"
                        content={
                            `Deals in our local community! ` +
                            props.currentBailiwik.description
                        }
                    />
                )}
            </Helmet>
            {CommentsModalMemo}
            <Header
                isGuest={props.isGuest}
                bailiwiks={props.bailiwiks}
                switchBailiwik={props.switchBailiwik}
                bailiwik={props.currentBailiwik}
                currentUser={props.currentUser}
                isMember={isMember}
            />
            <div ref={scrollableRef} className="deal-container">
                {props.isGuest ? (
                    <div className="add-badge">
                        <AddBadge
                            isGuest={true}
                            buttonText="LOGIN"
                            onClick={guestJoin}
                        />
                        <p className="text-lowercase">or</p>
                        <AddBadge
                            textClassName="text-white"
                            className="ab-container white-outline"
                            isGuest={true}
                            buttonText="SIGN UP"
                            onClick={guestSignup}
                        />
                    </div>
                ) : (
                    <div className="add-badge add-badge-sm">
                        <AddBadge
                            type="deal"
                            isGuest={false}
                            onClick={onCreateModalOpen}
                        />
                    </div>
                )}
                {!isMember && !props.isGuest && (
                    <JoinBailiwik
                        onJoin={handleJoin}
                        bailiwik={props.currentBailiwik}
                        toggleBottomNav={props.toggleBottomNav}
                    />
                )}
                <Messages
                    type="deal"
                    sendLike={toggleLike}
                    scrollableRef={scrollableRef}
                    redeemDeal={redeemDeal}
                    isGuest={props.isGuest}
                    toggleCommentModal={setCommentsMessage}
                    currentUser={props.currentUser}
                    bailiwikId={props.currentBailiwik._id}
                    onEventDealDelete={onEventDealDelete}
                    onEventDealEdit={onEventDealEdit}
                    onEventDealRepost={onEventDealRepost}
                />
            </div>
            {modal.open && modal.type === 'create-edit' && (
                <Modal
                    user={{ currentUser: props.currentUser }}
                    bailiwik={props.currentBailiwik}
                    data={modal.data}
                    onClose={onModalClose}
                />
            )}
            {modal.open && modal.type === 'create-repost' && (
                <Modal
                    user={{ currentUser: props.currentUser }}
                    bailiwik={props.currentBailiwik}
                    data={modal.data}
                    onClose={onModalClose}
                />
            )}
            {modal.open && modal.type === 'delete' && (
                <DeletePopup data={modal.data} onClose={onModalClose} />
            )}
        </>
    );
};

export default DealScreen;
