import React from 'react';
import { Helmet } from 'react-helmet';

export default function NoMatch() {
    return (
        <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
            <Helmet>
                <title>404 - Page Not Found</title>
            </Helmet>
            <div className="tw-text-5xl">404</div>
        </div>
    );
}
