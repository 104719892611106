import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';

import { BASE_URL } from '../../env';

import { getCroppedImg } from '../../core/utility/imageTools';
import HeaderLayout from '../common/HeaderLayout';
import { useStores } from '../../lib/store';
import ImageCropper from './components/ImageCropper';
import ImagePlaceholder from './components/ImagePlaceholder';
import imgQR from './assets/bailiwik.com.svg';
import imgLogo from './assets/logo.png';
import Modal from '../common/Modal';
import useDragAndDrop from '../../core/hooks/useDrugAndDrop';

import styles from './assets/index.module.css';
import { useTranslation } from 'react-i18next';

/**
 * Edit Icon
 *
 * @param {object} props
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function EditIcon(props) {
    return (
        <div {...props}>
            <svg
                width="32"
                height="32"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 15.2486V18.9986H3.75L14.81 7.93859L11.06 4.18859L0 15.2486ZM17.71 5.03859C18.1 4.64859 18.1 4.01859 17.71 3.62859L15.37 1.28859C14.98 0.898594 14.35 0.898594 13.96 1.28859L12.13 3.11859L15.88 6.86859L17.71 5.03859Z"
                    fill="#26375E"
                />
            </svg>
        </div>
    );
}

/**
 * Flyer page component
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function Flyer() {
    const { bailiwikStore } = useStores();
    const inputFileRef = useRef();
    const formRef = useRef();
    const pictureNameRef = useRef();
    const [isErrorsModalVisible, setIsErrorsModalVisible] = useState(false);
    const [croppedPixels, setCroppedPixels] = useState();
    const [preview, setPreview] = useState('');
    const { t } = useTranslation();
    const [title, setTitle] = useState(
        `${t("Join our community")},\n${bailiwikStore.current.name} ${t("Friends")}!`
    );
    const [description, setDescription] = useState(
        t("Come join our community where we can chat")
    );
    const { setElement } = useDragAndDrop((files) => setPreview(files));

    const onChangeHandler = async (e) => {
        try {
            setPreview(URL.createObjectURL(e.target.files[0]));
        } catch (err) {
            console.error(err);
            alert(t("Something went wrong Please try again"));
        }
    };

    const changePhoto = () => {
        inputFileRef.current && inputFileRef.current.click();
    };

    const getCroppedImage = async () => {
        return getCroppedImg(preview, croppedPixels, 690, 460);
    };

    const download = async () => {
        // if (!preview) {
        //     setIsErrorsModalVisible(true);
        //     return;
        // }
        const fd = new FormData();
        fd.append('title', title);
        fd.append('description', description);

        if (preview) {
            const image = await getCroppedImage();
            fd.append('picture', image);

            // upload to flyer api
            const imageName = await fetch(
                `${BASE_URL}/api/flyers/flyer-image`,
                {
                    method: 'post',
                    body: fd,
                }
            )
                .then((res) => res.json())
                .then((data) => data.flyer)
                .catch((err) => {
                    alert(
                        t("Something went wrong Please try again in a moment")
                    );
                    console.error(err);
                    return false;
                });
            if (imageName !== false) {
                pictureNameRef.current.value = imageName;
                formRef.current.submit();
            }
        } else {
            formRef.current.submit();
        }
    };

    return (
        <HeaderLayout
            RightButton={
                <div className="tw-flex tw-items-center tw-space-x-7">
                    {/* <svg
                        width="28"
                        height="14"
                        viewBox="0 0 32 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2.85 8C2.85 5.435 4.935 3.35 7.5 3.35H13.5V0.5H7.5C3.36 0.5 0 3.86 0 8C0 12.14 3.36 15.5 7.5 15.5H13.5V12.65H7.5C4.935 12.65 2.85 10.565 2.85 8ZM9 9.5H21V6.5H9V9.5ZM22.5 0.5H16.5V3.35H22.5C25.065 3.35 27.15 5.435 27.15 8C27.15 10.565 25.065 12.65 22.5 12.65H16.5V15.5H22.5C26.64 15.5 30 12.14 30 8C30 3.86 26.64 0.5 22.5 0.5Z"
                            fill="white"
                        />
                    </svg> */}
                    <button onClick={download}>
                        <svg
                            width="26"
                            height="24"
                            viewBox="0 0 34 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M25.5 8H24V0.5H6V8H4.5C2.01 8 0 10.01 0 12.5V21.5H6V27.5H24V21.5H30V12.5C30 10.01 27.99 8 25.5 8ZM9 3.5H21V8H9V3.5ZM21 21.5V24.5H9V18.5H21V21.5ZM24 18.5V15.5H6V18.5H3V12.5C3 11.675 3.675 11 4.5 11H25.5C26.325 11 27 11.675 27 12.5V18.5H24Z"
                                fill="white"
                            />
                            <path
                                d="M24 14.75C24.8284 14.75 25.5 14.0784 25.5 13.25C25.5 12.4216 24.8284 11.75 24 11.75C23.1716 11.75 22.5 12.4216 22.5 13.25C22.5 14.0784 23.1716 14.75 24 14.75Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>
            }
            title={t("Create a Flyer")}
            backButton
            withNav={false}
        >
            <div className="">
                <div className="tw-my-6 tw-text-white tw-opacity-75 tw-text-center tw-max-w-2xl tw-mx-auto ">
                    {t("Create a Flyer that you can download")}
                </div>
                <div className="tw-max-w-2xl tw-mx-auto tw-border tw-border-white tw-p-3 tw-mb-10">
                    <div className="">
                        <input
                            name="picture_file"
                            ref={inputFileRef}
                            type="file"
                            onChange={onChangeHandler}
                            className="tw-hidden"
                            accept="image/*"
                        />
                        <form
                            ref={formRef}
                            action={`${BASE_URL}/api/flyers/flyer-download`}
                            method="POST"
                            target="_blank"
                        >
                            <input
                                ref={pictureNameRef}
                                type="hidden"
                                name="picture"
                            />
                            <div className="tw-bg-white tw-p-10">
                                <div className="tw-relative">
                                    <textarea
                                        name="title"
                                        className={`${styles.title_input} tw-text-6xl tw-text-center tw-w-full tw-bg-gray-200 tw-rounded-2xl tw-p-4 tw-font-bold`}
                                        value={title}
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                        }}
                                    />
                                    <EditIcon className="tw-absolute tw-bottom-9 tw-right-4" />
                                </div>
                                <div
                                    ref={(ref) => setElement(ref)}
                                    className={styles.aspect_ratio_box}
                                >
                                    <div
                                        className={
                                            styles.aspect_ratio_box_inside
                                        }
                                    >
                                        {!preview && (
                                            <ImagePlaceholder
                                                onClick={changePhoto}
                                            />
                                        )}
                                        {preview && (
                                            <ImageCropper
                                                source={preview}
                                                changePhoto={changePhoto}
                                                setCroppedPixels={
                                                    setCroppedPixels
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="tw-py-5 tw-relative">
                                    <textarea
                                        name="description"
                                        className="tw-rounded-2xl tw-p-8 tw-w-full tw-bg-gray-200 tw-text-blue-header"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                    <EditIcon className="tw-absolute tw-bottom-9 tw-right-4" />
                                </div>
                                <div className="tw-py-5 tw-text-sm tw-text-gray-600">
                                    {t("Bailiwiks are neighborhood communities focused on sense of membership and connection")}
                                </div>
                                <div className="tw-relative tw-text-gray-500 tw-mt-10 tw-h-32">
                                    <div
                                        className={`${styles.logo_bg} tw-inline-block tw-text-center tw-p-1.5`}
                                    >
                                        <img
                                            src={imgLogo}
                                            className="tw-w-16 tw-inline-block"
                                        />
                                        <div className="tw-font-bold tw-text-white">
                                        {t("Bailiwik")}
                                        </div>
                                    </div>
                                    <div className="tw-absolute tw-right-0 tw-top-0 tw-text-right tw-text-xs">
                                        <img
                                            src={imgQR}
                                            className="tw-inline-block tw-w-20"
                                        />
                                        <div className="tw-mt-1">
                                        {t("Join our Bailiwik inside the app at")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isErrorsModalVisible && (
                                <Modal
                                    closeHandler={() => {
                                        setIsErrorsModalVisible(false);
                                    }}
                                >
                                    <div className="tw-text-center">
                                        <div className="tw-text-orange-chat">
                                        {t("Please upload a picture for your flyer")}
                                        </div>
                                        <button
                                            className="tw-bg-orange-chat tw-text-white tw-px-8 tw-py-2 tw-mt-4"
                                            onClick={() =>
                                                setIsErrorsModalVisible(false)
                                            }
                                        >
                                            {t("OK")}
                                        </button>
                                    </div>
                                </Modal>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </HeaderLayout>
    );
}

export default observer(Flyer);
