import React from 'react';
import MessageContent from '../../MessageContent';

export default function RecentComment({ thread }) {
    return (
        <>
            {thread.creator?.fullName ? thread.creator.fullName : 'RECENTS'}
            :&nbsp;
            <MessageContent showProfile={() => {}} text={thread.content.text} />
        </>
    );
}
