// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2BmiRQugxYuo7Tc2VoMADD {\n  height: 0;\n  overflow: hidden;\n  padding-top: 66.666666667%;\n  background: white;\n  position: relative;\n  margin-top: 1rem;\n}\n\n._2aWG0-WqEU57xGnN2N1_GV {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n._3Ewl7c_NSt2vrSzjNE4_3J {\n  color: #f27f45;\n}\n\n._3Ewl7c_NSt2vrSzjNE4_3J::first-line {\n  color: #364c80;\n}\n\n._1wI3p-D9y7dtkJMbQ2j0k4{\n  background-color: #26375E;\n  border-radius: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./client/components/Flyer/assets/index.module.css"],"names":[],"mappings":"AAAA;EACI,SAAS;EACT,gBAAgB;EAChB,0BAA0B;EAC1B,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AACpB;;AACA;EACI,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AAChB;;AAEA;EACI,cAAc;AAClB;;AACA;EACI,cAAc;AAClB;;AAEA;EACI,yBAAyB;EACzB,mBAAmB;AACvB","sourcesContent":[".aspect_ratio_box {\n    height: 0;\n    overflow: hidden;\n    padding-top: 66.666666667%;\n    background: white;\n    position: relative;\n    margin-top: 1rem;\n}\n.aspect_ratio_box_inside {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n}\n\n.title_input {\n    color: #f27f45;\n}\n.title_input::first-line {\n    color: #364c80;\n}\n\n.logo_bg{\n    background-color: #26375E;\n    border-radius: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aspect_ratio_box": "_2BmiRQugxYuo7Tc2VoMADD",
	"aspect_ratio_box_inside": "_2aWG0-WqEU57xGnN2N1_GV",
	"title_input": "_3Ewl7c_NSt2vrSzjNE4_3J",
	"logo_bg": "_1wI3p-D9y7dtkJMbQ2j0k4"
};
export default ___CSS_LOADER_EXPORT___;
