import { formatUser } from '../../utils';

export const editMediaMessage = (item, data) => {
    // eslint-disable-next-line no-unused-vars
  const { _id, ref, ...newData } = item;
    console.log('firebase-message-utility editMediaMessage newData:', newData );
    console.log('firebase-message-utility editMediaMessage data:', data );
  
    const currentTimestamp = (new Date(newData.createdAt)).getTime()
      newData.content.media.images = data.images
      newData.content.media.description = data.description
      newData.content.media.edited = true
      newData.updatedAt = -1 * Date.now(),
      newData.createdAt = currentTimestamp > 0 ? -1 * currentTimestamp : currentTimestamp
      console.log('firebase-message-utility final version for update:', newData);
  
      ref.update(newData)
  }

export const editMessage = (item, text) => {
// eslint-disable-next-line no-unused-vars
const { _id, ref, ...oldData } = item;
const currentTimestamp = (new Date(oldData.createdAt)).getTime()

item.ref.update({
    ...oldData,
    content: {
    text: text,
    },
    edited: true,
    updatedAt: -1 * Date.now(),
    createdAt: currentTimestamp > 0 ? -1 * currentTimestamp : currentTimestamp
});
}

export const toggleLike = (item, user, callback) => {
    const formattedUser = formatUser(user)

    item.ref.transaction(item => {
        if (item.likes.users && item.likes.users[user.currentUser._id]) {
          item.likes.count--;
          item.likes.users[user.currentUser._id] = null;
        } else {
          item.likes.count++;
          if (!item.likes.users) {
              item.likes.users = {};
          }
          item.likes.users[user.currentUser._id] = formattedUser;
        }
        if (callback) {
          callback({
            ...item,
            likes: {
              ...item.likes,
              users: Object.values(item.likes.users).filter(user => user)
            }
          })
        }
        return item;
    })
}

export const removeMessage = (item) => item.ref.remove()

export const reportMessage = (item, user, areReported) => item.ref.transaction(item => {
        if(!item.reports) item.reports = []
  
        areReported = !!item?.reports?.find(
          report => report.creator._id === user.currentUser._id
        );
  
        if (areReported) {
          const resultReports = item.reports.filter(
            report => report.creator._id !== user.currentUser._id
          );
  
          item.reports = resultReports
        } 
  
        if(!areReported){
          item.reports.push({ 
            creator: user.currentUser,
            createdAt: new Date().getTime()
          });
        }
  
        return item;
    });