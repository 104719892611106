import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderFooterLayout from '../common/HeaderFooterLayout';
import MembersList from '../common/MembersList';

export default function MemberDirectory() {
    return (
        <HeaderFooterLayout title="Members">
            <Helmet>
                <title>Bailiwik Members</title>
            </Helmet>
            <MembersList />
        </HeaderFooterLayout>
    );
}
