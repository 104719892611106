import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';

/**
 * Header Layout Component
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function HeaderLayout({
    children,
    title = '',
    backButton = false,
    RightButton = null,
    LeftButton = null,
    withNav = true,
}) {
    return (
        <div
            className={`centred-content tw-flex tw-flex-col ${
                withNav ? 'with-nav' : 'without-nav'
            }`}
        >
            <Header
                title={title}
                backButton={backButton}
                RightButton={RightButton}
                LeftButton={LeftButton}
            />
            <div className="tw-flex-1 tw-overflow-y-auto tw-bg-blue-bg tw-px-10">
                {children}
            </div>
        </div>
    );
}

HeaderLayout.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    backButton: PropTypes.bool,
    RightButton: PropTypes.node,
    LeftButton: PropTypes.node,
    withNav: PropTypes.bool,
};
