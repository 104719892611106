import React from 'react';
import BailiwiksListItem from './bailiwiks-list-item';

const BailiwiksList = props => {
  return props.bailiwiks.map(bailiwik =>
      <BailiwiksListItem
        key={bailiwik._id}
        bailiwik={bailiwik}
        switchBailiwik={props.switchBailiwik} />
  );
}

export default BailiwiksList;
