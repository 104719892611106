import { useState, useEffect } from 'react';
import { useFirebase } from '../contexts/FirebaseContext';
import chatUseCase from '../chat';

const parseVal = (data) => ({
    ...data.val(),
    _id: data.key,
    ref: data.ref,
    thread: data.val().thread || [],
    reports: data.val().reports || [],
    createdAt: data.val().createdAt * -1,
});

const useMessages = (path, page, limit) => {
    const [messages, setMessages] = useState([]);
    const { firebase } = useFirebase();
    const chatRef = firebase.database().ref(path);

    const initMessages = async () => {
        const messages = await chatUseCase.getMessages(chatRef, null, limit);
        setMessages(messages);
    };

    const initListener = () => {
        chatUseCase.onChildAdded(chatRef, (adderFunction) => {
            setMessages(adderFunction);
        });

        chatUseCase.onChildChanged(chatRef, (updaterFunction) => {
            setMessages(updaterFunction);
        });

        chatUseCase.onChildRemoved(chatRef, (removerFunction) => {
            setMessages(removerFunction);
        });
    };

    useEffect(() => {
        if (page < 1) return console.log('page less');
        const lastMessage = messages[messages.length - 1];
        // const lastMessage = messages[0]

        // TODO: change last index to actual index of firebase document

        chatUseCase
            .getMessages(chatRef, lastMessage)
            .then((newMessages) => {
                setMessages([...messages, ...newMessages]);
            })
            .catch((e) => console.log('new errror ', e));
    }, [page]);

    useEffect(() => {
        initMessages();
        initListener();

        return () => {
            chatRef.off();
            setMessages([]);
        };
    }, [path]);

    return messages;
};

export default useMessages;
