import moment from 'moment';

/**
 * Get ImageUrl.
 *
 * Input image id may have different formats. If user signed up with Google SSO
 * avatar may have this format:
 * https://lh3.googleusercontent.com/a/AGNmyxbFQ5DdXgU2YVgg9LnI5mFUwuqPRgIQBsLIxfzh=s96-c
 * This is rare, and a bug. It has been decided to ignore these URLs.
 * See https://github.com/Bailiwik1/bailiwik-server/issues/85
 *
 * When user uploads a profile pic, id may be a string like
 * "ac69f3bab346c5ad8f517003454cdb99.jpg". This is a valid case for their avatar.
 *
 * @param {string} id Image ID
 *
 * @returns {string} Image url
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getImageUrl = (id) => {
    if (typeof id != 'string' || !id || (id.trim && id.trim() === '')) {
        return '';
    }
    // #702 in some cases, avatar is a URL pointing to googleusercontent.com
    // In those cases, return empty string.
    if (id.startsWith(`https://`) && id.indexOf(`googleusercontent.com/`) > 0) {
        return '';
    }
    if (id.startsWith(process.env.ASSETS_URL)) {
        return id;
    }
    // Otherwise, this should be a correct format for the avatar.
    // Make sure your .env file has the correct value for ASSETS_URL
    const image3 = `${process.env.ASSETS_URL}/${id}`;
    return image3;
};

/**
 * Get time ago text
 *
 * @param {string} time Date time in zulu
 *
 * @returns {string}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getTimeAgo = (time) => {
    return moment(time).fromNow();
};

/**
 * Sanitize text to make it safe and readable
 *
 * @param {string} text Text to sanitize
 *
 * @returns {string} Sanitized output
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const sanitize = (text) => {
    if (!text) {
        return '';
    }
    let dirty = text;
    const type = typeof text;
    if (type !== 'string') {
        if (!['number', 'bignit'].includes(type)) {
            return '';
        }
        dirty = text.toString();
    }

    return dirty
        .replace(/&nbsp;/g, ' ') // fix space
        .replace(/(<div>|<\/div>)/g, '\n') // convert div to new line
        .replace(/\s*<br[ ]?[\/]?>\s*/g, '\n') // convert br to new line
        .replace(/\s*[\n]+\s*/g, '\n') // strip multiple new lines
        .replace(/<[^>]+>/g, '') // strip other tags
        .trim();
};

/**
 *
 * @param {*} array
 * @param {*} callback
 *
 * @author Copied from Mobile App Code
 * @author Gihan S <gihanshp@gmail.com>
 */
export const asyncForEach = async function (array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

/**
 * Create a alphabatical member directory
 *
 * @param {array}  data
 * @param {string} keyword
 * @param {array}  skip    Members to skip adding to group
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const groupMembers = (data, keyword, skip = []) => {
    if (!data || data.length === 0) {
        return [];
    }

    const groups = {};
    data.forEach((mem) => {
        if (skip.includes(mem._id)) {
            return;
        }
        const name = mem.displayName
            ? mem.displayName
            : mem.fullName
            ? mem.fullName
            : 'U';
        const char = name[0].toUpperCase();

        const reg = new RegExp(keyword, 'i');

        if (keyword !== '') {
            // isn't keyword inside of fullName or displayName?
            if (
                !(mem.fullName && mem.fullName.search(reg) > -1) &&
                !(mem.displayName && mem.displayName.search(reg) > -1)
            ) {
                return;
            }
        }

        if (groups[char]) {
            groups[char].push(mem);
        } else {
            groups[char] = [mem];
        }
    });

    // sort each group
    const sortedGroups = Object.keys(groups).map((key) => {
        groups[key].sort((a, b) => {
            const nameA = a.displayName ? a.displayName : a.fullName;
            const nameB = b.displayName ? b.displayName : b.fullName;
            return nameA.localeCompare(nameB);
        });
        return {
            group: key,
            members: groups[key],
        };
    });
    sortedGroups.sort((a, b) => a.group.localeCompare(b.group));
    return sortedGroups;
};

/**
 * Convert time to 12 hour format
 */
export const convert24To12 = ([hrs, mins]) => {
    let hours = parseInt(hrs, 10);
    const AMPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = parseInt(mins, 10).toString().padStart(2, '0');

    return `${hours}:${minutes} ${AMPM}`;
};
