import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextMessage from './TextMessage';
import Creator from './Creator';
import LikeIcon from './LikeIcon';
import ContextualPopUp from './ContextualPopUp';
import { useResetBadgesCount } from '../../../core/hooks/useBadgeCount';

import './index.css';

/**
 * Received Component
 *
 * This component display a received message
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Received({ userId, message, author, memberId, myProfile, toggleLike }) {
    const resetBadges = useResetBadgesCount(userId);
    const [isContextPopUp, setIsContextPopUp] = useState(false);
    // const toggleLike = useToggleLike(message, memberId);
    const refMessage = useRef(null);
    useEffect(() => {
        if (message?._id) resetBadges(message?._id);
        return () => {
        };
    }, []);
    const { content, edited } = message;

    const iLiked =  Object.values(message?.likes?.users || {}).find((user) => {
        if (typeof user === 'string') {
            return myProfile._id === user;
        }
        return user._id === myProfile._id;
    });

    /**
     * Render contextual popup
     */
    const ContextualPopUpContainer = ({ refMessage }) => {
        const refPopup = useRef(null);
        useEffect(() => {
            if (!refPopup.current || !refMessage) {
                return;
            }
            const { current: popup } = refPopup;
            const { current: msg } = refMessage;
            if (popup.clientWidth > msg.clientWidth) {
                popup.style.left = '1rem';
            } else {
                popup.style.right = '-1rem';
            }
            popup.style.visibility = 'visible';
        }, []);
        return (
            <div ref={refPopup} className="tw-absolute tw--bottom-4 tw-invisible">
                {
                    <ContextualPopUp
                        closeHandler={() => {
                            setIsContextPopUp(false);
                        }}
                        buttons={{
                            like:() => toggleLike(message),
                        }}
                    />
                }
            </div>
        );
    };

    return (
        <div className="received">
            <div
                data-test-id="received-clickable"
                className="tw-m-3.5 tw-inline-block tw-relative"
                onClick={(e) => {
                    // don't mess with clickable links
                    if(e.target.href) {
                        return;
                    }
                    setIsContextPopUp(!isContextPopUp);
                }}
            >
                <div
                    ref={refMessage}
                    className="tw-bg-white tw-p-2.5 tw-rounded-lg tw-rounded-bl-none"
                >
                    {content.text && (
                        <TextMessage text={content.text} edited={edited} />
                    )}
                </div>
                <div className="arrow-nw tw-absolute tw-left-0"></div>
                {message.likes.count > 0 && (
                    <LikeIcon
                        fillColor="#F27F45"
                        bgColor="tw-bg-white"
                        count={message.likes.count}
                        iLiked={iLiked}
                    />
                )}
                {isContextPopUp && (
                    <ContextualPopUpContainer refMessage={refMessage} />
                )}
            </div>
            <Creator message={message} author={author} />
        </div>
    );
}

Received.propTypes = {
    userId: PropTypes.string.isRequired,
    message: PropTypes.object.isRequired,
    author: PropTypes.object.isRequired,
    myProfile: PropTypes.object.isRequired,
    memberId: PropTypes.string.isRequired,
};
