import { useCallback, useMemo, useState } from 'react'
import { getAuthToken } from '../../services/deviceService';
import { BASE_URL } from '../../env';

const useSearchBailiwiksApi = () => {
    const [loading, setLoading] = useState(false)
    const token = getAuthToken()

    const requestData = useMemo(() => ({
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
    }), [token])
    
    const getByLocation = useCallback(({lat, lng}) => {
        setLoading(true)

        return fetch(`${BASE_URL}/api/bailiwiks/v2/?latitude=${lat}&longitude=${lng}`, requestData)
            .then(res => res.json())
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [requestData])
    

    const getByName = useCallback((search) => {
        setLoading(true)

        return fetch(`${BASE_URL}/api/bailiwiks?search=${search}`, requestData)
            .then(res => res.json())
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }, [requestData])

    return {getByLocation, getByName, loading}
}

export default useSearchBailiwiksApi