import React from 'react';
import PropTypes from 'prop-types';

import Member from './Member';

export default function GroupedMembers({ group }) {
    return (
        <div data-test-id="groups">
            <div className="tw-pt-2 tw-pb-4">{group.group}</div>
            <div>
                {group?.members?.length > 0 &&
                    group?.members?.map((data, idx) => (
                        <Member key={idx} data={data} />
                    ))}
            </div>
        </div>
    );
}

GroupedMembers.propTypes = {
    group: PropTypes.object.isRequired,
};
