import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import styles from './styles';

export default function GooglePlaceSearch({ value, onChange }) {
    return (
        <GooglePlacesAutocomplete
            apiKey={process.env.GOOGLE_PLACE_SEARCH_API_KEY}
            autocompletionRequest={{
                types: ['geocode', 'establishment'],
                componentRestrictions: {
                    country: ['us'],
                }
            }}
            selectProps={{
                disableScroll: false,
                value: !value ? null : value.value ? value : { label: value.label, value: {} },
                onChange,
                styles,
                className: "google-please-autocomplete-input",
                placeholder: 'Add location'
            }} />
    );
}
