import React, { useState } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Helmet } from 'react-helmet';

import InputField from './InputField';
import { useTranslation } from 'react-i18next';
import SocialLogin from '../../SocialLogin';

const inputConstraints = {
    email: {
        email: true,
    },
    password: {
        presence: {
            allowEmpty: false,
        },
    },
    firstName: {
        presence: {
            allowEmpty: false,
        },
    },
};

/**
 * SignUp Step A
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function StepA({ userData, setUserData, createProfile }) {
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();
    const { email, password, fullName, nickname, rememberMe } = userData;

    const onSubmit = (e) => {
        e.preventDefault();
        const errors = validate(
            {
                email,
                password,
                firstName: fullName,
                nickname,
            },
            inputConstraints
        );
        if (errors) {
            setErrors(errors);
            return false;
        }

        createProfile();
        return false;
    };

    /**
     * Clear input errors
     */
    const clearErrors = (field) => {
        setErrors({
            ...errors,
            [field]: [],
        });
    };

    return (
        <>
            <Helmet>
                <title>Sign Up</title>
            </Helmet>
            <h1 className="tw-text-xl tw-pt-8 tw-mb-4 tw-text-center tw-tracking-wider">
                {t('Awesome Lets get started')}
            </h1>
            <form action="" onSubmit={onSubmit} autoComplete="off">
                <div className="tw-space-y-6">
                    <InputField
                        label={t('EMAIL')}
                        name="email"
                        type="email"
                        value={email}
                        placeholder={t('Enter your email')}
                        onChange={(e) => {
                            setUserData('email', e.target.value);
                            clearErrors('email');
                        }}
                        errors={errors.email}
                    />
                    <InputField
                        label={t('PASSWORD')}
                        name="password"
                        value={password}
                        type="password"
                        placeholder={t('Enter password')}
                        onChange={(e) => {
                            setUserData('password', e.target.value);
                            clearErrors('password');
                        }}
                        errors={errors.password}
                    />
                    <InputField
                        label={t('FIRST NAME')}
                        name="first_name"
                        value={fullName}
                        placeholder={t('Enter your first name')}
                        onChange={(e) => {
                            setUserData('fullName', e.target.value);
                            clearErrors('fullName');
                        }}
                        errors={errors.firstName}
                    />
                    <InputField
                        label={t('NICKNAME')}
                        name="nickname"
                        value={nickname}
                        placeholder={t('Enter your nickname')}
                        onChange={(e) => {
                            setUserData('nickname', e.target.value);
                            clearErrors('nickname');
                        }}
                        errors={errors.nickName}
                    />
                    <label className="">
                        <input
                            name="remember_me"
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => {
                                setUserData('rememberMe', e.target.checked);
                                clearErrors('rememberMe');
                            }}
                        />{' '}
                        {t('Keep me logged in')}
                    </label>
                </div>
                <button
                    className="tw-mt-8 tw-bg-orange-chat tw-w-4/5 tw-block tw-py-4 tw-mx-auto tw-rounded-md tw-tracking-wider tw-text-lg"
                    onClick={onSubmit}
                    type="submit"
                    data-test-id="btn-submit"
                >
                    {t('NEXT')}
                </button>
            </form>
        </>
    );
}

StepA.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
};
