import React, { useState, useEffect } from 'react';

const TEXTS = [
    'Signing you in...',
    'Walking the dog',
    'Mowing the lawn',
    'Touching the sky',
];

export default function Loader() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((oldIndex) => {
                if (oldIndex + 1 < TEXTS.length) {
                    return oldIndex + 1;
                }

                return 0;
            });
        }, 800);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="sso-loader">
            <img src="/images/icons/sso-loader.svg" alt="Loader" />
            <span>{TEXTS[index]}</span>
        </div>
    );
}
