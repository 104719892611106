import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import RequestPasscode from './components/RequestPasscode';
import ResetPassword from './components/ResetPassword';
import VerifyPasscode from './components/VerifyPasscode';

/**
 * Forgot password main page
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function ForgotPassword({ setBailiwikId }) {
    const { path } = useRouteMatch();
    return (
        <div className="tw-bg-blue-bg tw-flex tw-min-h-screen">
            <div className="tw-max-w-lg tw-m-auto">
                <Switch>
                    <Route path={path} exact>
                        <Helmet>
                            <title>Request Passcode</title>
                        </Helmet>
                        <RequestPasscode setBailiwikId={setBailiwikId} />
                    </Route>
                    <Route path={`${path}/verify-passcode`}>
                        <Helmet>
                            <title>Verify Passcode</title>
                        </Helmet>
                        <VerifyPasscode setBailiwikId={setBailiwikId} />
                    </Route>
                    <Route path={`${path}/reset`}>
                        <Helmet>
                            <title>Reset Password</title>
                        </Helmet>
                        <ResetPassword setBailiwikId={setBailiwikId} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
