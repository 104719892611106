import React from 'react';
import PropTypes from 'prop-types';

import YoutubePlayer from 'react-player';

export default function YoutubeContent({ message, url }) {
    const getTitle = () => {
        // eslint-disable-next-line react/prop-types
        const { meta } = message;

        if (meta === undefined) {
            return '';
        }

        // eslint-disable-next-line react/prop-types
        let title = meta.title || meta['og:title'];
        return title && title !== '' ? title : 'No title';
    };

    const getDescription = () => {
        // eslint-disable-next-line react/prop-types
        const { meta } = message;
        if (meta === undefined) {
            return '';
        }
        // eslint-disable-next-line react/prop-types
        let description = meta.description || meta['og:description'];
        return description && description !== ''
            ? description
            : 'No description';
    };
    return (
        <div className="tw-w-72 md:tw-w-card tw-p-4 ">
            <div className="tw-max-w-full">
                <div className="tw-rounded-md tw-overflow-hidden">
                    <YoutubePlayer url={url} light width="100%" playing />
                </div>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                    className="tw-block tw-font-bold tw-pt-2 tw-underline tw-text-gray-700"
                >
                    {getTitle()}
                </a>
                <div className="tw-text-gray-700">{getDescription()}</div>
            </div>
        </div>
    );
}

YoutubeContent.propTypes = {
    message: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
};
