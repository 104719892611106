import React, { useState, useEffect } from 'react';
import { getPageWithBailiwikIdFromURL } from '../helpers';
import { CURR_USER_ID_KEY } from '../services/deviceService';
import useLogin from '../core/hooks/useLogin';
import Loader from './Loader';

const getUserId = () =>
    JSON.parse(window.localStorage.getItem(CURR_USER_ID_KEY) || null) ||
    JSON.parse(window.sessionStorage.getItem(CURR_USER_ID_KEY) || null);

const isCheckPassed = (page, bailiwikId) => {
    if (['chat', 'events', 'deals'].includes(page) && bailiwikId) {
        return false;
    }
    return true;
};

export default function DetectGuestWrapper({ children }) {
    const [page, bailiwikId] = getPageWithBailiwikIdFromURL();
    const login = useLogin('Social Login');
    const userId = getUserId();
    const [checkPassed, setCheckPassed] = useState(
        isCheckPassed(page, bailiwikId) || userId
    );

    useEffect(() => {
        if (!userId && bailiwikId) {
            login(process.env.GUEST_EMAIL, process.env.GUEST_PASSWORD, true)
                .then(() => setCheckPassed(true))
                .catch(() => console.log('>>> error logging in guest', e));
        }
    }, []);

    if (!checkPassed) {
        return <Loader />;
    }

    return children;
}
