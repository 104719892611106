import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';

export default function HeaderFooterLayout({
    children,
    title,
    backButton = false,
    Footer = null,
}) {
    return (
        <div className="centred-content tw-flex tw-flex-col with-nav tw-relative">
            <Header title={title} backButton={backButton} />
            <div className="tw-flex-1 tw-overflow-y-auto tw-bg-blue-bg tw-px-8 avoid-overflow">{children}</div>
            {Footer !== null && Footer}
        </div>
    );
}

HeaderFooterLayout.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    backButton: PropTypes.bool,
    Footer: PropTypes.element,
};
