import React, { Fragment } from 'react';
import Linkify from 'react-linkify';
import {
    replacedContent,
    isWordMention,
    replaceMentionHumanReadable,
    getMentionData,
} from '../core/utility/messageContent';
import { useStores } from '../lib/store';
import YoutubeContent from './chat/youtube-content';
import { getHostnameFromDomain } from '../helpers';

export default function MessageContent({ text, showProfile }) {
    const {
        bailiwikStore: { membersDetails },
    } = useStores();
    const mentionData = getMentionData(text);

    const onClick = (username) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        const profile = membersDetails.find(
            (member) => member._id === mentionData[username]
        );
        showProfile(profile);
    };

    if (!text) {
        return <></>;
    }

    return (
        <>
            {replacedContent(text).map((word, index) => (
                <Linkify
                    key={`word-${word}-${index}`}
                    componentDecorator={(decoratedHref, decoratedText, key) => {
                        if (
                            /^https:\/\/www.youtube\.com|https:\/\/www.youtu\.be/.test(
                                decoratedHref
                            )
                        ) {
                            return (
                                <YoutubeContent
                                    url={decoratedHref}
                                    message={text}
                                    key={key}
                                />
                            );
                        }

                        return (
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={decoratedHref}
                                key={key}
                            >
                                {getHostnameFromDomain(decoratedText)}
                            </a>
                        );
                    }}
                >
                    {isWordMention(word) ? (
                        <span
                            className="chat-mention-text"
                            onClick={onClick(replaceMentionHumanReadable(word))}
                        >
                            {replaceMentionHumanReadable(word)}
                        </span>
                    ) : (
                        <span className="chat-text">{word}</span>
                    )}
                </Linkify>
            ))}
        </>
    );
}

MessageContent.defaultProps = {
    showProfile: () => {},
};
