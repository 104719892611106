import jwt_decode from 'jwt-decode';
import moment from 'moment';

import { getAuthToken, removeAuthData } from './deviceService';
const ONE_HOUR = 60 * 60;

/**
 * Trigger signout
 *
 * @param {string} source
 * @param {object} rootStore RootStore instance
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export async function handleSignOut(source, rootStore) {
    // This should only be used when intending to navigate to Auth
    removeAuthData();

    // @FIXME: This should be updated as features complete
    // Refer mobile app AuthServie.handleSignOut
    rootStore?.bailiwikStore?.reset();
    rootStore?.userStore?.reset();
    rootStore?.chatStore?.reset();
    rootStore?.memberStore?.reset();
}

/**
 *  @returns {boolean|null} returns whether token is expired and
 *                          null if unable to decode token or token not found
 */
export const isAuthTokenExpired = () => {
    try {
        const TOKEN = getAuthToken();
        if (TOKEN) {
            const decodedToken = jwt_decode(TOKEN);
            if (decodedToken.exp) {
                // If present, decodedToken.exp is something like 1674735429
                // If you want to print the JavaScript date for this, multiply by 1000.
                // For example, if I signed in on Sept 28 2002, then
                // new Date(1674735429*1000) is
                // Thu Jan 26 2023 07:17:09 GMT-0500.
                // That is when the token expires.
                // In contrast, moment.unix() returns "number of seconds since unix Epoch"
                // and the return value is a moment object that can be used for comparing.
                // For example:
                // const s = moment.unix(decodedToken.exp);
                // returns a moment object with 1674735429000
                // representing Thu Jan 26 2023 07:17:09 GMT-0500

                // Next, subtract 1 HOUR from that expiration date, as a
                // buffer, in case there's a difference between our clock and
                // the server clock.
                const bufferedExp = moment.unix(decodedToken.exp - ONE_HOUR);
                // For example:
                // bufferedExp is a moment object with 1674731829000
                // representing Thu Jan 26 2023 06:17:09 GMT-0500
                // That's one hour prior to the actual expiry date.

                // Now, moment() returns something like 1664368917078.
                // Next, compare the current moment with the moment of the
                // expiration date.
                // "isAfter" checks if one moment is after another.
                // So this line checks to see if "now" is after the
                // expiration date (minus 1 hour padding).
                const isExpired = moment().isAfter(bufferedExp);
                // Want to see how far away bufferedExp is from right now?
                // Print this:
                // console.log("From now? " + bufferedExp.fromNow());
                // console.log("Were you expired? " + isExpired);
                return isExpired;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } catch (error) {
        console.log(error?.message);
        return null;
    }
};
