import React, { useState } from 'react';
import FirebaseMessages from '../chat/FirebaseChat';
import useMessages from '../../core/hooks/useMessages';
import { mapEventGuestUsers } from '../../utils';
import { useStores } from '../../lib/store';

export default function Messages({
    bailiwikId,
    sendLike,
    eventCtaClick,
    scrollableRef,
    toggleCommentModal,
    currentUser,
    isGuest,
    onEventDealEdit,
    onEventDealRepost,
    onEventDealDelete,
    report,
}) {
    const [page, setPage] = useState(0);
    const {
        bailiwikStore: { membersDetails },
    } = useStores();
    const messagesResponse = useMessages(`bailiwik/${bailiwikId}/events`, page);

    return (
        <FirebaseMessages
            setPage={setPage}
            messages={mapEventGuestUsers(messagesResponse, membersDetails)}
            type="event"
            like={(message) => sendLike(message, { currentUser })}
            eventCtaClick={eventCtaClick}
            ref={scrollableRef}
            isGuest={isGuest}
            toggleCommentModal={toggleCommentModal}
            currentUser={currentUser}
            onEventDealEdit={onEventDealEdit}
            onEventDealRepost={onEventDealRepost}
            onEventDealDelete={onEventDealDelete}
            report={report}
        />
    );
}
