import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../../core/contexts/FirebaseContext';

export default function Image({
    type,
    uri,
    ...rest
}) {
    const { firebase } = useFirebase();
    const [imageUri, setImageUri] = useState()
    
    const getImageUri = () => {
        firebase
            .storage()
            .ref(uri)
            .getDownloadURL()
            .then(setImageUri)
            .catch(e => console.log('>>> ERROR GET IMAGE DOWNLOAD URI', e))
    }

    useEffect(() => {
        if (!uri) {
            return setImageUri('/images/bg-theme1.png');
        }
        if (type === 'event' || type === 'deal') {
            return getImageUri();
        }

        setImageUri(`${process.env.ASSETS_URL}/${uri}`);
    }, [uri, type])

    if (imageUri === undefined) {
        return <></>;
    }

    return (
        <img
            src={imageUri}
            className="card-image"
            {...rest} />
    )
}