import { useState, useMemo, useEffect } from 'react'
import { useFirebase } from '../contexts/FirebaseContext'

export const useResetBadgesCount = (userId) => {
    const { firebase } = useFirebase()

    const resetBadges = (chatId) => {
        console.log("Reset badges called with userId " + userId + " chatId " + chatId);
        firebase
        .database()
        .ref(`users/${userId}/${chatId}`)
        .transaction(item => ({
                ...item,
                unseenCount: 0
            })
        )
    }

    return resetBadges
};

export const useBadgesCount = (userId) => {
    const [DMBadges, setDMBadges] = useState()
    const { firebase } = useFirebase()

    const badgeCount = useMemo(() => {
        if(!DMBadges) return 0
        let badges = 0

        const chatIds = Object.keys(DMBadges)

        chatIds.forEach(chatId => {
            badges = badges + DMBadges[chatId].unseenCount
        })

        return badges

    }, [DMBadges])

    const getData = () => firebase
        .database()
        .ref(`users/${userId}`)
        .on('value', (snapshot) => {
        const data = snapshot.val()

        if(!data) return 
        setDMBadges(data)
        
    })
    useEffect(() => {
        if(userId) {
        getData()
        }
    }, [userId])

    useEffect(() => {
        console.log('id-set-badges', badgeCount)
    }, [badgeCount])

    return {badgeCount, DMBadges}
};
