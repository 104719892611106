import * as amplitude from '@amplitude/analytics-browser';
import React from 'react';

/**
 * See documentation from Amplitude
 * https://www.docs.developers.amplitude.com/data/sdks/react-native/
 * @returns an instance of Amplitude that can be used for making calls
 */
const DEBUG = false;
const initAmplitude = () => {
  if (!process.env.AMPLITUDE_API_KEY) {
    console.error(`Amplitude key not found`);
    return;
  } else {
    console.log(`Amplitude analytics working`);
  }

  const level = DEBUG ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.Warn
  amplitude.init(process.env.AMPLITUDE_API_KEY, {
      serverZone: 'US',
      logLevel: level, // Use Warn or Debug
  })
  return amplitude;
};

const ampInstance = initAmplitude();

const AmplitudeInstanceContext = React.createContext(ampInstance);

export const useAmplitude = () => {
  const amplitude = React.useContext(AmplitudeInstanceContext);

  if (!amplitude) {
    throw new Error(
      'The component must be wrapped in AmplitudeProvider to use useAmplitude'
    );
  }
  if (DEBUG) {
    console.log('Returning ');
    console.log(amplitude);
  }
  return amplitude;
};

export const logToAmplitude = (userId, bailiwikId, bailiwikName, eventName, extras) => {
  try {
    const amplitude = ampInstance;
    if (!ampInstance) {
      throw new Error(
        'The component must be wrapped in AmplitudeProvider to use useAmplitude'
      );
    }
    if (!userId) {
      console.log('No userId, returning');
      return;
    }
    if (userId) {
      amplitude.setUserId(userId);
    }
    let properties = {};
    if (bailiwikId) {
      properties.BailiwikID = bailiwikId;
    }
    if (bailiwikName) {
      properties.bailiwikName = bailiwikName;
    }
    if (extras && extras.type) {
      // For example, 'Like' might have a type with it, 'direct' or 'text'
      properties.type = extras.type;
    }
    if (extras && extras.screen) {
      // For example, 'Post' might have a screen with it, 'direct' or 'home', to indicate where the post was made
      properties.screen = extras.screen;
    }
    if (DEBUG) {
      console.log(
        '(1) Using amplitude ' +
          userId +
          ' ' +
          eventName +
          ' ' +
          JSON.stringify(properties)
      );
    }
    amplitude.track(eventName, properties);
  } catch (e) {
    console.error(`ERROR tracking ` + e);
  }
};

export const useLogToAmplitude = () => {
  const amplitude = React.useContext(AmplitudeInstanceContext);
  if (!amplitude) {
    throw new Error(
      'The component must be wrapped in AmplitudeProvider to use useAmplitude'
    );
  }
  const myLogToAmplitude = (userId, bailiwikId, bailiwikName, eventName, extras) => {
    try {
      if (userId) {
        amplitude.setUserId(userId);
      }
      let properties = {};
      if (bailiwikId) {
        properties.BailiwikID = bailiwikId;
      }
      if (bailiwikName) {
        properties.bailiwikName = bailiwikName;
      }
      if (extras && extras.type) {
        // For example, 'Like' might have a type with it, 'direct' or 'text'
        properties.type = extras.type;
      }
      if (extras && extras.screen) {
        // For example, 'Post' might have a screen with it, 'direct' or 'home', to indicate where the post was made
        properties.screen = extras.screen;
      }
      if (DEBUG) {
        console.log(
          '(2) Using amplitude ' +
            userId +
            ' ' +
            eventName +
            ' ' +
            JSON.stringify(properties)
        );
      }
      amplitude.track(eventName, properties);
    } catch (e) {
      console.error(`ERROR ` + e);
    }
  };
  return myLogToAmplitude;
};

// See https://stackoverflow.com/questions/53371356/how-can-i-use-react-hooks-in-react-classic-class-component
export const withAmplitudeHook = (Component) => {
  return function WrappedComponent(props) {
    const amplitudeHookValue = useLogToAmplitude();
    return <Component {...props} amplitudeHook={amplitudeHookValue} />;
  }
}

export const AmplitudeProvider = ({ children }) => (
  <AmplitudeInstanceContext.Provider value={ampInstance}>
    {children}
  </AmplitudeInstanceContext.Provider>
);
