import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useStores } from '../../lib/store';

import { signup, updateUser } from '../../services/databaseService';
import {
    setRememberLogin,
    getStoredUserId,
    setAuthToken,
    setStoredUserId,
    setLoggedIn,
    getLoggedIn,
} from '../../services/deviceService';
import StepA from './components/StepA';
import StepB from './components/StepB';
// I think this is dead code. Probably used in archive-sign-up for some unknown reason.
function SignUp() {
    const { userStore, bailiwikStore, memberStore } = useStores();
    const [userData, setUserData] = useState({
        fullName: '',
        email: '',
        password: '',
        nickname: '',
        rememberMe: false,
    });
    const [avatar, setAvatar] = useState();
    const [processing, setProcessing] = useState(false);
    const history = useHistory();
    const [userId, setUserId] = useState(() => getStoredUserId());

    // already logged in?
    if (getLoggedIn()) {
        // has currnt user a bailiwik?
        if (bailiwikStore.current) {
            return <Redirect to="/chat" />;
        }
        return <Redirect to="/find-bailiwik" />;
    }

    const updateUserData = (field, value) => {
        setUserData({
            ...userData,
            [field]: value,
        });
    };

    const createProfile = () => {
        if (processing) {
            return;
        }
        setProcessing(true);

        const { email, password } = userData;
        signup({ email, password })
            .then((res) => {
                if (!res || !res.success) {
                    if (res.error) {
                        throw new Error(res.error);
                    }
                    throw new Error('Error response');
                }
                return res.user;
            })
            .then((user) => {
                setRememberLogin(userData.rememberMe);
                setAuthToken(user.token);
                setStoredUserId(user._id);
                setUserId(user._id);
                userStore.load('Sign Up');
                return user;
            })
            .then((user) => {
                const fd = new FormData();
                const { fullName, nickname } = userData;
                fd.append('user[fullName]', fullName);
                fd.append('user[displayName]', nickname);
                return updateUser(user._id, fd);
            })
            .then((res) => {
                if (!res || !res.success) {
                    throw new Error('Error response');
                }
                return true;
            })
            .catch((err) => {
                console.error(err);
                alert(err.error);
                return false;
            })
            .finally(() => setProcessing(false));
    };

    const uploadAvatar = () => {
        if (processing) {
            return false;
        }
        if (!avatar) {
            alert('Please upload your photo');
            return false;
        }
        setProcessing(true);

        const fd = new FormData();
        fd.append('user[appVersion]', process.env.APP_VERSION);
        fd.append('avatar', avatar);
        return updateUser(userId, fd)
            .then(async (res) => {
                if (!res || !res.success) {
                    throw new Error('Error response');
                }

                await userStore.load('SignUp upload photo');
                setLoggedIn(true);
                history.push('/find-bailiwik');
            })
            .catch((err) => {
                setProcessing(false);
                console.error(err);
                alert('Somethine went wrong.');
            });
    };

    const skipUpload = () => {
        setLoggedIn(true);
        history.push('/find-bailiwik');
    };

    return (
        <div className="tw-bg-blue-bg tw-h-screen">
            <div className="tw-max-w-lg tw-mx-auto">
                {userId ? (
                    <StepB
                        setAvatar={setAvatar}
                        uploadAvatar={uploadAvatar}
                        skipUpload={skipUpload}
                    />
                ) : (
                    <StepA
                        userData={userData}
                        setUserData={updateUserData}
                        createProfile={createProfile}
                    />
                )}
            </div>
        </div>
    );
}

export default observer(SignUp);
