import React from 'react';
import PropTypes from 'prop-types';

// import CameraButton from './CameraButton';
// import ImageButton from './ImageButton';
import ChatTextInput from './ChatTextInput';

/**
 * ChatToolbar component to display chat buttons
 *
 * @returns React.ReactElement
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function ChatToolbar({
    text,
    onTextChange,
    sendText,
    onMedia
}) {
    return (
        <div className="tw-bg-blue-header">
            <div className="tw-flex tw-flex-row tw-space-x-2 tw-px-4 md:tw-px-28 tw-py-3">
                {/* <CameraButton />
                <ImageButton /> */}
                <div className="media-button">
              <button type="button" onClick={() => onMedia()} className="button-bg button-upload w-flex-grow-0 tw-flex-shrink-0 tw-w-11 tw-h-11 tw-rounded-full tw-bg-white tw-overflow-hidden tw-mt-1">
                <div className="tw-w-6 tw-mx-auto">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 3.5H0.5L1.5 24H21.5V22.5H4L3 3.5Z" fill="#364C80" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5 0H27L26.5 20.5H6.5L5 0ZM9.00004 18L15 10L17.5 13.5L19.5 9L23.5 18H9.00004Z"
                      fill="#364C80"
                    />
                  </svg>
                </div>
              </button>
            </div>
                <ChatTextInput
                    text={text}
                    onChange={onTextChange}
                    send={sendText}
                />
            </div>
        </div>
    );
}

ChatToolbar.defaultProps = {
    text: '',
};

ChatToolbar.propTypes = {
    sendText: PropTypes.func.isRequired,
    onTextChange: PropTypes.func.isRequired,
    text: PropTypes.string,
};
