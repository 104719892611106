import React, {useState, useImperativeHandle} from 'react' 

const Toast = ({
    notification,
    closeNotificationOnAnimationEnd = true,
    position = 'bottom',
}, ref) => {
    const [notificationText, setNotificationText] = useState(notification)


    useImperativeHandle(ref, () => ({
        notify: (text) => setNotificationText(text)
    }))

    const handleEnd = () => {
        if (closeNotificationOnAnimationEnd) {
            setNotificationText(false)
        }
    }

    return (
        <>
            {notificationText && <div onAnimationEnd={handleEnd} className="toast-container d-flex justify-content-center" data-position={position || 'bottom'}>
                <p className="text-center">
                    {notificationText}
                </p>
            </div>}
        </>
    )
}

export default React.forwardRef(Toast)

