import { getAuthToken } from './deviceService';

const MAX_RETRY = 5;
const RETRY_TIMEOUT = 1500;

/**
 * Retries a fetch to the database and fails after a set number of retries above
 *
 * @param {string} url Remote url
 * @param {object} req Request payload
 * @param {string} source Source
 * @param {number} retryCount Retry counts
 *
 * @returns {Promise}
 *
 * @author Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
const fetchRetry = (url, req, source, retryCount) =>
    new Promise((resolve, reject) => {
        retryCount -= 1;

        console.log('DatabaseService retry count:', retryCount);
        if (retryCount === 0) {
            // Sentry.captureMessage('Toast message from connect timeout 4th try:' + source + ' url:' + url);
            // //console.log( 'Toast message from connect timeout 4th try:', source, ' url:', url );
            // MessageStore.toast({
            //     text: 'We\'re having trouble connecting to our server'
            // });
        }

        const fetchStart = new Date();
        const fullUrl = `${process.env.API_URL}/${url}`;
        console.log('Attempt fetch from source:', source, ' at url:', fullUrl);

        fetch(fullUrl, req)
            .then((res) => {
                if (res.status === 401) {
                    return reject(new Error('Unauthorized'));
                }
                console.log(
                    `Fetch time ${url}: source:${source}`,
                    new Date() - fetchStart
                );
                retryCount = MAX_RETRY;
                resolve(res);
            })
            .catch((error) => {
                setTimeout(
                    () => {
                        if (retryCount >= 0) {
                            console.log(
                                `Retrying connection ${retryCount} times... `
                            );

                            let fetchRetryStart = new Date();
                            fetchRetry(url, req, source, retryCount)
                                .then((res) => {
                                    console.log(
                                        `FetchRetry time ${url}: source:${source}`,
                                        new Date() - fetchRetryStart
                                    );
                                    resolve(res);
                                })
                                .catch((err) => {
                                    console.log(
                                        `Connection Failed - error ${err}`
                                    );
                                    reject(false);
                                });
                        } else {
                            console.log('SHOULD REJECT CALL');
                            reject(error);
                        }
                    },
                    retryCount
                        ? RETRY_TIMEOUT * Math.floor(MAX_RETRY / retryCount)
                        : RETRY_TIMEOUT
                );
            });
    });

/**
 * Fetch data from remote api
 *
 * @param {object} options
 *
 * @returns {Promise}
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
const fetchData = async ({ url, payload, token, headers, method, source }) => {
    let req = {
        method: method ? method : 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    };

    // Append body if payload is passed
    if (payload) {
        const body = { body: payload };
        req = Object.assign({ ...req }, body);
    }

    // Append token authorization header
    if (token) {
        const storageToken = await getAuthToken();
        const authHeader = { Authorization: `Token ${storageToken}` };
        req.headers = Object.assign({ ...req.headers }, authHeader);
    }
    //console.log(req, 'req', 'url', url);
    const data = await fetchRetry(url, req, source, MAX_RETRY);
    return await data.json();
};

const fetchDataFormData = async ({
    url,
    payload,
    token,
    headers,
    method,
    source,
}) => {
    let req = {
        method: method ? method : 'POST',
        headers: {
            Accept: 'application/json',
            ...headers,
        },
    };

    // Append body if payload is passed
    if (payload) {
        const body = { body: payload };
        req = Object.assign({ ...req }, body);
    }

    // Append token authorization header
    if (token) {
        const storageToken = await getAuthToken();
        const authHeader = { Authorization: `Token ${storageToken}` };
        req.headers = Object.assign({ ...req.headers }, authHeader);
    }
    //console.log(req, 'req', 'url', url);
    const data = await fetchRetry(url, req, source, MAX_RETRY);
    return await data.json();
};

/**
 * Get current user details
 *
 * @param {string} source Source
 *
 * @returns {Promise}
 *
 * @authr Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getUser = (source) =>
    fetchData({
        url: 'api/users/v2/current',
        token: true,
        source: source,
    });

export const getSystemAlert = (source) =>
    fetchData({
        url: 'api/process/system-alert/web',
        token: true,
        source: source,
        method: 'GET',
    });

/**
 * Get Bailiwik datab
 *
 * @param {string} id Bailiwik ID
 * @param {string} source From where this function calls
 *
 * @returns {Promise}
 *
 * @author Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getBailiwik = (id, source) =>
    fetchData({
        url: `api/bailiwiks/v2/${id}`,
        method: 'GET',
        token: true,
        source: source,
    });

/**
 * Get User account details
 *
 * @param {string} userId
 * @param {string} source
 *
 * @returns {Promise}
 *
 * @author Copied from Mobile App repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getUserAccount = (userId, source) =>
    fetchData({
        url: `api/users/get-user/${userId}`,
        method: 'GET',
        token: true,
        source: source,
        version: process.env.APP_VERSION,
    }).then((res) => {
        if (res?.success && res?.user) {
            return res.user;
        }
        return Promise.reject(res);
    });

/**
 * Login member
 *
 * @param {object} payload
 *
 * @returns {Promise}
 */
export const login = (payload) =>
    fetchData({
        url: `api/users/login`,
        payload: JSON.stringify({ user: payload }),
    });

export const socialLogin = (payload) =>
    fetchData({
        url: `api/users/social-auth`,
        payload: JSON.stringify({ user: payload }),
    });

/**
 * Get user bailiwiks
 *
 * @param {string} source
 *
 * @returns {Promise}
 */
export const getUserBailiwiks = (source) =>
    fetchData({
        url: `api/users/v2/getuserbailiwiks`,
        token: true,
        source: source,
    });

/**
 * Get bailiwik members
 *
 * @param {string} id
 * @param {string} source
 *
 * @returns {Promise}
 */
export const getMembers = (id, source) =>
    fetchData({
        url: `api/bailiwiks/${id}/members`,
        method: 'GET',
        token: true,
        source: source,
    });

/**
 * Reset password
 *
 * @param {object} payload
 *
 * @author Copied From Mobile APP Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const resetPassword = (payload) =>
    fetchData({
        url: `api/users/password-reset-token`,
        payload: JSON.stringify({ user: payload }),
    });

/**
 * Reset password
 *
 * @param {object} payload
 *
 * @author Copied From Mobile APP Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const resetConfirmToken = (payload) =>
    fetchData({
        url: `api/users/password-reset-confirm-token`,
        payload: JSON.stringify({ reset: payload }),
    });

/**
 * Reset password
 *
 * @param {object} payload
 *
 * @author Copied From Mobile APP Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const resetConfirm = (payload) =>
    fetchData({
        url: `api/users/password-reset-confirm`,
        payload: JSON.stringify({ reset: payload }),
    });

/**
 * Reset password
 *
 * @param {object} payload
 *
 * @author Copied From Mobile APP Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const passwordResetSecure = (payload) =>
    fetchData({
        url: `api/users/password-reset-secure`,
        payload: JSON.stringify({ reset: payload }),
        token: true,
    });

/**
 * Update user data
 *
 * @param {string} id User ID
 * @param {string} payload Payload string
 * @param {string} source Origin source
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const updateUser = (id, payload, source) =>
    fetchDataFormData({
        url: `api/users/${id}`,
        payload,
        method: 'PUT',
        token: true,
        source: source,
        version: process.env.APP_VERSION,
    });

/**
 * SignUp user
 *
 * @param {object} payload Signup data
 *
 * @author Copied from Mobile App Repo
 * @author Gihan S <gihanshp@gmail.com>
 */
export const signup = (payload) =>
    fetchData({
        url: 'api/users',
        payload: JSON.stringify({ user: payload }),
    }).then(async (res) => {
        if (res && res.user) {
            return Promise.resolve(res);
        } else {
            return Promise.reject(res);
        }
    });
