import React, { useCallback, useState, useRef, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Card from '../chat/Card';
import CommentsModal from '../chat/CommentsModal';
import JoinBailiwik from '../chat/JoinBailiwik';
import useEventCtaClick from '../../core/hooks/useEventCtaClick';
import useToggleLike from '../../core/hooks/useToggleLike';
import useGuestJoin from '../../core/hooks/useGuestJoin';
import useGuestSignup from '../../core/hooks/useGuestSignup';
import AddBadge from '../common/AddPostBadge';
import Header from '../header';
import Messages from './Messages';
import Modal from './Modal';
import ReportPost from '../chat/StandardModal/ReportPostModal';
import DeletePopup from './DeletePopup';
import './style.css';
import { getRepostData } from '../../core/utility/messageContent';

const EventScreen = (props) => {
    const eventCtaClick = useEventCtaClick();
    const toggleLike = useToggleLike();
    const guestJoin = useGuestJoin(props.currentBailiwik);
    const guestSignup = useGuestSignup(props.currentBailiwik);
    const [commentsMessage, setCommentsMessage] = useState();
    const [modal, setModal] = useState({ open: false, data: {}, type: '' });
    const scrollableRef = useRef();
    const { t } = useTranslation();
    const [messageToReport, setMessageToReport] = useState();
    const [messageToDelete, setMessageToDelete] = useState();
    const [messageToEdit, setMessageToEdit] = useState();

    const isMember =
        props.currentBailiwik?.members?.findIndex(
            (member) =>
                member === props.currentUser._id ||
                member._id === props.currentUser._id
        ) >= 0;

    const handleJoin = useCallback(
        (bailiwik) => {
            props.switchBailiwik(bailiwik);
            props.updateUserData();
        },
        [props]
    );

    const onCreateModalOpen = () =>
        setModal({ data: {}, open: true, type: 'create-edit' });

    /**
     * How can we force InfiniteScroll to reset when a user has finished a REPOST?
     * Getting "scrollableDiv" and setting scrollTop is hacky, but does the job.
     *
     * @param {*} isReposting if true, reset InfiniteScroll to bottom.
     */
    const onModalClose = (isReposting) => {
        if (isReposting) {
            const div = document.getElementById('scrollableDiv');
            if (div) {
                div.scrollTop = 0;
            }
        }
        setModal({ open: false, data: {}, type: '' });
    };

    const onEventDealEdit = (data) =>
        setModal({ open: true, data, type: 'create-edit' });

    const onEventDealRepost = (data) => {
        /*
        const repostData = {
            content : {
                event : {
                    allDay : data.content.event.allDay,
                    description : data.content.event.description,
                    image : data.content.event.image,
                    locationAddress: data.content.event.locationAddress,
                    locationName: data.content.event.locationName,
                    title: data.content.event.title,
                }
            }
        };*/
        const repostData = {
            content: {
                event: {
                    ...getRepostData(data.content.event),
                },
            },
        };
        setModal({ open: true, data: repostData, type: 'create-repost' });
    };

    const onEventDealDelete = (data) =>
        setModal({ open: true, data, type: 'delete' });

    const reportMessage = (message) => {
        {
            setMessageToReport(message);
        }
    };

    const onReportPost = async () => {
        let areReported = null;
        const item = messageToReport;
        const res = await reportMessage(item, user, areReported);

        if (!areReported && res.committed) {
            setMessageToReport(false);
            toastRef.current.notify('POST HAS BEEN REPORTED');
        } else {
            setMessageToReport(false);
            toastRef.current.notify('POST HAS BEEN UNREPORTED');
        }
    };

    const CommentsModalMemo = useMemo(() => {
        if (!commentsMessage) return;

        return (
            commentsMessage && (
                <CommentsModal
                    currentUser={props.currentUser}
                    parent={commentsMessage}
                    isEvent={true}
                    close={() => setCommentsMessage(false)}
                >
                    {(commentsMessage.content.event ||
                        commentsMessage.content.deal) && (
                        <Card
                            inThread={true}
                            like={() => {
                                toggleLike(
                                    commentsMessage,
                                    { currentUser: props.currentUser },
                                    (item) => {
                                        setCommentsMessage({
                                            ...commentsMessage,
                                            likes: item.likes,
                                        });
                                    }
                                );
                            }}
                            toggleModal={setCommentsMessage}
                            currentUser={props.currentUser}
                            message={commentsMessage}
                            eventCtaClick={(item, status) =>
                                eventCtaClick(
                                    commentsMessage,
                                    status,
                                    (response) => {
                                        setCommentsMessage({
                                            ...item,
                                            content: {
                                                event: {
                                                    ...item.content.event,
                                                    ...response.event,
                                                },
                                            },
                                        });
                                    }
                                )
                            }
                            key={commentsMessage['_id']}
                        />
                    )}
                </CommentsModal>
            )
        );
    }, [commentsMessage, props.currentUser]);

    return (
        <>
            <Helmet>
                <title>
                    Bailiwik {t('Events')} : {props.currentBailiwik.name}
                </title>
                {props.currentBailiwik.description && (
                    <meta
                        name="description"
                        content={
                            `Events in our local community! ` +
                            props.currentBailiwik.description
                        }
                    />
                )}
            </Helmet>

            {(messageToDelete || messageToEdit || messageToReport) && (
                <Modal
                    onClose={() =>
                        setMessageToDelete(false) ||
                        setMessageToEdit(false) ||
                        setMessageToReport(false)
                    }
                    title={
                        messageToDelete
                            ? 'Confirm Delete'
                            : messageToEdit
                            ? 'Edit Message'
                            : 'Confirm Report'
                    }
                >
                    {messageToDelete && (
                        <DeleteModal
                            onClose={() =>
                                setMediaModalData(false) ||
                                setMessageToDelete(false)
                            }
                            onConfirm={
                                messageToDelete.content.text
                                    ? onRemoveMessage
                                    : onRemoveMedia
                            }
                        />
                    )}
                    {messageToEdit && (
                        <EditTextModal
                            text={messageToEdit.content.text}
                            onPost={handleUpdate}
                        />
                    )}
                    {messageToReport && (
                        <ReportPost
                            onClose={() => setMessageToReport(false)}
                            onConfirm={onReportPost}
                        />
                    )}
                </Modal>
            )}

            {CommentsModalMemo}
            <Header
                isGuest={props.isGuest}
                bailiwiks={props.bailiwiks}
                switchBailiwik={props.switchBailiwik}
                bailiwik={props.currentBailiwik}
                isMember={isMember}
                currentUser={props.currentUser}
            />
            <div ref={scrollableRef} className="event-container">
                {props.isGuest ? (
                    <div className="add-badge">
                        <AddBadge
                            isGuest={true}
                            buttonText="LOGIN"
                            onClick={guestJoin}
                        />
                        <p className="text-lowercase">or</p>
                        <AddBadge
                            textClassName="text-white"
                            className="ab-container white-outline"
                            isGuest={true}
                            buttonText="SIGN UP"
                            onClick={guestSignup}
                        />
                    </div>
                ) : (
                    <div className="add-badge add-badge-sm">
                        <AddBadge
                            type="event"
                            isGuest={false}
                            onClick={onCreateModalOpen}
                        />
                    </div>
                )}
                {!isMember && !props.isGuest && (
                    <JoinBailiwik
                        onJoin={handleJoin}
                        bailiwik={props.currentBailiwik}
                        toggleBottomNav={props.toggleBottomNav}
                    />
                )}
                <Messages
                    type="event"
                    sendLike={toggleLike}
                    scrollableRef={scrollableRef}
                    eventCtaClick={eventCtaClick}
                    isGuest={props.isGuest}
                    toggleCommentModal={setCommentsMessage}
                    currentUser={props.currentUser}
                    bailiwikId={props.currentBailiwik._id}
                    onEventDealDelete={onEventDealDelete}
                    onEventDealEdit={onEventDealEdit}
                    onEventDealRepost={onEventDealRepost}
                    report={setMessageToReport}
                />
            </div>
            {modal.open && modal.type === 'create-edit' && (
                <Modal
                    user={{ currentUser: props.currentUser }}
                    bailiwik={props.currentBailiwik}
                    data={modal.data}
                    onClose={onModalClose}
                />
            )}
            {modal.open && modal.type === 'create-repost' && (
                <Modal
                    user={{ currentUser: props.currentUser }}
                    bailiwik={props.currentBailiwik}
                    data={modal.data}
                    onClose={onModalClose}
                />
            )}
            {modal.open && modal.type === 'delete' && (
                <DeletePopup data={modal.data} onClose={onModalClose} />
            )}
        </>
    );
};

export default EventScreen;
