import React, { useState } from 'react';
import { useFirebase } from '../../core/contexts/FirebaseContext';
import useSocialLogin from '../../core/hooks/useSocialLogin';
import useLogin from '../../core/hooks/useLogin';
import { useLogToAmplitude } from '../../core/utility/amplitude';
import Loader from '../Loader';

export default function SocialLogin({ reverseOr }) {
    const [showLoader, setShowLoader] = useState(false);
    const { firebase } = useFirebase();
    const socialLogin = useSocialLogin('Social Login');
    const login = useLogin('Social Login');
    const logToAmplitude = useLogToAmplitude();

    const onSignInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account',
        });

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((response) => {
                setShowLoader(true);
                console.log('response', response);
                console.log('response operation', response.operation);
                console.log(
                    `email: ${response.user.email}, display name: ${response.user.displayName}`
                );
                socialLogin(
                    response.additionalUserInfo.profile.email,
                    response.user.uid,
                    response.user.displayName,
                    // See #702
                    // response.additionalUserInfo.profile.picture
                );
            });
    };

    const onSignInWithApple = () => {
        const provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((response) => {
                setShowLoader(true);
                socialLogin(response.user.email, response.user.uid);
            });
    };

    const onGuestSignIn = () => {
        // Same event recorded in sign-in area.
        logToAmplitude(null, null, null, 'Guest Sign In Clicked 1');
        login(process.env.GUEST_EMAIL, process.env.GUEST_PASSWORD, true,
            () => {
                // when "logging in" as Guest, no bw recorded.
                logToAmplitude(null, null, null, 'Guest Sign In Clicked 2');
            });
    };

    return (
        <div className="social-login-buttons-wrapper">
            {!reverseOr && <span>OR</span>}
            <button
                onClick={onSignInWithGoogle}
                className="sign-in-form-btn google"
            >
                <img src="/images/icons/google.png" alt="google" />
                Sign in with Google
            </button>
            <button
                onClick={onSignInWithApple}
                className="sign-in-form-btn apple"
            >
                <img src="/images/icons/apple.png" alt="apple" />
                Sign in with Apple
            </button>
            <button
                type="button"
                onClick={onGuestSignIn}
                className="sign-in-form-btn apple"
            >
                Browse as a guest
            </button>
            {reverseOr && <span>OR</span>}
            {showLoader && <Loader />}
        </div>
    );
}
