import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Contextual popup for messages
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function ContextualPopUp({ buttons, closeHandler }) {
    const node = useRef();
    const { like, remove, edit } = buttons;

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return; // clicked inside
        }
        closeHandler(); // hide on outside click
    };

    // attach/deattach event for detecting click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            data-test-id="context-popup"
            ref={node}
            className="tw-bg-white tw-rounded-full tw-p-1 tw--ml-4 tw--mr-4"
        >
            <div className="tw-flex tw-space-x-2">
                {like !== undefined && (
                    <div data-test-id="btn-like" onClick={like}>
                        <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="19" cy="19" r="19" fill="#4099B3" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8 27.6452H12.129V15.2581H8V27.6452ZM30.7097 16.2903C30.7097 15.1548 29.7806 14.2258 28.6452 14.2258H22.1316L23.1123 9.50839L23.1432 9.17806C23.1432 8.75484 22.9677 8.36258 22.689 8.08387L21.5948 7L14.8026 13.8026C14.4206 14.1742 14.1935 14.6903 14.1935 15.2581V25.5806C14.1935 26.7161 15.1226 27.6452 16.2581 27.6452H25.5484C26.4052 27.6452 27.1381 27.129 27.4477 26.3858L30.5652 19.1084C30.6581 18.871 30.7097 18.6232 30.7097 18.3548V16.3832L30.6994 16.3729L30.7097 16.2903Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                )}
                {remove !== undefined && (
                    <div data-test-id="btn-delete" onClick={remove}>
                        <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="19" cy="19" r="19" fill="#4099B3" />
                            <path
                                d="M13 26C13 27.1 13.9 28 15 28H23C24.1 28 25 27.1 25 26V14H13V26ZM26 11H22.5L21.5 10H16.5L15.5 11H12V13H26V11Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                )}
                {edit !== undefined && (
                    <div data-test-id="btn-edit" onClick={edit}>
                        <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="19" cy="19" r="19" fill="#4099B3" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11 23.2496V26.9996H14.75L25.81 15.9396L22.06 12.1896L11 23.2496ZM28.71 13.0396C29.1 12.6496 29.1 12.0196 28.71 11.6296L26.37 9.28957C25.98 8.89957 25.35 8.89957 24.96 9.28957L23.13 11.1196L26.88 14.8696L28.71 13.0396Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
}

ContextualPopUp.defaultProps = {
    buttons: {},
};

ContextualPopUp.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    buttons: PropTypes.object
};
