import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Profile from './Profile';

export default function ProfileRouter({ members, bailiff, hosts = [] }) {
    const { memberId } = useParams();
    const history = useHistory();

    const member = useMemo(() => {
        return members.find((member) => member._id === memberId);
    }, [members, memberId]);

    useEffect(() => {}, []);

    const isHost = () => {
        return hosts.some((host) => host._id === memberId);
    };

    if (!member) {
        return null;
    }

    return (
        <Profile
            data={member}
            closeHandler={() => {
                history.goBack();
            }}
            isBailiff={bailiff._id === memberId}
            isHost={isHost()}
            top="tw-top-10"
        />
    );
}
