import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Set google analytics page views
 * 
 * @param {string} trackingId Google analytics ID
 * 
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function useTracking(trackingId) {
    const { listen } = useHistory();

    useEffect(() => {
        const unlisten = listen((location) => {
            if (!window.gtag) {
                return;
            }
            window.gtag('config', trackingId, { page_path: location.pathname });
        });
        return () => {
            unlisten();
        };
    }, [trackingId, listen]);
}
