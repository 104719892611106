import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RedeemDealModal from '../StandardModal/RedeemDealModal';
import Modal from '../StandardModal';
import Toast from '../../toast';
import Image from '../Image';
import RecentComment from './RecentComment';
import Linkify from 'react-linkify';
import './style.css';
import { useProfilePreview } from '../../../core/contexts/ProfilePreviewContext';
import { sanitize } from '../../../lib/utils';
import MessageContent from '../../MessageContent';
import { ref } from 'joi';

const avatar =
    'https://thumbs.dreamstime.com/b/profile-placeholder-image-gray-silhouette-no-photo-person-avatar-default-pic-used-web-design-127393540.jpg';
const convertDbTime = (timeStamp) => {
    return moment(timeStamp).format('MMM. DD HH:mm A');
};
const Card = (props) => {
    const isUserGuest = props.currentUser.email === process.env.GUEST_EMAIL;
    const { onProfilePopupOpen } = useProfilePreview();
    const toastRef = useRef();
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [lineClampTitle, setLineClampTitle] = useState(true);
    const [lineClampDesc, setLineClampDesc] = useState(true);
    const [lineClampLoc, setLineClampLoc] = useState(true);
    const type = useMemo(() => props.message.type.toLowerCase(), [props]);
    const [toolbar, setToolbar] = useState(false);
    const actionsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionsRef.current && !actionsRef.current.contains(event.target)) {
              setToolbar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
   
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toolbar]);

    const handleClick = () => {
        if (!isUserGuest) {
          setToolbar((toolbar) => !toolbar);
        }
    };

    const redeem = useMemo(
        () =>
            type === 'deal'
                ? props.message.content.deal.redeemed?.indexOf(
                      props.currentUser._id
                  ) >= 0
                : false,
        [props]
    );
    const { t } = useTranslation();

    const userWillGo =
        type === 'event'
            ? useMemo(
                  () =>
                      props.message.content[type].guests?.filter(
                          (guest) => guest.rsvpStatus === 1
                      ),
                  [props.message.content, type]
              )
            : [];

    const guestStatus = useMemo(() => {
        if (type !== 'event') return;

        const isGuest = props.message.content.event.guests?.find(
            (guest) => guest?.user?._id === props.currentUser._id
        );
        return isGuest ? isGuest.rsvpStatus : false;
    }, [props.currentUser._id, props.message.content.event, type]);

    const renderUsersWillGo = () => {
        if (
            userWillGo?.length > 2 &&
            userWillGo[0].user &&
            userWillGo[1].user &&
            userWillGo[2].user
        ) {
            return `${userWillGo[0]?.user?.fullName}, ${
                userWillGo[1]?.user?.fullName
            } ${t('and')} ${userWillGo.length - 2} ${t('users are going')}`;
        }

        if (
            userWillGo &&
            userWillGo[0]?.user?.fullName &&
            userWillGo[1]?.user?.fullName
        ) {
            return `${userWillGo[0]?.user?.fullName} ${t('and')} ${
                userWillGo[1]?.user?.fullName
            } ${t('are going')}`;
        }

        if (userWillGo && userWillGo[0]?.user) {
            return `${userWillGo[0]?.user?.fullName} ${t('is going')}`;
        }

        return t('No members are going');
    };

   

    return (
        <>
            {confirmationModal && (
                <Modal
                    title={t('Confirm To Redeem')}
                    onClose={() => setConfirmationModal(false)}
                >
                    <RedeemDealModal
                        title={props.message.content.deal.title}
                        onConfirm={() => {
                            props.redeem(props.message);
                            setConfirmationModal(false);
                            toastRef.current.notify('DEAL REDEEMED');
                        }}
                        onClose={() => setConfirmationModal(false)}
                    />
                </Modal>
            )}
            <Toast ref={toastRef} />
            <div key={`card-${props.message['_id']}`} className="card-container tw-max-w-2xl">
                {redeem && (
                    <div
                        className="card-redeem-wrapper"
                        data-creator={
                            props.currentUser._id === props.message.creator._id
                        }
                    >
                        {false &&
                            props.currentUser._id ===
                                props.message.creator._id && (
                                <button
                                    onClick={() =>
                                        !isUserGuest &&
                                        props.onEventDealDelete(props.message)
                                    }
                                    className="event-deal-action active-action right m-0"
                                >
                                    {t('DELETE')}
                                </button>
                            )}
                    </div>
                )}
                <div
                    ref={(ref) => {
                        if (!ref) return;
                        const width = ref.offsetWidth;
                        ref.style.height = `${width}px`;
                    }}
                    className="card-image-container"
                >
                    <Image
                        type="event"
                        uri={props.message.content[type].image}
                    />
                </div>
                <div className="card-data-container">
                    <div className="card-author-container">

                        <div className="container text-left">
                            <div className="row">
                                <div className="col-2 col-sm-2 pl-0 pr-0 d-flex justify-content-center align-items-center">

                                    <div className="card-author-image">
                                        <img
                                            src={
                                                props.message?.creator?.avatar
                                                    ? `${process.env.ASSETS_URL}/` +
                                                    props.message?.creator.avatar
                                                    : avatar
                                            }
                                            onClick={() =>
                                                !isUserGuest &&
                                                onProfilePopupOpen(props.message?.creator)
                                            }
                                        />
                                    </div>

                                </div>    

                                <div className="col-6 col-sm-8 pl-0">
                                    <div className="card-author-data tw-flex-grow">
                                        <p className="card-author-name">
                                            {convertDbTime(
                                                props.message.content[type].startTime
                                            )}{' '}
                                            -{' '}
                                            {convertDbTime(
                                                props.message.content[type].endTime
                                            )}
                                        </p>
                                        <p
                                            className={`card-author-desc ${
                                                lineClampTitle ? 'tw-line-clamp-1' : ''
                                            } md:tw-line-clamp-none`}
                                            onClick={() => {
                                                !isUserGuest &&
                                                    setLineClampTitle((state) => !state);
                                            }}
                                        >
                                            {props.message.content[type].title}
                                        </p>
                                        
                                        <p
                                            className={`card-author-desc ${
                                                lineClampLoc ? 'tw-line-clamp-1' : ''
                                            } md:tw-line-clamp-none`}
                                            onClick={() => {
                                                !isUserGuest &&
                                                    setLineClampLoc((state) => !state);
                                            }}
                                        >
                                            {props.message.content[type].locationName}
                                        </p>
                                    </div>
                                    
                                </div>
                                <div className="card-type-col col-4 col-sm-2 d-flex justify-content-center align-items-center">
                                   <p className="card-type">{props.message.type}</p>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-12 pt-3">
                                        <Linkify>
                                            <p
                                                className={`card-author-desc ${
                                                    lineClampDesc ? 'tw-line-clamp-1' : ''
                                                } md:tw-line-clamp-none`}
                                                onClick={() => {
                                                    !isUserGuest &&
                                                        setLineClampDesc((state) => !state);
                                                }}
                                            >
                                                <MessageContent
                                                            showProfile={
                                                                !MessageContent &&
                                                                onProfilePopupOpen
                                                            }
                                                            text={sanitize(
                                                                props.message.content[type].description
                                                            )}
                                                        />
                                            </p>
                                        </Linkify>
                                </div>
                            </div>  
                        </div>
                        <div className={`${props.message.type} card-author-data pl-0 d-flex justify-content-left align-items-center`} >
                            <div
                                    ref={actionsRef}
                                    onClick={handleClick}
                                   
                                    style={{ textAlign: 'end', zIndex: 2}}
                                    className="media-post-actions-wrapper tw-flex-shrink-0"
                            >
                                {toolbar && (
                                        <div className="media-post-actions">
                                            <ul>
                                                {props.report && (
                                                    <li
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (!isUserGuest) {
                                                                props.report(props.message);
                                                            }
                                                        }}
                                                    >
                                                        Report
                                                    </li>
                                                )}

                                                {props.message.creator._id ===
                                                    props.currentUser._id && (
                                                    <>
                                                        <li
                                                            onClick={() =>
                                                                !isUserGuest &&
                                                                props.onEventDealEdit(props.message)
                                                            }
                                                        >
                                                            Edit
                                                        </li>
                                                        <li
                                                            onClick={() =>
                                                                !isUserGuest &&
                                                                props.onEventDealRepost(props.message)
                                                            }
                                                        >
                                                            Repost
                                                        </li>
                                                        <li
                                                            onClick={() =>
                                                                !isUserGuest &&
                                                                props.onEventDealDelete(props.message)
                                                            }
                                                        >
                                                            Delete
                                                        </li>
                                                    </>
                                            )}        
                                            </ul>
                                        </div>
                                )}
                                <i className="fas fa-ellipsis-v media-more"></i>
                            </div>
                        </div>

                    </div>

                    {props.threads?.length > 0 && !props.inThread && (
                        <div className="card-comment-container">
                            <p
                                onClick={() =>
                                    !isUserGuest &&
                                    props.toggleModal(props.message)
                                }
                                className="card-view-more"
                            >
                                {props.threads?.length - 1 > 0
                                    ? `${t('VIEW')} ${
                                          props.threads?.length - 1
                                      } ${t('MORE COMMENTS')}`
                                    : ''}
                            </p>
                            <p className="card-comment-content">
                                {props.threads?.length > 0 &&
                                    !props.inThread && (
                                        <RecentComment
                                            thread={props.threads[0]}
                                        />
                                    )}
                            </p>
                        </div>
                    )}
                    <div className="media-card-actions-container tw-flex">
                        <div className="card-likers-img">
                            {props.message.likes.users &&
                                props.message.likes.users.map((user) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <div key={`card-likers-${user._id}-${props.message['_id']}`}  className="card-likers">
                                        <img
                                            src={
                                                user.avatar
                                                    ? `${process.env.ASSETS_URL}/${user.avatar}`
                                                    : avatar
                                            }
                                            onClick={() =>
                                                !isUserGuest &&
                                                onProfilePopupOpen(user)
                                            }
                                        />
                                    </div>
                                ))}
                        </div>
                        <div className="card-likes tw-flex-grow tw-w-full">
                            <div>
                                {props.message.likes.count > 0 && (
                                    <p className="card-likes-count">
                                        {props.message.likes.users[0]?.fullName}{' '}
                                        {
                                            props.message.likes?.users[2]
                                                ?.fullName
                                        }{' '}
                                        {props.message.likes.count - 1
                                            ? '& others'
                                            : ''}{' '}
                                        like this
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="card-actions tw-flex-shrink-0">
                            <div className="actions-wrapper">
                                <div
                                    onClick={
                                        !isUserGuest ? props.like : () => {}
                                    }
                                    className="media-btn-container"
                                >
                                    <img
                                        src={`/images/icons/${
                                            props.message.likes.users?.find(
                                                (user) =>
                                                    user._id ===
                                                    props.currentUser._id
                                            )
                                                ? 'like-theme'
                                                : 'thumbs-up'
                                        }.png`}
                                        alt="like-btn"
                                        className={`media-like ${
                                            !props.inThread ? 'mr-3' : ''
                                        }`}
                                    />
                                    {props.message.likes.count ? (
                                        <div
                                            className={
                                                redeem
                                                    ? 'media-display-num-dark media-display-num'
                                                    : 'media-display-num'
                                            }
                                        >
                                            {props.message.likes.count}
                                        </div>
                                    ) : null}
                                </div>
                                {!props.inThread && (
                                    <div
                                        onClick={() =>
                                            !isUserGuest &&
                                            props.toggleModal(props.message)
                                        }
                                        className="media-btn-container"
                                    >
                                        <img
                                            src="/images/icons/thread.png"
                                            alt="comment-btn"
                                            className="media-comment"
                                        />
                                        {props.threads?.length ? (
                                            <div
                                                className={
                                                    redeem
                                                        ? 'media-display-num-dark media-display-num'
                                                        : 'media-display-num'
                                                }
                                            >
                                                {props.threads?.length}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {type === 'event' && (
                        <div className="media-card-actions-container">
                            <div className="card-likers-img">
                                {userWillGo?.map((guest, i) => {
                                    // eslint-disable-next-line react/jsx-key
                                    return (
                                        <div
                                            key={`card-likers-event-${guest?.user?._id || i}-${props.message['_id']}-${i}`}
                                            className="card-likers"
                                        >
                                            <img
                                                src={
                                                    guest?.user?.avatar
                                                        ? `${process.env.ASSETS_URL}/${guest.user.avatar}`
                                                        : avatar
                                                }
                                                onClick={() =>
                                                    !isUserGuest &&
                                                    onProfilePopupOpen(
                                                        guest?.user
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="card-likes">
                                <div>
                                    <p className="card-likes-count">
                                        {renderUsersWillGo(userWillGo)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mt-3 event-deal-actions">
                        {type === 'event' ? (
                            <>
                                <button
                                    onClick={() =>
                                        !isUserGuest &&
                                        props.eventCtaClick(
                                            props.message,
                                            'MAYBE'
                                        )
                                    }
                                    className={`event-deal-action ${
                                        guestStatus === 2 ? 'active-action' : ''
                                    }`}
                                >
                                    {t('INTERESTED')}
                                </button>
                                <button
                                    onClick={() =>
                                        !isUserGuest &&
                                        props.eventCtaClick(
                                            props.message,
                                            'YES'
                                        )
                                    }
                                    className={`event-deal-action ${
                                        guestStatus === 1 ? 'active-action' : ''
                                    }`}
                                >
                                    {t('GOING')}
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={() =>
                                    !isUserGuest
                                        ? setConfirmationModal(true)
                                        : () => {}
                                }
                                className={`event-deal-action ${
                                    !redeem ? 'active-action' : ''
                                }`}
                            >
                                {redeem ? t('REDEEMED') : t('REDEEM')}
                            </button>
                        )}
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default Card;
