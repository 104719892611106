import React from 'react'

const icons = {
    like: 'M0.82605 16.2998H3.87794V7.08886H0.82605V16.2998ZM17.6115 7.85643C17.6115 7.0121 16.9248 6.32128 16.0855 6.32128H11.2711L11.996 2.81345L12.0189 2.56783C12.0189 2.25312 11.8892 1.96144 11.6831 1.7542L10.8744 0.948242L5.85403 6.00657C5.57173 6.2829 5.40388 6.66669 5.40388 7.08885V14.7646C5.40388 15.609 6.09055 16.2998 6.92982 16.2998H13.7966C14.4299 16.2998 14.9716 15.916 15.2005 15.3633L17.5046 9.95191C17.5733 9.77537 17.6115 9.59115 17.6115 9.39158V7.92551L17.6038 7.91784L17.6115 7.85643Z',
    'light-like': 'M19,49H1c-0.6,0-1-0.4-1-1V19c0-0.6,0.5-1,1-1H9c0.6,0,1,0.5,1,1v29C10,48.6,9.5,49,9,49z M2,47h6V20H2V47z M25.8,47  C25.8,47,25.8,47,25.8,47c-0.7,0-1.4,0-2.1,0c-2.7-0.1-5.9-0.6-8.2-0.9c-1.1-0.2-2.1-0.3-2.7-0.3c-0.5,0-0.9-0.5-0.9-1V21.1  c0-0.4,0.3-0.8,0.6-0.9l0.5-0.2c2.3-0.8,7-2.6,9.4-5.2c2.6-2.7,3.8-6.4,3.8-10.8c0.1-1.4,0.9-4,3.6-4c0.5,0,1.1,0.1,1.8,0.3  c0.1,0,0.3,0.1,0.4,0.2c1.1,1.1,4.7,7.6,1.3,17.5H39c0,0,0,0,0,0c0.4,0,3.4,0.1,4.6,3.9c0.2,0.5,1,2.6-0.8,5.2  c0.5,0.9,1.3,2.8,0.4,4.8c-0.1,0.2-0.5,1.4-1.3,2.2c0.5,1.7,0.4,4.5-2,6.5c-0.1,1.2-0.6,4-3,5C36.6,45.7,32.4,47,25.8,47z M14,43.9  c0.5,0.1,1.2,0.2,1.9,0.3c2.3,0.3,5.4,0.7,8,0.9c7.4,0.3,12.3-1.3,12.3-1.3c1.7-0.7,1.7-3.6,1.7-3.6c0-0.3,0.2-0.6,0.4-0.8  c2.8-2,1.5-5,1.5-5.2c-0.2-0.5,0-1,0.5-1.3c0.4-0.2,0.9-1.1,1.1-1.6c0.8-1.8-0.5-3.5-0.5-3.5c-0.3-0.4-0.3-0.9,0-1.3  c1.7-2,1-3.6,1-3.7c0,0,0-0.1-0.1-0.1C41,20.1,39.2,20,39.1,20h-7.2c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.2-0.6-0.1-0.9  c3.5-9.1,0.7-15.3-0.2-16.6C30.4,2,30.1,2,29.9,2c-1.4,0-1.5,1.8-1.5,2c0,4.9-1.4,9-4.3,12.1c-2.7,2.9-7.5,4.7-10,5.6V43.9z M5,45  c-0.6,0-1-0.4-1-1c0-0.6,0.5-1,1-1s1,0.5,1,1C6,44.6,5.5,45,5,45z',
    remove: 'M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306',
    message: 'M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z',
    edit: 'M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z',
    search: 'M11.71 11H12.5L17.49 16L16 17.49L11 12.5V11.71L10.73 11.43C9.59 12.41 8.11 13 6.5 13C2.91 13 0 10.09 0 6.5C0 2.91 2.91 0 6.5 0C10.09 0 13 2.91 13 6.5C13 8.11 12.41 9.59 11.43 10.73L11.71 11ZM2 6.5C2 8.99 4.01 11 6.5 11C8.99 11 11 8.99 11 6.5C11 4.01 8.99 2 6.5 2C4.01 2 2 4.01 2 6.5Z',
    backArrow: 'M3.83 7L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7H3.83Z',
}


const IconSVG = ({
    icon,
    color = "#fff",
    viewBox = "0 0 19 22",
    width = "25",
    height = "25"
}) => {
    return (
        <div style={{display:"flex"}}><svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg"><path d={icons[icon]} fill={color}></path></svg></div>
    )
}

export default IconSVG