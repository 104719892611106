import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextMessage from './TextMessage';
import Creator from './Creator';
import ContextualPopUp from './ContextualPopUp';

import './index.css';
import LikeIcon from './LikeIcon';
import useToggleLike from './hooks/useToggleLike';

/**
 * Render contextual popup
 */
const ContextualPopUpContainer = ({
    setIsContextPopUp,
    deleteHandler,
    editHandler,
    toggleLike,
    message,
    refMessage,
}) => {
    const refPopup = useRef(null);
    useEffect(() => {
        if (!refPopup.current || !refMessage) {
            return;
        }
        const { current: popup } = refPopup;
        const { current: msg } = refMessage;
        if (popup.clientWidth > msg.clientWidth) {
            popup.style.right = '1rem';
        } else {
            popup.style.left = '-1rem';
        }
        popup.style.visibility = 'visible';
    }, []);
    return (
        <div ref={refPopup} className="tw-absolute tw--bottom-4 tw-invisible">
            {
                <ContextualPopUp
                    closeHandler={() => {
                        setIsContextPopUp(false);
                    }}
                    buttons={{
                        remove: () => {
                            deleteHandler(message);
                            setIsContextPopUp(false);
                        },
                        edit: () => {
                            editHandler(message);
                            setIsContextPopUp(false);
                        },
                        like: toggleLike,
                    }}
                />
            }
        </div>
    );
};
ContextualPopUpContainer.propTypes = {
    setIsContextPopUp: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    editHandler: PropTypes.func.isRequired,
    toggleLike: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    refMessage: PropTypes.object.isRequired
}


/**
 * Sent Component
 *
 * This component display sent messages
 *
 * @param {object} props
 *
 * @returns React.ReactElement
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function Sent({
    message,
    deleteHandler,
    author,
    editHandler,
    memberId,
    myProfile,
    toggleLike
}) {
    const { content, edited } = message;
    const [isContextPopUp, setIsContextPopUp] = useState(false);
    // const toggleLike = useToggleLike(message, memberId);
    const refMessage = useRef(null);

    const iLiked =  Object.values(message?.likes?.users || {}).find((user) => {
        if (typeof user === 'string') {
            return myProfile._id === user;
        }
        return user._id === myProfile._id;
    });

    return (
        <div className="sent tw-text-right">
            <div
                data-test-id="sent-clickable"
                data-message-id={message._id}
                className="tw-m-3.5 tw-relative tw-inline-block"
                onClick={(e) => {
                    // don't mess with clickable links
                    if(e.target.href) {
                        return;
                    }
                    setIsContextPopUp(!isContextPopUp);
                }}
            >
                <div
                    ref={refMessage}
                    className="tw-bg-orange-chat tw-text-white tw-p-2.5 tw-rounded-lg tw-rounded-br-none"
                >
                    {content.text && (
                        <TextMessage
                            text={content.text}
                            sender
                            edited={edited}
                        />
                    )}
                </div>
                <div className="arrow-ne tw-absolute tw-right-0"></div>
                {message.likes.count > 0 && (
                    <LikeIcon
                        fillColor="#FFFFFF"
                        bgColor="tw-bg-orange-chat"
                        count={message.likes.count}
                        left
                        iLiked={iLiked}
                    />
                )}
                {isContextPopUp && (
                    <ContextualPopUpContainer
                        setIsContextPopUp={setIsContextPopUp}
                        deleteHandler={deleteHandler}
                        editHandler={editHandler}
                        toggleLike={() => toggleLike(message)}
                        message={message}
                        refMessage={refMessage}
                    />
                )}
            </div>
            <Creator message={message} author={author} sender />
        </div>
    );
}

Sent.propTypes = {
    message: PropTypes.object.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    author: PropTypes.object.isRequired,
    myProfile: PropTypes.object.isRequired,
    editHandler: PropTypes.func.isRequired,
    memberId: PropTypes.string.isRequired,
};
