import React, { useEffect, useState, useRef, useMemo } from 'react';
import RightMessage from './right-message';
import LeftMessage from './left-message';
import ActionsToolbar from './ActionsToolbar';
import MediaPost from '../media-post';
import Card from './Card';
import { useFirebase } from '../../core/contexts/FirebaseContext';
import { useProfilePreview } from '../../core/contexts/ProfilePreviewContext';
import { useStores } from '../../lib/store';

const styles = {
    container: {
        position: 'relative',
    },

    toolbarLeft: {
        display: 'flex',
        position: 'absolute',
        bottom: '20%',
        left: '10%',
    },
    toolbarRight: {
        display: 'flex',
        position: 'absolute',
        bottom: '20%',
        right: '10%',
    },
};

const Message = (props) => {
    const { onProfilePopupOpen } = useProfilePreview();
    const [toolbar, toggleToolbar] = useState(false);
    const toolbarRef = useRef();
    const messageRef = useRef();
    const [threads, setThreads] = useState([]);
    const { userStore } = useStores();
    let { isGuest: isUserGuest } = userStore;
    const isCreator = props?.message?.creator
        ? props.currentUser._id === props.message.creator['_id']
        : false;
    const actions = useMemo(() => {
        let all = isCreator
            ? ['like', 'update', 'delete', 'comment']
            : ['like', 'report', 'comment'];
        if (props.isThread)
            all = all.filter(
                (action) => action !== 'comment' && action !== 'report'
            );

        return all;
    }, [isCreator, props.isThread]);
    const { firebase } = useFirebase();

    const calculateThreads = () => {
        const item = props.message;
        if (!item._id) return;

        const ref = firebase.database().ref(`thread/${item._id}/messages`);

        ref.on('value', (current) => {
            if (!current.val()) return setThreads([]);

            const threads = Object.values(current.val());

            setThreads(threads);
        });
    };

    useEffect(() => {
        calculateThreads();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.message]);

    useEffect(() => {
        if (!messageRef.current) return;
        const outsideHandler = (e) => {
            if (messageRef.current && messageRef.current.contains(e.target))
                return;
            toggleToolbar(false);
        };

        document.addEventListener('click', outsideHandler);

        return () => {
            document.removeEventListener('click', outsideHandler);
        };
    }, []);

    if (props.message.content.event || props.message.content.deal) {
        return (
            <Card
                inThread={props.inThread}
                message={props.message}
                report={isCreator ? null : props.report}
                like={props.like}
                toggleModal={() => props.toggleModal(props.message)}
                creator={props.message?.creator?.fullName}
                currentUser={props.currentUser}
                eventCtaClick={props.eventCtaClick}
                redeem={props.redeemDeal}
                threads={threads}
                showProfile={onProfilePopupOpen}
                onEventDealDelete={props.onEventDealDelete}
                onEventDealEdit={props.onEventDealEdit}
                onEventDealRepost={props.onEventDealRepost}
                key={props.message['_id']}
            />
        );
    }

    if (props.message.content.media) {
        return (
            <MediaPost
                inThread={props.inThread}
                edit={isCreator ? props.onEditMedia : null}
                remove={isCreator ? props.remove : null}
                report={isCreator ? null : props.report}
                like={props.like}
                comment={() => props.toggleModal(props.message)}
                creator={props.message?.creator?.fullName}
                currentUser={props.currentUser}
                time={props.message.createdAt}
                post={props.message}
                showProfile={onProfilePopupOpen}
                threads={threads}
            />
        );
    }

    return (
        <div className="d-flex px-2" style={styles.container}>
            <div
                className="w-100 mt-3"
                onClick={() => !isUserGuest && toggleToolbar(true)}
            >
                {props.currentUser._id === props.message?.creator?._id ? (
                    <RightMessage
                        threads={threads}
                        like={props.like}
                        comment={props.toggleModal}
                        ref={messageRef}
                        message={props.message}
                        creator={props.message?.creator?.fullName}
                        time={props.message.createdAt}
                        showProfile={onProfilePopupOpen}
                    />
                ) : (
                    <LeftMessage
                        threads={threads}
                        like={props.like}
                        comment={props.toggleModal}
                        ref={messageRef}
                        message={props.message}
                        creator={props.message?.creator?.fullName}
                        time={props.message.createdAt}
                        showProfile={onProfilePopupOpen}
                    />
                )}
            </div>
            {toolbar && (
                <div
                    id="test"
                    style={
                        props.currentUser._id === props.message?.creator?._id
                            ? styles.toolbarRight
                            : styles.toolbarLeft
                    }
                >
                    <ActionsToolbar
                        actions={actions}
                        onComment={props.toggleModal}
                        onLike={props.like}
                        onRemove={props.remove}
                        onUpdate={props.update}
                        onReport={props.report}
                        ref={toolbarRef}
                    />
                </div>
            )}
        </div>
    );
};

export default Message;
