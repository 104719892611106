/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete';
import { Helmet } from 'react-helmet';

import inputStyles from './googleAutocompleteStyles';
import useSearchBailiwiksApi from '../../core/hooks/useSearchApi';
import LoadingSpinner from '../UI/loading-spinner';
import BailiwikItem from './BailiwikItem';
import IconSVG from '../common/iconSVG';
import useJoinBailiwik from '../../core/hooks/useJoinBailiwik';
import { getBailiwik, getSystemAlert } from '../../services/databaseService';
import { useLogToAmplitude } from '../../core/utility/amplitude';
import { useStores } from '../../lib/store';
import {
    updateBailiwiks
} from '../../services/deviceService';
import './style.css';



const FindBailiwik = (props) => {
    const [checksArePassed, setChecksArePassed] = useState(false);
    const [findByLocation, setFindByLocation] = useState(false);
    const [findBailiwiks, setFindBailiwiks] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [featuredBailiwiks, setFeaturedBailiwiks] = useState([])
    const [inputName, setInputName] = useState('');
    const { getByLocation, getByName, loading } = useSearchBailiwiksApi();
    const { joinBailiwik } = useJoinBailiwik();
    const { userStore } = useStores();
    const logToAmplitude = useLogToAmplitude();

    const getFeaturedBailiwiks = useCallback((featuredBailiwiksIds) => {
        Promise
            .all(featuredBailiwiksIds.map(id => getBailiwik(id, 'find bailiwik')))
            .then(response => setFeaturedBailiwiks(response.map(res => res.bailiwik)));
    }, []);

    const handleSwitchBailiwik = async (bailiwik) => {
        try {
            if (!props.isPage) {
                props.onClose();
            }
            await props.switchBailiwik(bailiwik);
        } catch (e) {
            console.log('>>> error switching bailiwik', e);
        }
    };

    const handleSwitchBailiwikJoinedBailiwik = async (bailiwik) => {
        try {
            const result = await joinBailiwik(bailiwik);
            updateBailiwiks(bailiwik); // update bailiwiks in local storage
            await props.switchBailiwik(result.bailiwik, true);
        } catch (e) {
            console.log('>>> error switching bailiwik', e);
        }
    };

    useEffect(() => {
        if (!keyword) return;

        findByLocation
            ? geocodeByAddress(keyword.value.description)
                  .then((results) => getLatLng(results[0]))
                  .then(getByLocation)
                  .then(setFindBailiwiks)
                  .catch((error) => console.error('geocodeByAddress', error))
                  .finally(() => setInputName('') || setKeyword(''))
            : getByName(keyword)
                  .then((res) => {
                      return res;
                  })
                  .then(setFindBailiwiks)
                  .catch((error) => console.error('getByName', error))
                  .finally(() => setKeyword(''));
    }, [keyword, getByLocation, getByName, findByLocation]);

    useEffect(() => {
        const guestJoinBWId = window.sessionStorage.getItem('guest-join-bw');
        if (guestJoinBWId) {
            const bailiwikObj = JSON.parse(guestJoinBWId);
            handleSwitchBailiwikJoinedBailiwik(bailiwikObj);
            logToAmplitude(userStore?.getUserId, bailiwikObj?._id, bailiwikObj?.name, 'Join Automatic');
            window.sessionStorage.removeItem('guest-join-bw');
        } else {
            setChecksArePassed(true);
        }
        getSystemAlert()
            .then(response => getFeaturedBailiwiks(response.message.featuredBailiwiks))
            .catch(e => console.log('>>> get system alert error', e))
    }, []);

    useEffect(() => {
        setFindBailiwiks([]);
    }, [findByLocation]);

    if (!checksArePassed) {
        return null;
    }

    return (
        <div className="find-modal-container">
            <Helmet>
                <title>Find a Bailiwik</title>
            </Helmet>
            {loading && (
                <div className="find-loading-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className="modal-content">
                <div className="modal-headline">
                    <button
                        onClick={() => props.onClose()}
                        className="modal-close"
                    >
                        <IconSVG
                            icon="backArrow"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                        />
                    </button>
                    <span>Find a Bailiwik</span>
                </div>
                <div className="modal-find p-5">
                    <div className="col-6 offset-3 d-flex justify-content-around">
                        <button
                            onClick={() => setFindByLocation(false)}
                            className={`find-type${
                                !findByLocation ? '-active' : ''
                            }`}
                        >
                            BY BAILIWIK
                        </button>
                        <button
                            onClick={() => setFindByLocation(true)}
                            className={`find-type${
                                findByLocation ? '-active' : ''
                            }`}
                        >
                            BY LOCATION
                        </button>
                    </div>
                    <div className="col-10 offset-1 d-flex find-input-section">
                        <img src="/images/icons/header-search.png" />
                        {!findByLocation ? (
                            <input
                                value={inputName}
                                className="find-input w-100"
                                placeholder="Search By Bailiwik or Host Name"
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && setKeyword(inputName)
                                }
                                onChange={(e) => setInputName(e.target.value)}
                            />
                        ) : (
                            <GooglePlacesAutocomplete
                                apiKey="AIzaSyArfENGNhr5y7YwoUj1VAaoLecmfFQIdZ8"
                                selectProps={{
                                    styles: inputStyles,
                                    value: keyword,
                                    onChange: setKeyword,
                                    className: 'find-input w-100',
                                    placeholder: 'Search by City or Zip Code',
                                }}
                                placeholder="Search by City or Zip Code"
                                className="find-input w-100"
                            />
                        )}
                    </div>

                    <div className="justify-content-center mt-5">
                        <div className="">
                            <div className="justify-content-center align-items-center bio">
                                <div className="mt-4 pb-3 list-title">
                                    FEATURED BAILIWIKS
                                </div>
                            </div>
                        </div>
                        <div className="bailiwik-list find-list">
                            {featuredBailiwiks &&
                                featuredBailiwiks.map((bailiwik) => (
                                    <div
                                        key={bailiwik._id}
                                        className="bailiwik-item-wrapper"
                                    >
                                        <BailiwikItem
                                            switchBailiwik={
                                                handleSwitchBailiwik
                                            }
                                            bailiwik={bailiwik}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="justify-content-center mt-5">
                        <div className="">
                            <div className="justify-content-center align-items-center bio">
                                <div className="mt-4 pb-3 list-title">
                                    FOUND BAILIWIKS
                                </div>
                            </div>
                        </div>
                        <div className="bailiwik-list find-list">
                            {findBailiwiks.length < 1 ? (
                                <span style={{ color: 'while', opacity: '.4' }}>
                                    Use the search field above to find a
                                    Bailiwik
                                </span>
                            ) : (
                                findBailiwiks.map((bailiwik) => (
                                    <div
                                        key={bailiwik._id}
                                        className="bailiwik-item-wrapper"
                                    >
                                        <BailiwikItem
                                            switchBailiwik={
                                                handleSwitchBailiwik
                                            }
                                            key={bailiwik._id}
                                            bailiwik={bailiwik}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FindBailiwik;
