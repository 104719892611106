import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Errors from '../../common/Errors';
import Row from './Row';
import Icon, { I_PENCIL } from '../assets/icon';
import IconEyeOn from '../../../components/common/icons/EyeOn';
import IconEyeOff from '../../../components/common/icons/EyeOff';

import styles from '../assets/styles.module.css';

/**
 * FormInput component
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function FormInput({
    children,
    type = 'text',
    name,
    placeholder = '',
    value = '',
    className = '',
    onChange,
    onFocus,
    onBlur,
    errors = null,
    editable = true,
    onEnterKey = () => {},
}) {
    const [disabled, setDisabled] = useState(true);
    const [passwordType, setPasswordType] = useState('password');
    const inputRef = useRef(null);

    const myProps = {
        name,
        placeholder,
        value,
        onChange,
        className: `${styles.frmInput} tw-w-full tw-bg-transparent tw-px-2 tw-mr-2 tw-mt-1 ${className}`,
    };
    if (onFocus) {
        myProps.onFocus = onFocus;
    }
    if (onBlur) {
        myProps.onBlur = onBlur;
    }

    useEffect(() => {
        if (!disabled) {
            inputRef.current.focus();
        }
    }, [disabled]);

    return (
        <div>
            <Row>
                <div className="tw-flex-grow-0 tw-w-10">{children}</div>
                {type === 'password' ? (
                    <div className="tw-relative tw-w-full">
                        <input
                            ref={inputRef}
                            type={passwordType}
                            {...myProps}
                            disabled={disabled}
                            autoComplete="new-password"
                            data-test-id={`input-${name}`}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    onEnterKey(e);
                                    setDisabled(true);
                                }
                            }}
                        />
                        <div
                            className="tw-absolute tw--right-1 tw-top-1 tw-w-8 tw-h-8 tw-p-1"
                            onClick={() => {
                                setPasswordType((pt) => {
                                    console.log('pt', pt);
                                    return pt === 'password'
                                        ? 'text'
                                        : 'password';
                                });
                            }}
                        >
                            {!disabled && (
                                <>
                                    {passwordType === 'password' && (
                                        <IconEyeOn />
                                    )}
                                    {passwordType === 'text' && <IconEyeOff />}
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <input
                        ref={inputRef}
                        disabled={disabled}
                        data-test-id={`input-${name}`}
                        type={type}
                        {...myProps}
                    />
                )}
                {editable &&
                    ((type === 'password' && disabled) ||
                        type !== 'password') && (
                        <Icon
                            name={I_PENCIL}
                            onClick={() => {
                                setDisabled(!disabled);
                            }}
                        />
                    )}
            </Row>
            {errors && <Errors attr={name} errors={errors} />}
        </div>
    );
}

FormInput.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.string,
    className: PropTypes.string,
    editable: PropTypes.bool,
};
