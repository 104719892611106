import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useStores } from '../../lib/store';
import { socialLogin, getUserBailiwiks } from '../../services/databaseService';
import {
    setAuthToken,
    setLoggedIn,
    setStoredUserId,
} from '../../services/deviceService';

/**
 * useSocialLogin hook for reusable login function
 *
 * @TODO: unitTest
 *
 * @param {string} initialSource
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export default function useSocialLogin(initialSource) {
    const { userStore } = useStores();
    const history = useHistory();
    const [source] = useState(initialSource);

    /**
     * Perform login
     *
     * @param {string} email
     * @param {string} password
     * @param {boolean} remember
     *
     * @returns {Promise}
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    return async (email, uid, displayName, avatar, callback) => {
        try {
            const user = {
                email,
                uid,
                fullName:
                    displayName ||
                    `User ${new Date().getTime().toString().slice(0, 5)}`,
            };
            const response = await socialLogin(user)
                .then(async (user) => {
                    if (user.success) {
                        return user;
                    } else if (user.errors.email) {
                        alert(user.errors.email);
                    } else if (user.errors.password) {
                        alert(user.errors.password);
                    } else {
                        alert('Something went wrong. Please refresh');
                    }
                })
                .catch((err) => console.error(err));

            if (response) {
                const TOKEN = response.user.token;
                setAuthToken(TOKEN);
                setStoredUserId(response.user._id);
                // It is not clear what this code is intended to do. We get the
                // user's bws, if any. Then we call back with the bailiwikId...
                // whatever that does (see sign-in.jsx where path *was* being
                // set, but maybe used in other places, too?) Then set the path
                // below it, but the callback was also setting a path.
                // For now, just set path here.
                const userBailiwiks = await getUserBailiwiks();
                const bailiwikId = userBailiwiks.bailiwiks &&
                            userBailiwiks.bailiwiks[0]?._id;
                if (callback) {
                    console.log(`callback called with ${bailiwikId}`);
                    callback(bailiwikId);
                }
                await userStore.updateData(
                    { user: { ...response.user, email }, userBailiwiks },
                    'useSocialLogin'
                );
                setLoggedIn(true);
                const path = !bailiwikId
                        ? '/find-bailiwik'
                        : `/chat/${bailiwikId}`;
                history.push(path);
            }
        } catch (e) {
            console.log(`social login error: ${e}`);
        }
    };
}
