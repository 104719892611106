import React, { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Message from '../message'
import { useLogToAmplitude } from '../../../core/utility/amplitude';
import { useStores } from '../../../lib/store';

const sorted = (data) => data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

const Messages = (
    {
        messages = [],
        currentUser, 
        like,
        remove,
        editMessage,
        editMedia,
        report,
        showProfile,
        toggleCommentModal,
        eventCtaClick,
        redeemDeal,
        isThread,
        isGuest,
        setPage = () => {},
        onEventDealEdit = () => {},
        onEventDealDelete = () => {},
        onEventDealRepost = () => {},
    }, ref) => {
    const { bailiwikStore } = useStores();
    const logToAmplitude = useLogToAmplitude();
    const formattedMessages = useMemo(() => {
        return messages.map(message => {
            const cloneMessage = { ...message }

            cloneMessage.likes.users = Object.values(
                cloneMessage.likes.users 
                    ? cloneMessage.likes.users 
                    : {}
            )
            cloneMessage.createdAt = (new Date(message.createdAt)).toISOString().toString()

            return cloneMessage
        })
    }, [messages])

    return (
        <div id="scrollableDiv" className="box-container" data-is-guest={isGuest} ref={ref}>
            <InfiniteScroll
                ref={(ref => {
                    // scrollbarRef.current = ref
                    if(ref && ref.isElementAtBottom) {
                      const func = ref.__proto__.isElementAtTop
                      ref.__proto__.isElementAtTop = (target, n) => target.scrollTop === 0 ? false : func(target,n)
                    }
                  })}
                className="container"
                scrollThreshold={0.9}
                // initialScrollY={data ? 30 : 30}
                inverse={true}
                dataLength={formattedMessages.length}
                hasMore={true}

                next={() => setPage(page => page + 1)}
                scrollableTarget="scrollableDiv">
                {formattedMessages[0] && sorted(formattedMessages).map((message) => (
                    <Message
                        isThread={isThread}
                        onEditMedia={() => editMedia(message)}
                        toggleModal={() => {
                            /* Log action to Amplitude #655 */
                            const currentBailiwik = bailiwikStore.current;
                            const extras = message.type &&
                                'string' == typeof message.type ? {
                                    type : message.type.toLowerCase()
                                } : {};
                            logToAmplitude(currentUser?._id,
                                currentBailiwik._id,
                                currentBailiwik.name,
                                'Chat Thread',
                                extras);
                            /* FINISH Log action to Amplitude #655 */
                            return toggleCommentModal(message);
                        }}
                        like={() => like(message)}
                        report={() => report(message)}
                        remove={() => remove(message)}
                        currentUser={currentUser}
                        eventCtaClick={eventCtaClick}
                        redeemDeal={redeemDeal}
                        message={message}
                        update={() => editMessage(message)}
                        key={message["_id"]}
                        showProfile={showProfile}
                        onEventDealEdit={onEventDealEdit}
                        onEventDealDelete={onEventDealDelete}
                        onEventDealRepost={onEventDealRepost}
                    />
                ))}
            </InfiniteScroll>
        </div>
    )
}

export default React.forwardRef(Messages)