import React from 'react';
import PropTypes from 'prop-types';

import VisiblePasswordField from '../../common/VisiblePasswordField';
import Errors from '../../common/Errors';

/**
 * Signup Input field component
 *
 * @param {object} props
 *
 * @returns {React.ReactElement}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const InputField = ({
    label,
    name,
    value,
    onChange,
    type = 'text',
    placeholder = '',
    errors = [],
}) => (
    <div className="">
        <label className="tw-block tw-text-left tw-mb-6">{label}</label>
        {type === 'password' ? (
            <VisiblePasswordField
                name={name}
                data-test-id={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="new-password"
                className="tw-bg-transparent tw-py-2 tw-w-full tw-border-b tw-border-gray-400"
            />
        ) : (
            <input
                name={name}
                data-test-id={name}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="new-input"
                className="tw-bg-transparent tw-py-2 tw-w-full tw-border-b tw-border-gray-400"
            />
        )}
        <Errors errors={errors} attr={name} />
    </div>
);

InputField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
};

export default InputField;
