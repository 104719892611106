import React, { useContext, createContext, useState } from 'react'
import Profile from "../../components/common/MembersList/Profile";

const ProfilePreviewContext = createContext({
    onProfilePopupOpen: () => {}
});

export const useProfilePreview = () =>
    useContext(ProfilePreviewContext)

export function ProfilePreviewProvider({ children }) {
    const [profilePreviewData, setProfilePreviewData] = useState(null)

    const onProfilePopupOpen = (data) =>
        setProfilePreviewData(data)

    const onProfilePreviewClose = () =>
        setProfilePreviewData(null)

    return (
        <ProfilePreviewContext.Provider value={{ onProfilePopupOpen }}>
            {children}
            {profilePreviewData && (
                <Profile
                    data={profilePreviewData}
                    closeHandler={onProfilePreviewClose}
                    isBailiff={false}
                    isHost={false}
                />
            )}
        </ProfilePreviewContext.Provider>
    )
}