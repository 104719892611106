import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

import { getImageUrl } from '../../../../../lib/utils';
import iconPerson from '../../../../../assets/person.png';

/**
 * Displays an individual message in the list of Direct Messages
 * in the Messages tab. The message text shown is the last one in the
 * conversation.
 *
 * @param {*} param0
 * @returns
 */
export default function MessageListItem({ notification, myId }) {
    const { url } = useRouteMatch();
    // const { creator } = notification;
    const creator = Object.values(notification?.conversation.users).find(user => user._id !== myId)
    const getName = () => {
        if (creator.displayName) {
            return creator.displayName;
        }
        return creator.fullName;
    };

    const hasSeen = () => {
        return notification.seen && notification.seen.includes(myId)
    }

    const getAvatar = (creatorAvator) => {
        const avatar = getImageUrl(creatorAvator);
        return avatar ? avatar : iconPerson;
    };

    return (
        <Link to={`${url}/${creator._id}/${notification?.conversation.id}`}>
            <div className="tw-flex tw-content-center py-3 tw-border-b tw-border-gray-600">
                <div className="tw-flex tw-h-full tw-pt-2">
                    <img
                        src={getAvatar(creator.avatar)}
                        className="tw-rounded-full tw-w-14 tw-h-12 tw-overflow-hidden"
                    />
                </div>
                <div className="tw-ml-4 tw-w-full tw-text-sm">
                    <div className="tw-flex tw-text-gray-400">
                        <div className="tw-flex-grow tw-text-xs">{creator.name || 'DIRECT MESSAGE'}</div>
                        <div className="tw-text-right tw-flex-grow-0">
                            {moment(notification.createdAt).fromNow()}
                        </div>
                    </div>
                    <div className={notification.unread ? 'tw-font-bold' : 'tw-text-gray-400'}>
                        <div>{getName(creator)}</div>
                        <div>{notification?.conversation?.lastMessage?.content?.text || notification?.conversation?.lastMessage?.content?.media?.type}</div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

MessageListItem.propTypes = {
    notification: PropTypes.object.isRequired,
    myId: PropTypes.string.isRequired,
}
