import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Joi from 'joi';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Switch from 'react-switch';
import metadata from '../../metadata.json';

import Avatar from './components/Avatar';
import {
    updateUser,
    passwordResetSecure,
} from '../../services/databaseService';
import { useStores } from '../../lib/store';
import LoadingSpinnerWithModal from '../common/LoadingSpinnerWithModal';
import Row from './components/Row';
import FormInput from './components/FormInput';

import Icon, {
    I_ARROW_R,
    I_BIO,
    I_CAKE,
    I_EMAIL,
    I_INTERESTS,
    I_LOCK,
    I_OCCUPATION,
    I_WEBSITE,
} from './assets/icon';
import { handleSignOut } from '../../services/authService';
import HeaderLayout from '../common/HeaderLayout';
import { setAuthToken } from '../../services/deviceService';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const validateInputs = (data) => {
    const errMsgWebsite = {
        'string.pattern.base': i18n.t(
            'Please enter valid website URI or domain'
        ),
        'string.domain': i18n.t('Please enter valid website URI or domain'),
    };
    const schema = Joi.object({
        name: Joi.string()
            .trim()
            .messages({ 'string.empty': i18n.t('Please enter your name') }),
        displayName: Joi.string()
            .trim()
            .messages({ 'string.empty': i18n.t('Please enter your display name') }),
        birthday: Joi.date()
            .iso()
            .allow('')
            .less(new Date())
            .messages({
                'string.empty': i18n.t('Please enter a valid birthday'),
            }),
        website: [
            Joi.string()
                .pattern(
                    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,255}[-a-zA-Z0-9_~]\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
                )
                .allow('')
                .messages(errMsgWebsite),
            Joi.string().domain().messages(errMsgWebsite),
        ],
    });
    const result = schema.validate(data, {
        abortEarly: false,
    });
    if (!result.error) {
        return false;
    }
    const out = {};
    result.error.details.forEach((error) => {
        out[error.context.key] = [
            error.context.message ? error.context.message : error.message,
        ];
    });
    return out;
};

function Profile() {
    const rootStore = useStores();
    const { userStore, bailiwikStore, memberStore } = rootStore;
    const { currentUser } = userStore;
    const [name, setName] = useState(currentUser.fullName ?? '');
    const [displayName, setDisplayName] = useState(
        currentUser.displayName ?? ''
    );
    const [email, setEmail] = useState(currentUser.email ?? '');
    const [password, setPassword] = useState(currentUser.password ?? '');
    const [occupation, setOccupation] = useState(currentUser.occupation ?? '');
    const [bio, setBio] = useState(currentUser.bio ?? '');
    const [birthday, setBirthday] = useState(() => {
        if (!currentUser.birthday) {
            return '';
        }
        return currentUser.birthday.substring(0, 10);
    });
    const [website, setWebsite] = useState(currentUser.website ?? '');
    const [interests, setInterests] = useState(currentUser.interests ?? '');
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false);
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const buildFormData = () => {
        const formData = new FormData();
        formData.append('user[appVersion]', process.env.APP_VERSION);
        formData.append('user[fullName]', name);
        formData.append('user[displayName]', displayName);
        formData.append('user[occupation]', occupation);
        formData.append('user[bio]', bio);
        formData.append('user[birthday]', birthday);
        formData.append('user[website]', website);
        formData.append('user[interests]', interests);
        return formData;
    };
    const handleLanguage = () => {
        if ('es' == i18n.language) {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('es');
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (processing) {
            return false;
        }

        const errs = validateInputs({
            name,
            displayName,
            birthday,
            website,
        });

        if (errs) {
            setErrors(errs);
            return false;
        }
        const formData = buildFormData();
        setProcessing(true);
        updateUser(userStore.getUserId, formData, 'AccountScreen')
            .then(async (res) => {
                if (!res || !res.success) {
                    throw new Error('Error response');
                }
                await userStore.load('AccountScreen handleSubmit');
                await bailiwikStore.load(null, 'AccountScreen handleSubmit');
                await memberStore.load(
                    false,
                    true,
                    'AccountScreen handleSubmit'
                );
                window.alert(t('Profile updated successfully'));
                setProcessing(false);
            })
            .catch((err) => {
                console.error(err);
                window.alert(t('Something went wrong. Please try again'));
                setProcessing(false);
            });
        // update password
        if (password) {
            passwordResetSecure({
                userId: userStore.getUserId,
                password,
            }).then((res) => {
                if (!res || !res.user) {
                    throw new Error('Error response');
                }
                const { token } = res.user;
                setAuthToken(token);
                setPassword('');
            });
        }

        return false;
    };

    // header right action button
    const RightButton = () => {
        return (
            <button
                type="button"
                data-test-id="btn-save"
                onClick={submitHandler}
            >
                {t('Save')}
            </button>
        );
    };

    return (
        <HeaderLayout
            backButton
            title={t('My Profile')}
            RightButton={<RightButton />}
        >
            <Helmet>
                <title>{t('My Profile')}</title>
            </Helmet>
            <form className="tw-relative" action="" onSubmit={submitHandler}>
                {processing && <LoadingSpinnerWithModal />}
                <Avatar />
                <div className="tw-mt-7">
                    <div className="tw-mt-6 tw-mb-2 tw-px-5 tw-tracking-widest">
                        {t('PROFILE')}
                    </div>
                    <div className="tw-px-5 tw-pb-2">
                        <FormInput
                            name="name"
                            placeholder={t('Your name')}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setErrors({
                                    ...errors,
                                    name: [],
                                });
                            }}
                            errors={errors.name}
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.0002 10.0013C12.5775 10.0013 14.6668 7.91196 14.6668 5.33464C14.6668 2.75731 12.5775 0.667969 10.0002 0.667969C7.42283 0.667969 5.3335 2.75731 5.3335 5.33464C5.3335 7.91196 7.42283 10.0013 10.0002 10.0013Z"
                                    fill="white"
                                />
                                <path
                                    d="M17.4551 12.988C15.4951 11.9846 12.9517 11.168 10.0001 11.168C7.04841 11.168 4.50508 11.9846 2.54508 12.988C1.37841 13.583 0.666748 14.7846 0.666748 16.0913V19.3346H19.3334V16.0913C19.3334 14.7846 18.6217 13.583 17.4551 12.988Z"
                                    fill="white"
                                />
                            </svg>
                        </FormInput>
                        <FormInput
                            name="display_name"
                            placeholder={t('What should we call you')}
                            value={displayName}
                            onChange={(e) => {
                                setDisplayName(e.target.value);
                                setErrors({
                                    ...errors,
                                    displayName: [],
                                });
                            }}
                            errors={errors.displayName}
                        >
                            <div className="tw-opacity-50">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.0002 10.0013C12.5775 10.0013 14.6668 7.91196 14.6668 5.33464C14.6668 2.75731 12.5775 0.667969 10.0002 0.667969C7.42283 0.667969 5.3335 2.75731 5.3335 5.33464C5.3335 7.91196 7.42283 10.0013 10.0002 10.0013Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M17.4551 12.988C15.4951 11.9846 12.9517 11.168 10.0001 11.168C7.04841 11.168 4.50508 11.9846 2.54508 12.988C1.37841 13.583 0.666748 14.7846 0.666748 16.0913V19.3346H19.3334V16.0913C19.3334 14.7846 18.6217 13.583 17.4551 12.988Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </FormInput>
                        <FormInput
                            type="email"
                            name="email"
                            placeholder={t('Email')}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setErrors({
                                    ...errors,
                                    email: [],
                                });
                            }}
                            errors={errors.email}
                            editable={false}
                        >
                            <Icon name={I_EMAIL} />
                        </FormInput>
                        <FormInput
                            type="password"
                            name="password"
                            placeholder={t('Reset Password')}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setErrors({
                                    ...errors,
                                    password: [],
                                });
                            }}
                            onEnterKey={(e) => {
                                submitHandler(e);
                            }}
                            errors={errors.password}
                        >
                            <Icon name={I_LOCK} />
                        </FormInput>
                    </div>
                    <div className="tw-mt-6 tw-mb-2 tw-px-5 tw-tracking-widest">
                        {t('ABOUT YOU')}
                    </div>
                    <div className="tw-px-5 tw-pt-0.5 tw-pb-3">
                        <FormInput
                            name="occupation"
                            placeholder={t('Occupation')}
                            value={occupation}
                            onChange={(e) => {
                                setOccupation(e.target.value);
                                setErrors({
                                    ...errors,
                                    occupation: [],
                                });
                            }}
                            errors={errors.occupation}
                        >
                            <Icon name={I_OCCUPATION} />
                        </FormInput>
                        <FormInput
                            name="bio"
                            placeholder={t(
                                'Tell us a little bit about yourself'
                            )}
                            value={bio}
                            onChange={(e) => {
                                setBio(e.target.value);
                                setErrors({
                                    ...errors,
                                    bio: [],
                                });
                            }}
                            errors={errors.bio}
                        >
                            <Icon name={I_BIO} />
                        </FormInput>
                        <FormInput
                            name="birthday"
                            type="text"
                            placeholder={t('Birthday')}
                            value={birthday}
                            onFocus={(e) => {
                                e.target.type = 'date';
                            }}
                            onBlur={(e) => {
                                e.target.type = 'text';
                            }}
                            onChange={(e) => {
                                setBirthday(e.target.value);
                                setErrors({
                                    ...errors,
                                    birthday: [],
                                });
                            }}
                            errors={errors.birthday}
                        >
                            <Icon name={I_CAKE} />
                        </FormInput>
                        <FormInput
                            type="url"
                            placeholder={t('Personal Website or relevant link')}
                            name="website"
                            value={website}
                            onChange={(e) => {
                                setWebsite(e.target.value);
                                setErrors({
                                    ...errors,
                                    website: [],
                                });
                            }}
                            errors={errors.website}
                        >
                            <Icon name={I_WEBSITE} />
                        </FormInput>
                        <FormInput
                            name="interests"
                            placeholder={t('Skills and interests')}
                            value={interests}
                            onChange={(e) => {
                                setInterests(e.target.value);
                            }}
                            errors={errors.interests}
                        >
                            <Icon name={I_INTERESTS} />
                        </FormInput>
                        <div>
                            <Row>
                                <div>
                                    <label>
                                        <span>Enable Spanish </span>
                                        <Switch
                                            checked={'es' == i18n.language}
                                            className="react-switch"
                                            onChange={handleLanguage}
                                        />
                                    </label>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <div className="tw-mt-6 tw-mb-2 tw-px-5 tw-tracking-widest">
                        {t('ADDITIONAL SETTINGS')}
                    </div>
                    <div className="tw-px-5 tw-pt-0.5 tw-pb-3">
                        <Row>
                            <p>BAILIWIK APP VERSION: {`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</p>
                        </Row>
                        <Row>
                            <a
                                data-test-id="lnk-help-center"
                                href="https://www.bailiwik.com/help/"
                                target="_blank"
                                rel="noreferrer"
                                className="tw-w-full"
                            >
                                {t('Help Center')}
                            </a>
                            <Icon name={I_ARROW_R} />
                        </Row>
                        <Row>
                            <div
                                role="button"
                                className="tw-w-full"
                                data-test-id="btn-sign-out"
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            t(
                                                'Are you sure you want to sign out'
                                            )
                                        )
                                    ) {
                                        handleSignOut(
                                            'AccountScreen',
                                            rootStore
                                        );
                                        history.push('/');
                                    }
                                }}
                            >
                                {t('Sign Out')}
                            </div>
                            <Icon name={I_ARROW_R} />
                        </Row>
                    </div>
                </div>
            </form>
            <div className="tw-py-8">
                {/** push content from bottom toolbar */}
            </div>
        </HeaderLayout>
    );
}

export default observer(Profile);
