import {useState, useLayoutEffect} from 'react'

const useDragAndDrop = (onDrop) => {
    const [element, setElement] = useState()

    useLayoutEffect(() => {
        if (!element) return
        element.addEventListener('dragenter', handleDragIn)
        element.addEventListener('dragleave', handleDragOut)
        element.addEventListener('dragover', handleDrag)
        element.addEventListener('drop', handleDrop)

        return () => {
            element.removeEventListener('dragenter', handleDragIn)
            element.removeEventListener('dragleave', handleDragOut)
            element.removeEventListener('dragover', handleDrag)
            element.removeEventListener('drop', handleDrop)
        }
        
    }, [element])

    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
    }

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()

    }

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = e.dataTransfer.files
            const loadedImages = []
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader()
    
                reader.addEventListener('load', () => {
                    loadedImages.push(reader.result)
                    if (loadedImages.length === files.length) onDrop(loadedImages)
                }, false)
    
                reader.readAsDataURL(files[i])
                
            }
          }
    }

    return { setElement }
}

export default useDragAndDrop